import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  FormControl,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
  Popper,
  InputAdornment,
  Modal,
  Skeleton,
} from "@mui/material";
import {
  headingStyle,
  tableRowLab,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import {
  dateFiled,
  menuItemStyle,
  primaryBtn,
  selectField,
  textfieldText,
  textfieldTextBlueBorder,
  textfieldTextBlueBorderPharmacy,
} from "../../style/AppointmentStyle";
// import { useDispatch, useSelector } from "react-redux";
import { primaryBtnBlue, } from "../../style/PatientDetailsStyle";
import { Link, useNavigate } from "react-router-dom";
import { autoFocusOffWithOutBorder } from "../../style/PatinetFormStyle";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { DeleteForeverRounded } from "@mui/icons-material";
import CustomModal from "../CustomModal/CustomModal";
import EditPurchaseOrder from "./EditPurchaseOrder";
import NewPurchaseOrder from "./NewPurchaseOrder";
import UseGetPurchaseDetails from "../../helper/UseGetPurchaseDetails";
import { deletePurchaseOrder } from "../../connections/deleteApi";
import { showToast } from "../../utils/toastUtil";
import { formattedDate, formattedDateWithoutBg } from '../../utils/constantFunctions'
import { tableCellPharmacy } from "../../style/PharmacyStyle";

const PurchaseOrderDetails = ({ onClose, id, refetch, page, itemPerPage }) => {

  const [filterModal, setFilterModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [editPurchaseOrderModal, setEditPurchaseOrderModal] = useState(false);
  const [newPurchaseOrderModal, setNewPurchaseOrderModal] = useState(false);
  const { data: purchaseDetails, isLoading } = UseGetPurchaseDetails(id)
  // console.log(purchaseDetails?.payload)
  const navigate = useNavigate();

  const handleDelete = async (id) => {
    setDeleteModal(false);
    const res = await deletePurchaseOrder(id);
    console.log(res);

    showToast(res?.status?.message || res?.status?.status, res?.status?.status);
    if (res?.status?.status === 'SUCCESS') {
      refetch(page, itemPerPage);
      onClose();
    }
  }

  return (
    <>
      <Box sx={{ width: "100%", display: 'flex', height: "90vh", flexDirection: 'column', justifyContent: "space-between" }}>

        {/* //modals for delete and filter */}

        <Modal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          sx={{
            position: 'absolute',
            top: '25%',
            // left:'50%',
            width: "450px",
            // height:"400px",
            margin: 'auto',
            //  display: "flex",
            //  alignItems: "flex-start",
            //  justifyContent: "center",
            //  overflow: "auto",
            borderRadius: '20px',
          }}
        >

          <Box
            sx={{
              mt: 2,
              bgcolor: "background.paper",
              borderRadius: 3.6,
              boxShadow: 24,
              p: 4.5,
              "&:focus": {
                outline: "none",
              },


            }}
          >

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography textAlign={'center'}>Are you sure?</Typography>
              <Typography textAlign={'center'}>You want to delete these selected purchase orders?</Typography>

            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'flex', gap: '25px', width: 'max-content', margin: 'auto', marginTop: "40px" }}>
              <Button sx={{
                backgroundColor: "#FF392B", color: "white", border: '', width: "150px", borderRadius: "5px", fontSize: "12px", textTransform: 'capitalize', '&:hover': {
                  backgroundColor: "red",
                }
              }} onClick={() => { handleDelete(id) }}>Delete</Button>
              <Button onClick={() => setDeleteModal(false)} sx={{ backgroundColor: "white", color: "black", border: '2px solid #FF392B', width: "150px", borderRadius: "5px", fontSize: "12px", textTransform: 'capitalize' }}>Cancel</Button>
            </Box>

          </Box>
        </Modal>

        <Modal
          open={filterModal}
          onClose={() => setFilterModal(false)}
          sx={{
            position: 'absolute',
            top: '14%',
            // left:'50%',
            width: "340px",
            // height:"400px",
            margin: 'auto',
            //  display: "flex",
            //  alignItems: "flex-start",
            //  justifyContent: "center",
            //  overflow: "auto",
            borderRadius: '20px',
          }}
        >

          <Box
            sx={{
              mt: 2,
              bgcolor: "background.paper",
              borderRadius: '20px',
              boxShadow: 24,
              p: 1,
              "&:focus": {
                outline: "none",
              },


            }}
          >

            <Grid container spacing={2} sx={{ padding: '9px' }}>
              <Grid item xs={12}>
                <Typography variant="subTitle116px" fontWeight={'600'} textAlign={'center'} margin={'auto'}>Filters</Typography>
              </Grid>



              {/* <Grid item xs={12}>
        <Typography  variant="subTitle116px" >Suppliers</Typography>
      </Grid> */}

              {/* Filter Selection */}
              <Grid item xs={12}>

                <Select fullWidth defaultValue="" placeholder="Select Filter" sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Ensure border remains none on focus
                  },
                  "& .MuiSelect-select": {
                    padding: "9px",
                    borderRadius: "15px",
                    backgroundColor: "#F7F7FF",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                  }
                }}

                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <span style={{ color: "text.default_grey" }}>
                          Select filter
                        </span>
                      );
                    }
                    return selected === "filter1"
                      ? "filter2"
                      : selected === "filter3"
                        ? "filter3"
                        : "select filter";
                  }}
                >
                  <MenuItem value="filter1">Filter 1</MenuItem>
                  <MenuItem value="filter2">Filter 2</MenuItem>
                  <MenuItem value="filter3">Filter 3</MenuItem>
                </Select>
              </Grid>

              {/* Purchase Date */}
              <Grid item xs={12}>
                <Typography variant="subTitle116px">Purchase Date</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="From"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="To"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Department Selection */}
              <Grid item xs={12}>
                <Typography variant="subTitle116px">Department</Typography>
                <Select fullWidth defaultValue="" sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Ensure border remains none on focus
                  },
                  "& .MuiSelect-select": {
                    padding: "9px",
                    borderRadius: "15px",
                    backgroundColor: "#F7F7FF",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                  }
                }}>
                  <MenuItem value="dept1">Department 1</MenuItem>
                  <MenuItem value="dept2">Department 2</MenuItem>
                  <MenuItem value="dept3">Department 3</MenuItem>
                </Select>
              </Grid>

              {/* Created At */}
              <Grid item xs={12}>
                <Typography variant="subTitle116px">Created At</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="From"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="To"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Buttons */}
              <Grid item xs={6}>
                <Button fullWidth variant="subTitle116px16px" sx={{ ...primaryBtnBlue, color: 'white' }}>Apply</Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={() => setFilterModal(false)} fullWidth variant="subTitle116px16px" sx={{ ...primaryBtn, color: 'white', border: 'none' }}>Clear Filters</Button>
              </Grid>
            </Grid>



          </Box>
        </Modal>


        <Box sx={{
          overflowY: 'auto', maxHeight: "80vh", '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}  >

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
          >
            <Box></Box>


            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              <CreditCardIcon sx={{ color: 'neutral.navyBlue', marginBottom: "4px", marginRight: '8px' }} />
              <Typography
                variant="heading_Large"
                fontWeight={700}
                gutterBottom
                sx={headingStyle}
              >
                Purchase Order Details
              </Typography>
            </Box>
            <IconButton sx={{ marginBottom: "2rem" }} onClick={onClose}>
              <CloseIcon size="small" sx={{ color: "red" }} />
            </IconButton>
          </Box>

          <Box
            mt={-1}

            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingX="25px"
          >
            <Box >
            </Box>


            <Box sx={{ display: 'flex', gap: '8px', marginRight: "-8px" }}>

              <EditRoundedIcon onClick={() => {
                setEditPurchaseOrderModal(true)
                navigate("/pharmacy",
                  { state: purchaseDetails?.payload }
                )
              }} sx={{ backgroundColor: "#FF8D1E", p: '2px', width: "20px", color: 'white', borderRadius: "8px", cursor: "pointer" }} />
              <DeleteForeverRounded onClick={() => { setDeleteModal(true) }} sx={{ backgroundColor: "#FF392B", p: '2px', width: "18px", color: "white", borderRadius: "8px", cursor: "pointer" }} />

            </Box>

          </Box>



          {/* <TableRow sx={{ display: 'flex', width: '98%', margin: 'auto', padding: '0px', justifyContent: 'space-between', borderBottom: '4px solid white' }}>
            <TableCell sx={tableCellPharmacy}>
              <Box sx={{ display: "flex", gap: '22px', alignItems: 'center' }}>
                <Typography variant="heading2_14"> ID </Typography> <Typography> {id}</Typography>
              </Box>
            </TableCell>
            <TableCell sx={tableCellPharmacy}>
              <Box sx={{ display: "flex", gap: '22px', alignItems: 'center' }}> <Typography variant="heading2_14"> Suppliers </Typography> <Typography> {purchaseDetails?.payload?.supplier?.name}</Typography></Box>
            </TableCell>
          </TableRow> */}

          {/* <TableRow sx={{ display: 'flex', width: '98%', margin: 'auto', marginY: '0px', padding: '0px', justifyContent: 'space-between' }}>
            <TableCell sx={tableCellPharmacy}>
              <Box sx={{ display: "flex", gap: '22px', alignItems: 'center' }}>
                <Typography variant="heading2_14">  Bill Type </Typography> <Typography> {purchaseDetails?.payload?.bill_type}</Typography>
              </Box>
            </TableCell>
            <TableCell sx={tableCellPharmacy}>
              <Box sx={{ display: "flex", gap: '22px', alignItems: 'center' }}> <Typography variant="heading2_14"> Bill number  </Typography> <Typography>{purchaseDetails?.payload?.bill_number} </Typography> </Box>
            </TableCell>
            <TableCell sx={tableCellPharmacy}>
              <Box sx={{ display: "flex", gap: '22px', alignItems: 'center' }}> <Typography variant="heading2_14"> Department </Typography> <Typography>{purchaseDetails?.payload?.department?.code}</Typography> </Box>
            </TableCell>
            <TableCell sx={tableCellPharmacy}>
              <Box sx={{ display: "flex", gap: '22px', alignItems: 'center' }}> <Typography variant="heading2_14"> updated at   </Typography> <Typography> {formattedDateWithoutBg(purchaseDetails?.payload?.updated_at.split(" ")[0])} {" ,"}   {purchaseDetails?.payload?.updated_at.split(" ")[1]} </Typography> </Box>
            </TableCell>
          </TableRow> */}

          {/* <TableRow sx={{ display: 'flex', width: '98%', margin: 'auto', marginY: '0px', padding: '0px', justifyContent: 'space-between' }}>
            <TableCell sx={tableCellPharmacy}>
              <Box sx={{ display: "flex", gap: '22px', alignItems: 'center' }}>
                <Typography variant="heading2_14"> Purchase Date  </Typography> <Typography> {purchaseDetails?.payload?.purchase_date}</Typography>
              </Box>
            </TableCell>
            <TableCell sx={tableCellPharmacy}>
              <Box sx={{ display: "flex", gap: '22px', alignItems: 'center' }}> <Typography variant="heading2_14"> Grand total </Typography> <Typography> {purchaseDetails?.payload?.supplier?.grand_total} </Typography> </Box>
            </TableCell>
            <TableCell sx={tableCellPharmacy}>
              <Box sx={{ display: "flex", gap: '22px', alignItems: 'center' }}> <Typography variant="heading2_14"> Paid </Typography> <Typography>{purchaseDetails?.payload?.bill_number} </Typography> </Box>
            </TableCell>
            <TableCell sx={tableCellPharmacy}>
              <Box sx={{ display: "flex", gap: '22px', alignItems: 'center' }}> <Typography variant="heading2_14"> Created at   </Typography> <Typography>{formattedDateWithoutBg(purchaseDetails?.payload?.created_at.split(" ")[0])} {" ,"}   {purchaseDetails?.payload?.created_at.split(" ")[1]} </Typography> </Box>
            </TableCell>
          </TableRow> */}




          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "97%", margin: 'auto', marginTop: "10px" }}>
            <Box sx={{ display: "flex", gap: '110px', width: '50%', alignItems: 'center' }}> <Typography variant="heading2_14"> ID </Typography> <Typography> {id}</Typography> </Box>
            <Box sx={{
              display: "flex", gap: '22px', width: '50%', justifyContent: 'flex-end',
              alignItems: 'center'
            }}> <Typography variant="heading2_14"> Suppliers </Typography> <Typography> {purchaseDetails?.payload?.supplier?.name}</Typography></Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "97%", margin: 'auto', marginTop: "12px" }}>
            <Box sx={{ display: "flex", gap: '65px', width: '25%', alignItems: 'center' }}> <Typography variant="heading2_14">  Bill Type </Typography> <Typography> {purchaseDetails?.payload?.bill_type}</Typography> </Box>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', justifyContent: "flex-start", alignItems: 'center' }}><Typography variant="heading2_14"> Bill number  </Typography> <Typography>{purchaseDetails?.payload?.bill_number} </Typography> </Box>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', justifyContent: "flex-start", alignItems: 'center' }}> <Typography variant="heading2_14"> Department </Typography>  <Typography> {purchaseDetails?.payload?.department?.code} </Typography></Box>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', justifyContent: 'flex-end', alignItems: 'center' }}><Typography variant="heading2_14"> updated at  </Typography> <Typography> {formattedDateWithoutBg(purchaseDetails?.payload?.updated_at.split(" ")[0])} {" ,"}   {purchaseDetails?.payload?.updated_at.split(" ")[1]}  </Typography> </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "97%", margin: 'auto', marginTop: "12px", marginBottom: '14px' }}>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', alignItems: 'center' }}> <Typography variant="heading2_14"> Purchase Date </Typography> <Typography> {purchaseDetails?.payload?.purchase_date} </Typography> </Box>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', justifyContent: "flex-start", alignItems: 'center' }}><Typography variant="heading2_14"> Grand Total </Typography> <Typography> {purchaseDetails?.payload?.supplier?.grand_total} </Typography> </Box>
            <Box sx={{ display: "flex", gap: '80px', width: '25%', justifyContent: "flex-start", alignItems: 'center' }}><Typography variant="heading2_14">  Paid </Typography> <Typography> {purchaseDetails?.payload?.paid} </Typography> </Box>
            <Box sx={{ display: "flex", gap: '22px', width: '25%', justifyContent: 'flex-end', alignItems: 'center' }}><Typography variant="heading2_14"> Created at  </Typography> <Typography> {formattedDateWithoutBg(purchaseDetails?.payload?.created_at.split(" ")[0])} {" ,"}   {purchaseDetails?.payload?.created_at.split(" ")[1]}</Typography> </Box>
          </Box>


          <Typography variant="heading2_14" sx={{ marginLeft: "18px" }} >
            Items
          </Typography>

          <Grid item xs={12}>
            <TableContainer sx={{ mt: 2, width: "100%" }}>
              <Table sx={{ width: "100%" }}>
                <TableHead stickyHeader >
                  <TableRow sx={{ textAlign: "center", }}>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{

                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      S No.
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "8px",
                        //  paddingX:"4px",
                        textAlign: "center",
                        width: "200px",
                      }}
                    >
                      Item
                    </TableCell>

                    <TableCell

                      variant="body1_text_bold"
                      sx={{
                        width: "130px",
                        paddingRight: "30px",
                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Pack
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Batch
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Expiry
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      CGST/SGST
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      MRP
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Discount
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "8px",
                        textAlign: "center",
                      }}
                    >
                      Department
                    </TableCell>
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={12} sx={{ p: 0 }}>
                        <Skeleton
                          variant="rectangular"
                          sx={{
                            width: "100%",
                            height: "280px", // Adjust to match your table row height
                            backgroundColor: "#f0f0f0", // Light grey color
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>) : (purchaseDetails?.payload?.purchase_items?.length > 0 ? (
                    <TableBody>
                      {purchaseDetails?.payload?.purchase_items?.length > 0 && purchaseDetails?.payload?.purchase_items?.map((purchaseItem, index) =>
                        <TableRow sx={{ ...tableRowLab, textAlign: "center", borderTop: "2px solid white", borderBottom: "6px solid white" }} key={index}>
                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              borderTopLeftRadius: "20px",
                              borderBottomLeftRadius: '20px',
                              color: 'neutral.black',
                              p: "8px",
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="body_Grey1"> {purchaseItem?.id}</Typography>
                          </TableCell>
                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "8px",
                              //  paddingX:"4px",
                              textAlign: "center",
                              width: "200px",
                            }}
                          >
                            <Typography variant="body_Grey1"> <Link>
                              {purchaseItem?.medicine_name}
                            </Link>
                            </Typography>
                          </TableCell>
                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              width: "130px",
                              paddingRight: "30px",
                              color: 'neutral.black',
                              p: "8px",
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="body_Grey1"> {purchaseItem?.pack}</Typography>
                          </TableCell>
                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "8px",
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="body_Grey1">{purchaseItem?.batch}</Typography>
                          </TableCell>
                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "8px",
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="body_Grey1">  {formattedDate(purchaseItem?.expiry_date)}</Typography>
                          </TableCell>

                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "8px",
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="body_Grey1">{purchaseItem?.quantity}</Typography>
                          </TableCell>
                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "8px",
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="body_Grey1">{purchaseItem?.cgst}/{purchaseItem?.sgst}     </Typography>
                          </TableCell>
                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "8px",
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="body_Grey1"> {purchaseItem?.mrp}  </Typography>
                          </TableCell>

                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "8px",
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="body_Grey1"> {purchaseItem?.discount}</Typography>
                          </TableCell>

                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "8px",
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="body_Grey1">{purchaseItem?.rate}</Typography>
                          </TableCell>
                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              borderTopRightRadius: "20px",
                              borderBottomRightRadius: '20px',
                              color: 'neutral.black',
                              p: "8px",
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="body_Grey1"> Department {purchaseItem?.department_id} </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>

                  ) : (
                    <TableRow>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                        rowSpan={5}
                        colSpan={11}
                      >
                        <Typography color={"black"} variant="heading2">
                          Data Not Found
                        </Typography>

                        {/* <Box sx={{width:'100%' , margin:"auto",  display: isLoading? 'none' :'',}}>
      <Box sx={{width:'100%', display:'flex', alignItems:'center', flexDirection:'row',  justifyContent:'space-between' ,  backgroundColor :'white' , borderRadius:'20px' ,  height:'185px', marginTop:'100px'}}>
      
        <Box sx={{marginTop:'-80px' , marginLeft:'30px'}}>
        <img src={not_found} alt='' width={'160px'} />
        </Box> 
        
      <Box sx={{paddingRight:'20px', marginTop:'-14px'}}>
        
        <Typography fontSize={'22px'} fontWeight={'bold'} color={'neutral.navyBlue'}> <Typography variant="span" color={'black'}>  Data not found!</Typography> </Typography>
        {/* <Typography fontSize={'24px'}  fontWeight={'bold'} >You've made it </Typography> */}
                        {/* <Typography sx={{marginTop:"8px"}}> Data not found!</Typography> 
      </Box>

      </Box>   
  </Box> */}


                      </TableCell>
                    </TableRow>
                  ))}

              </Table>
            </TableContainer>


          </Grid>
        </Box>


      </Box>
      <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={detailsModal} setDetailsModal
        onClose={() => setDetailsModal(false)}
      >
        <PurchaseOrderDetails onClose={() => setDetailsModal(false)} />
      </CustomModal>

      <CustomModal
        customStyles={{ border: "none", minWidth: "85%", maxWidth: "92%" }}
        open={editPurchaseOrderModal}
        onClose={() => setEditPurchaseOrderModal(false)}
      >
        <EditPurchaseOrder onClose={() => setEditPurchaseOrderModal(false)}
          refetch={refetch}
        />
      </CustomModal>

      <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={newPurchaseOrderModal}
        onClose={() => setNewPurchaseOrderModal(false)}
      >
        <NewPurchaseOrder onClose={() => setNewPurchaseOrderModal(false)} />
      </CustomModal>
    </>
  );
};

export default PurchaseOrderDetails;
