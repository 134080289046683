import React from "react";

const PrintPrescription = React.forwardRef((prescription, ref) => {
  // console.log(prescription);
  if (!prescription?.prescription) return null;

  const getMarginTopForPageType = (organizationSettings, pageType) => {
    const setting = organizationSettings?.find(
      (setting) => setting?.page_type === pageType
    );
    return setting;
  };

  const headerMarginTop = getMarginTopForPageType(
    prescription?.prescription?.organization?.organization_settings,
    "prescription"
  );

  // console.log(prescription, "prescription");

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // DD/MM/YYYY format
  };

  const patientDOB = prescription?.prescription?.patient_dob
    ? formatDate(prescription?.prescription?.patient_dob)
    : "";
  const visitDate = prescription?.prescription?.appointment?.visit_date
    ? formatDate(prescription?.prescription?.appointment?.visit_date)
    : "";

  const doctor1 = prescription?.prescription?.appointment?.doctor?.details;
  const doctor1Details = doctor1?.replace(/\r\n/g, "").split("<br/>");
  // console.log(doctor1);

  const doctor2 =
    prescription?.prescription?.appointment?.consultant_doctor?.details;
  const doctor2Details = doctor2?.replace(/\r\n/g, "").split("<br/>");
  // console.log(doctor2);

  // console.log(prescription?.prescription?.organization?.organization_setting?.prescription_header_height)
  return (
    <div
      ref={ref}
      style={{
        //  minHeight:'1in'
        marginTop: `${headerMarginTop?.header_height && headerMarginTop?.is_active
          ? headerMarginTop?.header_height
          : "3.2"
          }in`,
        marginBottom: `${headerMarginTop?.footer_height && headerMarginTop?.is_active
          ? headerMarginTop?.footer_height
          : "1.2"
          }in`,
        paddingLeft: "70px",
        paddingRight: "80px",
        paddingTop: "5px",
        // maxWidth: "1000px",
        width: "1000px",
        color: "#3A3A3A",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Lato, sans-serif",
        justifyContent: "space-between",
      }}
    >
      <div>
        <div style={{ position: "relative", left: "0", top: "10px" }}>
          {/* { prescription?.prescription?.organization?.logo && <img
            src={prescription?.prescription?.organization?.logo}
            alt=""
            width="95"
            height=""
            className="logo-img"
          />} */}
        </div>

        {/* <h1
          style={{
            textAlign: "center",
            marginTop: "5px",
            marginBottom: "10px",
            fontSize: "32px", 
          }}
        >
          {" "}
          {prescription?.prescription?.organization?.name}{" "}
        </h1> */}
        <br />

        <div style={{ maxWidth: "1300px", margin: "auto" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* <div>
              <h1 style={{ fontSize: "24px", marginBottom: "10px" }}>
                {prescription?.prescription?.appointment?.doctor?.full_name}
              </h1>

             
            { doctor1Details?.map((item,index)=>
                <p key={index} style={{marginTop :'2px', marginBottom:'0px' , paddingTop:"2px" , paddingBottom :'0px'}}>{item}</p>
            )}

            </div> */}

            {/* <div style={{ marginRight: "10px" }}>
              <h1 style={{ fontSize: "24px", marginBottom: "10px" }}>
                {
                  prescription?.prescription?.appointment?.consultant_doctor
                    ?.full_name
                }
              </h1>
              { doctor2Details?.map((item,index)=>
               <p key={index} style={{marginTop :'2px', marginBottom:'0px' , paddingTop:"2px" , paddingBottom :'0px'}}>{item}</p>
            )}
               
            </div> */}
          </div>
          <br />
          {/* <hr /> */}
          <br />

          <div className="patient-details">
            <div
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p style={{ fontSize: "22px", fontWeight: "bolder" }}>
                <span style={{ fontWeight: "bolder" }}>
                  {prescription?.prescription?.patient_full_name}
                </span>{" "}
                ({prescription?.prescription?.patient_uid})
              </p>
              <p style={{ fontSize: "18px", fontWeight: "bolder" }}>
                Date: {visitDate}
              </p>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "18px",
              }}
              className="flex justify-between mb-3"
            >
              <span className="">
                {/*                
                Weight : &nbsp; */}
                <span className="">
                  Weight: {prescription?.prescription?.appointment?.weight}{" "}
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <span>
                  Age: {prescription?.prescription?.patient_age}{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  {/* {prescription?.prescription?.patient_age}{" "} */}
                </span>
              </span>

              <span className="">
                Mobile: {prescription?.prescription?.patient_mobile}{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}  &nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{ textTransform: 'capitalize' }}> {prescription?.prescription?.patient_gender}{" "}</span>
                {/* {prescription?.prescription?.patient_gender}{" "} */}
              </span>

              {prescription?.prescription?.appointment?.bp_d && (
                <span className="">
                  BP : &nbsp;
                  {prescription?.prescription?.appointment?.bp_s} /{" "}
                  {prescription?.prescription?.appointment?.bp_d}
                </span>
              )}

              {prescription?.prescription?.appointment?.visit_type === "New" ||
                prescription?.prescription?.appointment?.visit_type === "Old"
                ? `(Valid for 1 Revisit within ${headerMarginTop?.review_duration || "5"
                } days)`
                : prescription?.prescription?.appointment?.visit_type}
            </div>

            {prescription?.prescription?.diagnosis_list && (
              <table
                style={{
                  width: "100%",
                  // marginTop: "8px",
                  marginBottom: "-12px",
                }}
              >

                <tr>
                  <td style={{ width: "150px", verticalAlign: "top" }}>
                    <div>
                      <p>Diagnosis:</p>
                    </div>
                  </td>
                  <td>
                    {/* {prescription?.prescription?.diagnosis?.map((daig, index) => (
                    <div key={index}>
                      <p style={{ wordWrap: "break-word" }}>{daig.value} </p>
                    </div>
                  ))} */}

                    <p style={{ wordWrap: "break-word" }}>
                      {prescription?.prescription?.diagnosis_list}{" "}
                    </p>
                  </td>
                </tr>


              </table>
            )}


            {prescription?.prescription?.appointment?.investigations
              ?.length > 0 && (
                <table
                  style={{
                    width: "100%",
                    // marginTop: "-8px",
                    marginBottom: "-6px",
                  }}
                >
                  <tr>
                    <td
                      style={{
                        verticalAlign: "top",
                        width: "150px",
                        // padding: "4px",
                        margin: "0",
                      }}
                    >
                      <div>
                        <p>Investigations:</p>

                      </div>
                    </td>
                    <td
                      style={{
                        wordWrap: "break-word",
                      }}
                    >
                      <div>
                        <p style={{ margin: 0 }}>
                          {prescription?.prescription?.appointment?.investigations
                            .map((item) => `${item.name} ${item.value ? `(${item.value})` : ""}`)
                            .join(", ")}
                        </p>
                      </div>
                    </td>
                  </tr>

                </table>
              )}

            <table style={{
              width: "100%",
              // marginTop: "0px",
              marginBottom: "-6px",
            }}>
              <tr>
                <td style={{ width: "150px", verticalAlign: "top" }}></td>
                <td>
                  {" "}
                  {prescription.prescription?.daily_exercise
                    ? "Regular Exercise ,"
                    : " "}{" "}
                  {prescription.prescription?.physiotherapist
                    ? "Meet Physiotherapist ,"
                    : " "}{" "}
                  {prescription.prescription?.psychologist
                    ? "Meet psychologist ,"
                    : " "}{" "}
                  {prescription.prescription?.dietitian ? "dietitian, " : " "}{" "}
                </td>
              </tr>
            </table>

            {prescription?.prescription?.notes && (
              <table
                style={{
                  width: "100%",
                  // marginTop: "-7px",
                  marginBottom: "-6px",
                }}
              >

                <tr>
                  <td style={{ width: "150px", verticalAlign: "top" }}>
                    <div>
                      <p>Notes:</p>
                    </div>
                  </td>
                  <td>
                    <p style={{ wordWrap: "break-word" }}>
                      {prescription?.prescription?.notes}{" "}
                    </p>
                  </td>
                </tr>

              </table>
            )}

            {/* {prescription?.prescription?.notes &&     <div style={{ marginTop: "9px" }}>
              <span style={{ marginRight: "24px" }}>Notes: </span>{" "}
              {prescription?.prescription?.notes}{" "}
            </div>
            } */}

            <div style={{ marginBottom: "30px", marginTop: "10px" }}>
              <h5 style={{ marginBottom: "17px", fontSize: "20px" }}>
                <b>Medicines</b>
              </h5>

              {prescription?.prescription?.medicines?.map((medi, index) => (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0",
                    padding: "0",
                    marginTop: "4px",
                    marginBottom: medi?.salt ? "3px" : "",
                  }}
                >
                  <div
                    style={{
                      width: "2.2%",
                      fontSize: "16px",
                      marginTop: "2px",
                    }}
                  >
                    {index + 1}
                  </div>

                  <div
                    style={{
                      margin: "0",
                      padding: "0",
                      width: "39.8%",
                      marginTop: "2px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        margin: "0",
                        padding: "0",
                        fontWeight: "420px",
                      }}
                    >
                      {medi?.name}
                    </p>
                    <small
                      style={{
                        display: "block",
                        width: "950px",
                        fontSize: "12px",
                        margin: "0",
                        padding: "0",
                        fontWeight: "400",
                        color: "#3A3A3A",
                      }}
                    >
                      {medi?.salt}
                    </small>
                  </div>

                  <div
                    style={{
                      width: "29%",
                      margin: "0",
                      padding: "0",
                      marginTop: "2px",
                    }}
                  >
                    <p style={{ fontSize: "16px", margin: "0", padding: "0" }}>
                      {medi?.description.replace(/,/g, ", ")}
                    </p>
                  </div>

                  <div
                    style={{
                      width: "29%",
                      margin: "0",
                      padding: "0",
                      marginTop: "2px",
                    }}
                  >
                    <p style={{ fontSize: "16px", margin: "0", padding: "0" }}>
                      {medi?.remark}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            {/* <table style={{ marginBottom: "0px" }}>
              <tr>
                <td style={{ width: "150px" ,padding: "0" , margin:'0' }}>
                  <div>
                  {prescription?.prescription?.special_instructions
                  && 
                    <p> Spl instructions:</p>
                  }
                  </div>
                </td>
                <td>
                  <div>
                    <p>{prescription?.prescription?.special_instructions}</p>
                  </div>
                </td>
              </tr>

              <tr >
                <td style={{ width: "150px" , padding: "0", margin:'0'    }}>
                  <div >
                    {prescription?.prescription?.followup && 
                    <p>Follow up:</p>
}
                  </div>
                </td>
                <td>
                  <div>
                    <p>{prescription?.prescription?.followup}</p>
                  </div>
                </td>
              </tr>

              {/* <tr>
                <td style={{ width: "150px" }}>
                  <div>
                    <p>Videos:</p>
                  </div>
                </td>
                <td>
                  <div>
                    <p>
                      {" "}
                      <a href={prescription?.prescription?.vedios}> </a>{" "}
                    </p>
                  </div>
                </td>
              </tr> 

             

              <tr>
                <td style={{ width: "150px", verticalAlign: "top",  padding: "0" , margin:'0' } }>
                  <div>
                  {prescription?.prescription?.additional_tests &&   <p>Additional Tests:</p>}
                  </div>
                </td>
                <td style={{ wordWrap: "break-word" }}>
                  <div>
                    <p>
                      {prescription?.prescription?.additional_tests}
                    </p>
                  </div>
                </td>
              </tr>

              <tr>
                <td style={{verticalAlign: "top" , padding: "0", margin:'0'  }}>
                  <div>
                  {prescription?.prescription?.appointment?.investigations?.length>0  && <p>Investigations:</p> }
                  </div>
                </td>
                <td style={{ wordWrap: "break-word" }}>
                  <div>
                    <p>
                      {prescription?.prescription?.appointment?.investigations
                        .map((item) => item.toUpperCase())
                        .join(", ")}
                    </p>
                  </div>
                </td>
              </tr>

            </table> */}

            <table style={{ marginBottom: "0px", borderCollapse: "collapse" }}>

              {prescription?.prescription?.special_instructions && (
                <tr style={{ margin: "5px", padding: "5px" }}>
                  <td
                    style={{
                      width: "150px",
                      padding: "4px",
                      margin: "0",
                      verticalAlign: "top",
                    }}
                  >
                    <div>

                      <p style={{ margin: 0 }}>Spl instructions:</p>

                    </div>
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <div>
                      <p style={{ margin: 0 }}>
                        {prescription?.prescription?.special_instructions}
                      </p>
                    </div>
                  </td>
                </tr>
              )}

              {prescription?.prescription?.followup && (
                <tr style={{ margin: "8px", padding: "8px" }}>
                  <td
                    style={{
                      width: "150px",
                      padding: "4px",
                      margin: "0",
                      verticalAlign: "top",
                    }}
                  >
                    <div>

                      <p style={{ margin: 0 }}>Follow up:</p>

                    </div>
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <div>
                      <p style={{ margin: 0 }}>
                        {prescription?.prescription?.followup}
                      </p>
                    </div>
                  </td>
                </tr>
              )}

              <tr style={{ margin: "8px", padding: "8px" }}>
                <td
                  style={{
                    width: "150px",
                    verticalAlign: "top",
                    padding: "4px",
                    margin: "0",
                  }}
                >
                  <div>
                    {prescription?.prescription?.additional_tests && (
                      <p style={{ margin: 0 }}>Additional Tests:</p>
                    )}
                  </div>
                </td>
                <td
                  style={{
                    wordWrap: "break-word",
                    padding: "4px",
                    margin: "0",
                  }}
                >
                  <div>
                    <p style={{ margin: 0 }}>
                      {prescription?.prescription?.additional_tests}
                    </p>
                  </div>
                </td>
              </tr>


              {prescription?.prescription?.medical_tests_list && (
                <tr style={{ margin: "8px", padding: "8px" }}>
                  <td
                    style={{ verticalAlign: "top", padding: "4px", margin: "0" }}
                  >
                    <div>

                      <p style={{ margin: 0 }}>Medical Tests:</p>

                    </div>
                  </td>
                  <td
                    style={{
                      wordWrap: "break-word",
                      padding: "4px",
                      margin: "0",
                    }}
                  >
                    <div>
                      <p style={{ margin: 0 }}>
                        {prescription?.prescription?.medical_tests_list}
                      </p>
                    </div>
                  </td>
                </tr>
              )}

            </table>
          </div>
        </div>
      </div>

      {/* <div className="footer" style={{marginBottom:"15px"  }}> */}
      {/* <hr /> */}
      {/* <p className="text-center pt-3  text-[12px]">
          {prescription?.prescription?.organization?.address}{" "}
          {prescription?.prescription?.organization?.city}
          { (prescription?.prescription?.organization?.state)}{" "}
          {prescription?.prescription?.organization?.contact_number}{" "}
          {prescription?.prescription?.organization?.email} |{" "}
          {prescription?.prescription?.organization?.website} &nbsp; Advice
          given without physical checkup is Provisional, pending physical
          evaluation by a qualified Doctor.{" "}
          <b>Patient Can Buy Generic Medicine of Same Salt</b>
        </p> */}
      {/* </div> */}
    </div>
  );
});
PrintPrescription.displayName = "PrintPrescription";
export default PrintPrescription;
