import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { headingStyle, tableRowLab } from "../../style/LapPageStyle";
import { DShapeSaveButton, textfieldText } from "../../style/AppointmentStyle";
import { startBtn } from "../../style/LapPageStyle";
import { useSelector } from "react-redux";
import { getMedicalReports } from "../../connections/getApi";
import { medicalTestReports, MedicalTestReportsPrescription } from "../../connections/postApi";
import { showToast } from "../../utils/toastUtil";
import {
  primaryBtnBlue,
  textfieldTextPrep,
} from "../../style/PatientDetailsStyle";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import not_found from "../../assets/not_found.svg";
import DoneIcon from "@mui/icons-material/Done";

const PatientMedicalReports = ({ onClose }) => {
  const patientDetailsData = useSelector(
    (state) => state.prescription.patientDetailsData
  );

  const [testReportsData, setTestReports] = useState({
    // full_name: "",
    // uid: "",
    // current_age: "",
    // gender: "",
    // reports_medical_test: [],
  });

  // const [reportValues, setReportValues] = useState({});

  // const handleValueChange = (e, reportIndex) => {

  //   const { value } = e.target;
  //   setReportValues((prevValues) => ({
  //     ...prevValues,
  //     [reportIndex]: e.target.value,
  //   }));
  // };
  const handleValueChange = (e, reportIndex, report) => {
    const { value } = e.target;
    // console.log(report, reportIndex, value);

    setTestReports((prevReports) => {
      const updatedReports = { ...prevReports }; // Clone the top-level object
      updatedReports.reports_medical_test = [
        ...prevReports.reports_medical_test,
      ]; // Clone the array

      // Update the specific report's value
      updatedReports.reports_medical_test[reportIndex] = {
        ...prevReports.reports_medical_test[reportIndex],
        reports: [
          {
            ...prevReports.reports_medical_test[reportIndex]?.reports[0],
            medical_report_value: value,
          },
        ],
      };
      return updatedReports; // Return the updated object
    });
  };

  // const handleValueChange = (e, reportIndex, report) => {
  //   const { value } = e.target;
  //   console.log(report, reportIndex, value);
  //   setReportValues((prevValues) => ({
  //     ...prevValues,
  //     report[reportIndex]?.reports[0]: value, // Directly update the specific index
  //   }));
  // };
  // console.log(reportValues);
  const saveReport = async (report, index) => {
    const data = {
      id: report?.reports[0]?.report_id,
      medical_test_report: {
        value: report?.reports[0]?.medical_report_value,
      },
    };
    // console.log(report);

    try {
      const response = await MedicalTestReportsPrescription(data);
      console.log("Report saved:", response);
      showToast(
        response?.status?.message || response?.status?.status,
        response?.status?.status
      );
    } catch (error) {
      console.error("Error saving report:", error);
    }
  };

  useEffect(() => {
    const fetchMedicalReports = async () => {
      try {
        const res = await getMedicalReports(patientDetailsData.id);
        setTestReports(res);
        console.log(res);
      } catch (error) {
        console.error("Error fetching medical reports:", error);
      }
    };

    if (patientDetailsData?.id) {
      fetchMedicalReports();
    }
  }, [patientDetailsData]);

  //  console.log(patientDetailsData)
  return (
    <Box sx={{ width: "90vw", overflowX: "auto" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box></Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ContentPasteSearchIcon
            sx={{
              color: "neutral.navyBlue",
              width: "28px",
              height: "28px",
              marginRight: "8px",
              marginTop: "-4px",
            }}
          />
          <Typography
            variant="heading1"
            fontWeight={600}
            gutterBottom
            sx={{ ...headingStyle }}
          >
            Test Reports
          </Typography>
        </Box>

        <IconButton sx={{ marginBottom: "2rem" }} onClick={onClose}>
          <CloseIcon size="small" sx={{ color: "red" }} />
        </IconButton>
      </Box>

      <Grid
        container
        sx={{
          justifyContent: "space-between",
          width: "100%",
          mt: "30px",
          marginBottom: "20px",
        }}
      >
        <Typography>Name: {testReportsData?.full_name} </Typography>
        <Typography>Patient ID: {testReportsData?.uid} </Typography>
        <Typography>Age : {testReportsData?.current_age}</Typography>
        <Typography>Gender: {patientDetailsData?.gender}</Typography>
      </Grid>

      <Grid container spacing={2} marginBottom="auto">
        <Grid item xs={12}>
          <TableContainer sx={{
            mt: 1, width: "100%",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
            "scrollbar-width": "none",
          }}>
            {testReportsData?.reports_medical_test?.length > 0 ? (
              <Table sx={{
                width: "100%",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  display: "none", // Hide the scrollbar for Webkit browsers (Chrome, Safari)
                },
                "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
                "scrollbar-width": "none", // Hide scrollbar for Firefox           
              }}>
                <TableBody>
                  {testReportsData?.reports_medical_test?.map(
                    (report, index) => (
                      <TableRow sx={{
                        backgroundColor: '#F7F7FF',
                        paddingY: "6px",
                        cursor: "pointer",
                        borderBottom: "8px solid white",
                        borderTop: index === 0 ? "2px solid white" : "",
                        transition: "all 0.3s ease-in-out", // Smooth transition for hover effects
                        "&:hover": {
                          transform: "scale(1.001)", // Slight scaling for the pop-up effect
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for outer glow
                          borderRadius: "20px !important",
                        },
                      }} key={index}

                      >
                        <TableCell
                          sx={{
                            padding: "2px",
                            paddingLeft: "15px",
                            width: "20%",
                            marginTop: "10px",
                            borderTopLeftRadius: "20px",
                            borderBottomLeftRadius: "20px",
                            // border: "1px solid red",
                          }}
                        >
                          <Typography
                            sx={{ padding: "0px" }}
                            variant="heading2"
                            fontWeight={"600"}
                          >
                            {report?.medical_test}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            padding: "0px",
                            paddingLeft: "15px",
                            width: "30%",
                            // border: "1px solid red",
                          }}
                        >
                          <Box
                            sx={{
                              width: "220px",
                              display: "flex",
                              justifyContent: "space-between",
                              mt: 1,
                            }}
                          >
                            <Box>
                              <TextField
                                placeholder="Enter value"
                                sx={{
                                  ...textfieldTextPrep,
                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: "0px",
                                    borderTopLeftRadius: "15px",
                                    borderBottomLeftRadius: "15px",
                                    height: "38px",
                                    padding: "9px !important",
                                    "& input": {
                                      padding: "4px",
                                      fontSize: "14px",
                                    },
                                  },
                                }}
                                variant="outlined"
                                name="valueAction"
                                // value={reportValues[index] || ""}
                                value={
                                  report?.reports[0]?.medical_report_value || ""
                                } // Ensure an empty string if undefined
                                onChange={(e) =>
                                  handleValueChange(e, index, report)
                                }
                              />
                              <Typography variant="body2" color="textSecondary" sx={{ mt: "2px", ml: '6px' }}>
                                {report?.reports[0]?.generated_at}
                              </Typography>
                            </Box>

                            <Button
                              sx={{
                                ...DShapeSaveButton,
                                py: "2px",
                                px: "4px",
                                height: "38px",
                                color: "white",
                              }}
                              onClick={() => saveReport(report, index)}
                            >
                              <DoneIcon sx={{ color: "neutral.navyBlue" }} />
                            </Button>
                          </Box>
                        </TableCell>

                        <TableCell
                          sx={{
                            // width: "60%",
                            display: "flex",
                            overflowX: "auto",
                            borderTopRightRadius: "20px",
                            gap: "70px",
                            borderBottomRightRadius: "20px",
                          }}
                        >
                          {report?.reports?.map((el) => (
                            <Box>
                              {el?.medical_report_value || "-"}
                              <br />
                              {el?.generated_at}
                            </Box>
                          ))}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "30%",
                  margin: "auto",
                }}
              >
                <Box sx={{ margin: "auto", width: "40%" }}>
                  <img
                    src={not_found}
                    alt=""
                    width={"100%"}
                    sx={{ margin: "auto" }}
                  />
                </Box>
                <Typography align="center" fontSize={"17px"} color={"crimson"}>
                  {" "}
                  Test reports not found for selected patient
                </Typography>
              </Box>
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientMedicalReports;
