import { Box, Chip, Grid, keyframes, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { slotChip, activeChip } from "../../style/PatientDetailsStyle";
import PatientInfoForm from "./PatientDetailsComponent/PatientInfoForm";
import TreatmentDetail from "./PatientDetailsComponent/TreatmentDetail";
import Medicine from "./PatientDetailsComponent/Medecine";
import { useSelector, useDispatch } from "react-redux";
import {
  setPrescriptionData,
  setPatientDetailsData,
  setAppointmentData,
} from "../../redux/slices/prescriptionSlice";
import UseFetchAppointmentDetailsPatient from "../../helper/UseFetchAppointmentDetailsPatient";
import UsePatientDetailPrescription from "../../helper/UsePatientDetailPrescription";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import loadingSpinner from "../../assets/loadingSpinner.svg";
import { getPatientPrescription } from "../../connections/getApi";

const rotateSpinner = keyframes`
 0% { transform: rotate(0deg); }
 10% { transform: rotate(36deg); }
 20% { transform: rotate(72deg); }
 30% { transform: rotate(108deg); }
 40% { transform: rotate(144deg); }
 50% { transform: rotate(180deg); }
 60% { transform: rotate(216deg); }
 70% { transform: rotate(252deg); }
 80% { transform: rotate(288deg); }
 90% { transform: rotate(324deg); }
 100% { transform: rotate(360deg); }
`;

const PatientDetails = () => {
  const dispatch = useDispatch();
  const patientDetailsData = useSelector(
    (state) => state.prescription.patientDetailsData
  );

  const [commonData, setCommonData] = useState({
    patientForm: {},
    treatmentDetail: {},
    investigations: [],
    medicines: [],
  });

  const [errors, setErrors] = useState({});
  const [appointmentsDate, setAppointmentsDate] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [appointmentId, setAppointmentId] = useState(null);
  const [prescriptionId, setPrescriptionId] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [searchPrevMedicine, setSearchPrevMedicine] = useState(false);

  // Fetch appointment details and prescription details for the selected appointment
  const { data: appointmentDetails, isLoading: appointmentDataLoading, status: appointmentStatus } =
    UseFetchAppointmentDetailsPatient(appointmentId);
  const { data: prescriptionDetails, isLoading: prescriptionDataLoading, status: prescriptionStatus } =
    UsePatientDetailPrescription(prescriptionId);

  useEffect(() => {
    if (patientDetailsData?.appointments) {
      const dates = patientDetailsData?.appointments?.filter(appointment =>
        ["completed", "paid"].includes(appointment.status) ||
        appointment?.prescription_id).map((appointment) => ({
          date: appointment?.visit_date ? new Date(appointment?.visit_date).toDateString() :
            "null",
          id: appointment.id, // Track the appointment ID for fetching details
          prescription_id: appointment?.prescription_id,
          appointmentStatus: appointment?.status,
        }));
      setAppointmentsDate(dates);
      if (dates?.length > 0) {
        setSelectedAppointment(dates[0].id); // Set the first (latest) appointment as selected
        // setAppointmentId(dates[0].id); // Fetch the latest appointment data
      }
    }
    else {
      setAppointmentsDate([]);
    }
  }, [patientDetailsData]);

  // console.log(appointmentsDate , 'appointmentsDate patient  details')
  //  console.log(appointmentsDate)
  //  console.log(appointmentsDate[1]?.prescription_id)

  // useEffect(() => {
  //   if ( patientDetailsData?.appointments?.length === 0) {
  //     setAppointmentsDate([]); // Explicitly clear appointments when data is empty
  //   }
  // }, [patientDetailsData]);

  const changeAppointment = (id) => {
    dispatch(setAppointmentData({})); // remove previous appointment & prescription details  patient data
    dispatch(setPrescriptionData({}));
    setPrescriptionId(null);  // there can be chance same prescription id is already saved in setPrescription    
    setCommonData({
      ...commonData,
      patientForm: {},
      treatmentDetail: {},
      investigations: [],
      medicines: [],
    });
    setTimeout(() => {
      setSelectedAppointment(id); // Update selected appointment
      setAppointmentId(id); // Trigger data fetching for the new appointment
    });
  };

  // Set prescription ID when appointment details are fetched
  // console.log(appointmentDetails, 'appointmentDetails')
  useEffect(() => {
    if (appointmentDetails) {
      if (appointmentStatus === 'success' && !appointmentDataLoading) {
        dispatch(setAppointmentData(appointmentDetails));
      }
      if (appointmentDetails?.prescription?.id) {
        setPrescriptionId(appointmentDetails?.prescription?.id); // Set the prescription ID for fetching
        // console.log('called prescription', appointmentDetails?.prescription?.id)
      }
    }
  }, [appointmentDetails]);

  // When appointment details and prescription details are fetched, dispatch the data to Redux


  useEffect(() => {
    if (prescriptionDetails) {
      // if(appointmentStatus==='success' && !appointmentDataLoading){
      // dispatch(setAppointmentData(appointmentDetails));
      // }
      // Dispatch appointment data
      if (prescriptionStatus === 'success' && !prescriptionDataLoading) {
        dispatch(setPrescriptionData(prescriptionDetails));
      }
      // console.log('data saved prescription')
      // Dispatch prescription data
      //  console.log(appointmentDetails, prescriptionDetails);
    }
  }, [prescriptionDetails]);


  // console.log(appointmentsDate, 'appointmentsDate');

  const fetchPrescription = async () => {

    if (appointmentsDate?.length > 0 && (appointmentsDate[0]?.prescription_id || appointmentsDate[1]?.prescription_id)) {
      const prescriptionID = appointmentsDate[0]?.prescription_id || appointmentsDate[1]?.prescription_id;
      if (prescriptionID) {
        try {
          const res = await getPatientPrescription(prescriptionID);

          if (res) {
            dispatch(setPrescriptionData(res));
          }
        }
        catch (error) {
          console.error("Error fetching prescription:", error);
        }
      }
      else {
        console.log('not prescription id found in recent appointment ')
      }
    }

    // if (!appointmentDetails?.prescription_id) {
    //   if (patientDetailsData?.appointments?.length > 1 && !patientDetailsData?.appointments[0]?.prescription_id) {

    //     if (patientDetailsData?.appointments && appointmentsDate?.length >= 2 && (appointmentsDate[1]?.prescription_id)) {
    //       try {
    //         const res = await getPatientPrescription(patientDetailsData?.appointments[1]?.prescription_id);
    //         console.log(res, "Fetched Prescription Data TO save medicine from an prev prescription");
    //         if (res) {
    //           dispatch(setPrescriptionData(res));
    //         }
    //       }
    //       catch (error) {
    //         console.error("Error fetching prescription:", error);
    //       }
    //     }
    //   } else {
    //     console.warn("No truthy prescription ID found in appointments");
    //   }
    // }

    // else {
    //   if (appointmentsDate && appointmentsDate[0]?.prescription_id) {
    //     try {
    //       const res = await getPatientPrescription(patientDetailsData?.appointments[0]?.prescription_id);
    //       console.log(res, "Fetched Prescription Data TO save medicine from an prev prescription");
    //       if (res) {
    //         dispatch(setPrescriptionData(res));
    //       }
    //     }
    //     catch (error) {
    //       console.error("Error fetching prescription:", error);
    //     }
    //   }
    // }

  };

  const [allDataLoading, setAllDataLoading] = useState(false);
  // Debounce effect to stabilize allDataLoading state
  useEffect(() => {
    if (appointmentDataLoading) {
      // console.log(patientDetailsLoading);
      setAllDataLoading(true); // Set to true immediately when patientDetailsLoading starts
    } else if (!appointmentDataLoading && !prescriptionDataLoading) {
      // Use a timeout to debounce the false state change
      const timer = setTimeout(() => {
        setAllDataLoading(false);
        // console.log("i am loader", "all api loader");
      }, 800);

      return () => clearTimeout(timer); // Clear timeout if dependencies change before debounce ends
    }
  }, [appointmentDataLoading, prescriptionDataLoading]);

  const displayedAppointments = showAll
    ? appointmentsDate
    : appointmentsDate.slice(0, 5);

  return (
    <Grid
      spacing={2}
      container
      lg={8}
      sx={{
        border: "none !important",
        outline: "none !important",
      }}
    >
      <Modal
        //  open={isLoading}
        open={allDataLoading}
        sx={{
          position: "absolute",
          border: "none !important",
          outline: "none !important",
          top: "40%",
          left: "40%",
        }}
      >
        <Box
          sx={{
            animation: `${rotateSpinner} 1s linear infinite`,
            width: "50px",
            border: "none !important",
            outline: "none !important",
          }}
        >
          <img
            src={loadingSpinner}
            alt=""
            style={{
              width: "50px",
              height: "50px",
              border: "none !important",
              outline: "none !important",
            }}
          />
        </Box>
      </Modal>

      {appointmentsDate?.length > 0 && (
        <Grid
          display={"flex"}
          alignItems={"center"}
          sx={{
            backgroundColor: "#F1F1FF",
            marginLeft: "18px",
            marginTop: "8px",
            marginBottom: "-20px",
            marginRight: "40px",
          }}
          flexWrap={"wrap"}
          gap={"8px"}
          item
          lg={12}
        >
          {appointmentsDate?.length > 5 && !showAll && (
            <Chip
              // className="btn draw-border"
              sx={{
                ...activeChip,
                color: "white",
                paddingX: "0px",
                border: "none",
                "&:hover": {
                  backgroundColor: "#0067FF !important",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
              label={
                <ArrowBackIosNewIcon
                  sx={{ height: "15px", marginTop: "3px" }}
                />
              }
              variant="outlined"
              onClick={() => setShowAll(true)} // Show all appointments on click
            />
          )}

          {displayedAppointments
            ?.slice()
            .reverse()
            .map((appointment, index) => (
              <Chip
                key={appointment.id}
                sx={{
                  ...(appointment.id === selectedAppointment
                    ? activeChip
                    : { ...slotChip, backgroundColor: "#FFFFFF" }),
                  //  "&:hover": activeChip,
                }}
                label={appointment?.date}
                variant="outlined"
                // onClick={() =>
                // {  appointment?.id !== selectedAppointment && changeAppointment(appointment?.id);
                //      fetchPrescription();
                // }
                // } 
                onClick={() => {
                  if (appointment?.id !== selectedAppointment) {
                    // Check if this is the last chip
                    changeAppointment(appointment?.id);
                    if (index === displayedAppointments?.length - 1) {
                      fetchPrescription(); // Call fetchPrescription only for the last chip
                    }
                  }
                }}

              />
            ))}
        </Grid>
      )}

      <Grid item lg={12}>
        <PatientInfoForm
          setCommonData={setCommonData}
          errors={errors}
          setErrors={setErrors}
        />
      </Grid>
      <Grid item lg={12}>
        <TreatmentDetail setCommonData={setCommonData} />
      </Grid>
      <Grid sx={{ backgroundColor: "#F7F7FF" }} item lg={12}>
        <Medicine
          commonData={commonData}
          setErrors={setErrors}
          setCommonData={setCommonData}
        />
      </Grid>
    </Grid>
  );
};

export default PatientDetails;
