// import { useEffect } from "react";

// export const RequestType = {
//   GET: "get",
//   POST: "post",
//   PUT: "put",
//   DELETE: "delete",
//   PATCH: "patch",
// };

// export const BASE_URL = getBaseUrl();

// // console.log(BASE_URL)

// export function getBaseUrl() {
//   const getSubDomain = async () => {
//     const waitForSubDomain = () => {
//       return new Promise((resolve) => {
//         const timeOut = setTimeout(() => {
//           const subDomain = localStorage.getItem("cyphermdSubDomain");
//           if (subDomain) {
//             resolve(subDomain);
//           }
//         }, 50);
//       });
//     };

//     return (await waitForSubDomain()) || "wdc";
//   };
//   // const subDomain=   getSubDomain();
//    const subDomain=  'wdc'
//   const hostname = window.location.hostname;
//   const [clientCode] = hostname.split(".");

//   // Handle localhost or direct cyphermd client
//   if (hostname.includes("localhost")) {
//     console.log(`https://${clientCode} , ${subDomain} , 'subDomain `);
//     return `https://${subDomain}.staging.cyphermd.com`; // For local development
//   }

//   if (hostname.includes("cyphermd")) {
//     console.log(`https://${clientCode} , ${subDomain} ,'subDomain `);
//     return `https://${subDomain}.staging.cyphermd.com`; // Direct cyphermd URL
//   }

//   // Check if the environment is staging or production
//   const isStaging = hostname.includes("staging");
//   const domainSuffix = isStaging
//     ? "staging.cyphermd.com"
//     : "staging.cyphermd.com"; // Assuming "cyphermd.com" for production
//   console.log(`https://${clientCode}.${domainSuffix}`);
//   // Construct the base URL dynamically based on client code and environment
//   return `https://${clientCode}.${domainSuffix}`;
// }
import React, { createContext, useContext } from "react";
import { useSelector } from "react-redux";

export const RequestType = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
  PATCH: "patch",
};

// Helper function to get the base URL dynamically
function GetBaseUrl(subDomainValue) {
  const subDomain = subDomainValue || localStorage.getItem("cyphermdSubDomain");
  const hostname = window.location.hostname;
  // const [clientCode] = hostname.split(".");
  // https://staging.cyphermd.com
  // https://app.cyphermd.com
  // http://localhost:3000
  const isLocalHost = hostname.includes("localhost");
  const isStaging = hostname.includes("staging");
  const isLive = hostname.includes("app.cyphermd.com") && !isStaging;
  // console.log(hostname, "hostname");
  if (isLocalHost) {
    return `https://${subDomain}.staging.cyphermd.com`;
    //  return `https://${subDomain}.cyphermd.in`;

  }
  if (isStaging) {
    return `https://${subDomain}.staging.cyphermd.com`;
  }
  if (isLive) {
    return `https://${subDomain}.cyphermd.in`;
  }
  // return `https://${subDomain}.staging.cyphermd.com`;
  return `https://${subDomain}.cyphermd.in`;

  // const isStaging = hostname.includes("staging");
  // const domainSuffix = isStaging ? "staging.cyphermd.com" : "cyphermd.com";
  // return `https://${clientCode}.${domainSuffix}`;
}

const BaseUrlContext = createContext();

export const useBaseUrl = () => useContext(BaseUrlContext);

export let BASE_URL = "";

export const BaseUrlProvider = ({ children }) => {
  const { subDomainValue } = useSelector((state) => state.auth);
  BASE_URL = GetBaseUrl(subDomainValue);

  return (
    <BaseUrlContext.Provider value={BASE_URL}>
      {children}
    </BaseUrlContext.Provider>
  );
};
