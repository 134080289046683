import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  Autocomplete,
  MenuItem,
  FormControlLabel,
  Checkbox,
  // ListSubheader,
  Chip,
  // FormControl,
  Popper,
  List,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  orangeButton,
  primaryBtnBlue,
  slotChip,
  textfieldTextBlueBorder,
  textfieldTextPrep,
} from "../../../style/PatientDetailsStyle";
import { drTableRow } from "../../../style/DoctorListComponent";
import { menuItemStyle, primaryBtn } from "../../../style/AppointmentStyle";
import CloseIcon from "@mui/icons-material/Close";
// import UseGetMedicineList from "../../../helper/UseGetMedicineList";
// import UseGetAllMedicine from "../../../helper/UseFetchVideoList";
import UseGetFollowUpList from "../../../helper/UseGetFollowUpList";
import useDebounce from "../../../hooks/UseDebounce";
// import UseFetchMeDicineDescription from "../../../helper/UseFetchMeDicineDescription";
import UseGetAllDescription from "../../../helper/UseGetAllDescription";
// import UseMedicineRemarkList from "../../../helper/UseMedicineRemarkList";
import UseGetAllRemarks from "../../../helper/UseGetAllRemarks";
import { medicalTestShortCode } from "../../../connections/getApi";
import {
  setAppointmentData,
  setPatientDetailsData,
  setPrescriptionData,
} from "../../../redux/slices/prescriptionSlice";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import PrintPrescription from "../printPrescription";
import { showToast } from "../../../utils/toastUtil";
import {
  createAndUpdatePrescription,
  createAppointment,
  createPatient,
  savePatientAppointment,
} from "../../../connections/postApi";
import { getMedicineByName } from "../../../connections/getApi";
import { PrescriptionForm } from "../../../utils/constant";
import UseFetchVideoList from "../../../helper/UseFetchVideoList";
import { rescheduleAppointmentData } from "../../../connections/postApi";
// import { tableRowLab } from "../../../style/LapPageStyle";
// import { autoFocusOff } from "../../../style/PatientsInfoStyle";
import UseGetAllMedicineList from "../../../helper/UseGetAllMedicineList";
import UseGetShortCodeList from "../../../helper/UseGetShortCodeList";
import UseGetRecomendation from "../../../helper/UseGetRecomendation";
import UseGetMedicalTests from "../../../helper/UseGetMedicalTests";
import { drTableRowBlueBg } from "../../../style/PatinetFormStyle";
import { xBtn } from "../../../style/LapPageStyle";
import UseGetAllMedicineSaltList from "../../../helper/UseGetAllMedicineSaltList";
import { setRenderPrescriptionTable } from "../../../redux/slices/prescriptionSlice";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useTour } from "@reactour/tour";
dayjs.extend(utc);
dayjs.extend(timezone);

const Medicine = ({ commonData, setErrors, setCommonData }) => {
  const { currentStep, setIsOpen, setCurrentStep, steps } = useTour();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMedicines, setFilteredMedicines] = useState([]);
  const [filteredRemarks, setFilteredRemarks] = useState([]);
  // console.log(commonData);
  const [mobileNumber, setmobileNumber] = useState(
    commonData?.patientForm?.mobile
  );
  const [filteredDescriptionList, setFilteredDescriptionList] = useState([]);
  const patientDetailsData = useSelector(
    (state) => state.prescription.patientDetailsData
  );
  const appointmentData = useSelector(
    (state) => state.prescription.appointmentData
  );
  const prescriptionData = useSelector(
    (state) => state.prescription.prescriptionData
  );

  // console.log(prescriptionData)
  const [openMedicineList, setOpenMedicines] = useState(0);
  const [openRemarkList, setOpenRemarkList] = useState(0);
  const [openDescriptionList, setOpenDescriptionList] = useState(0);
  // const [shortCode, setShortCode] = useState(null);
  const [medicines, setMedicines] = useState(
    prescriptionData?.medicines || [
      { id: "", name: "", description: "", remark: "" },
    ]
  );
  const [followUp, setFollowUp] = useState(prescriptionData?.followup);
  const [highlightedIndexMedicine, setHighlightedIndexMedicine] = useState(0);
  const [highlightedIndexDescription, setHighlightedIndexDescription] =
    useState(0);
  const [highlightedIndexRemark, setHighlightedIndexRemark] = useState(0);

  const [additionalTests, setAdditionalTests] = useState(
    prescriptionData?.additional_tests
  );
  // useEffect(() => {

  // }, [prescriptionData]);

  //  console.log(prescriptionData?.followup  ,followUp ,    additionalTests , prescriptionData?.additional_tests)
  const [medicalTestList, setMedicalTestList] = useState(
    prescriptionData?.medical_tests_list
  );
  const [inputValue, setInputValue] = useState("");
  const [openDropdown, setOpenDropdown] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [checkBoxes, setCheckBoxes] = useState({});
  const [prescriptionSuccessRes, setPrescriptionSuccessRes] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    if (commonData?.medicines?.length > 0) {
      setMedicines((prevMedicines) => {
        // Filter out medicines with IDs that already exist in the current list
        const newMedicines = commonData.medicines.filter(
          (newMed) => !prevMedicines.some((med) => med?.id === newMed?.id)
        );
        return [
          ...prevMedicines.filter((med) => med.name !== "" && med.id !== ""),
          ...newMedicines,
          ...[{ id: "", name: "", description: "", remark: "" }], // Only add medicines that are not already in the list
        ];
      });
    }
    // console.log(commonData?.treatmentDetail?.medicalTests)
    if (commonData?.treatmentDetail) {
      // console.log(commonData?.treatmentDetail?.medicalTests)
      setInputValue(commonData?.treatmentDetail?.medicalTests);
      setMedicalTestList(commonData?.treatmentDetail?.medicalTests);
    }
  }, [commonData?.medicines, commonData?.treatmentDetail?.medicalTests]);


  // console.log(commonData)
  // note : don't delete any commented code


  // const [vedios, setVedios] = useState(prescriptionData?.vedios || "");

  // const transformVideoData = (data) => {
  //   // Safeguard against undefined or null data
  //   if (!data || !data.video_categories) return [];

  //   // Process the video categories and videos
  //   return data.video_categories.flatMap((category) =>
  //     category?.videos?.map((video) => ({
  //       label: video.title,
  //       group: category.title,
  //     }))
  //   );
  // };

  // note : don't delete any commented code
  // const { data: videoOptions, isError } = UseFetchVideoList();

  // let transformedVideos = [];
  // if (!isLoading && !isError && videoOptions) {
  //   transformedVideos = transformVideoData(videoOptions);
  // }

  // Transform the data after fetching
  // const transformedVideos = videoOptions ? transformVideoData(videoOptions) : [];
  // console.log(videoOptions);
  // const[prescriptionPrintPayload, setPrescriptionPrintPayload] =useState({});

  // const debouncedInputValue = useDebounce(
  //   medicines?.length ? medicines?.[medicines.length - 1]?.name : "",
  //   0
  // );
  // const debounceDecriptionvalue = useDebounce(
  //   medicines?.[medicines.length - 1]?.description,
  //   300
  // );
  // const debouncedRemarkValue = useDebounce(
  //   medicines?.[medicines.length - 1]?.remark,
  //   300
  // );

  // get medicine , description , remarks old logic
  // Fetch medicine list based on the debounced input
  // const { data: medicineList } = UseGetMedicineList(debouncedInputValue);
  // const { data: medicineDescriptionList } = UseFetchMeDicineDescription( debounceDecriptionvalue);
  // const { data: remarkList } = UseMedicineRemarkList(debouncedRemarkValue);
  const dispatch = useDispatch();

  // handle shortCode
  const fetchSelectedShortCode = async (shortCode) => {
    const shortCodePayload = await medicalTestShortCode(shortCode);
    if (shortCodePayload) {
      const medicalTests = shortCodePayload?.medical_tests;
      const namesString = medicalTests?.map((test) => test?.name).join(", ");
      const newTestNames = namesString?.split(", ");

      // Filter out duplicates from medicalTestList
      const uniqueTestNames = newTestNames?.filter(
        (testName) => !medicalTestList?.includes(testName)
      );

      // If no unique tests are found, simply return
      if (uniqueTestNames?.length === 0) {
        return;
      }
      // Join the unique test names into a single string
      const uniqueNamesString = uniqueTestNames?.join(", ");

      // Update medicalTestList state
      setMedicalTestList((medicalTestList || "") + " " + uniqueNamesString);
      setInputValue((medicalTestList || "") + " " + uniqueNamesString);
    }
  };

  // console.log(prescriptionData?.medicines)
  useEffect(() => {
    if (prescriptionData?.medicines) {
      // console.log(...prescriptionData?.medicines)
      setMedicines([
        ...prescriptionData?.medicines,
        { id: "", name: "", description: "", remark: "" },
      ]);
    } else {
      setMedicines([{ id: "", name: "", description: "", remark: "" }]);
    }
    if (prescriptionData) {
      setFollowUp(prescriptionData?.followup || "");
      setAdditionalTests(prescriptionData?.additional_tests || "");
    }
  }, [prescriptionData]);

  const debouncedFollowUpValue = useDebounce(followUp, 200);

  const { data: medicineList } = UseGetAllMedicineList();
  const { data: medicineSaltList } = UseGetAllMedicineSaltList();
  const { data: medicineDescriptionList } = UseGetAllDescription();
  const { data: remarkList } = UseGetAllRemarks();

  const { data: followUpList } = UseGetFollowUpList(debouncedFollowUpValue); //get follow up  this one will remain same using debounce to search
  const { data: shortCodeList } = UseGetShortCodeList();
  const { data: recomendations } = UseGetRecomendation();
  const { data: medicalList } = UseGetMedicalTests();

  // console.log(medicineSaltList?.payload?.medicine_salt)

  const handleAddRow = () => {
    setMedicines([
      ...medicines,
      { id: "", name: "", description: "", remark: "" },
    ]);
  };

  const handleRemoveRow = (index) => {
    const newMedicines = medicines.filter((_, idx) => idx !== index);
    setMedicines(newMedicines);
  };

  // const handleChange = (index, field, value) => {
  //   // console.log(index, field, value);
  //   // handleSearch(e);
  //   const newMedicines = medicines.map((medicine, idx) =>
  //     idx === index ? { ...medicine, [field]: value } : medicine
  //   );
  //   setMedicines(newMedicines);
  // };

  const printPrescriptionData = () => {
    if (!patientDetailsData?.id && !appointmentData?.id) {
      showToast(
        "Patient is not registered or has not taken an appointment. Click on 'Create New Visit' to create a prescription.",
        "ERROR"
      );
      setButtonLoading(false);
      return;
    }

    setPrescriptionSuccessRes(prescriptionData);
    setTimeout(() => {
      // setMedicines([]);
      handlePrint();
      showToast(`Generating prescription PDF`, "SUCCESS");
    }, 300);
  }

  const handleChange = (index, field, value) => {
    const newMedicines = [...medicines];
    newMedicines[index] = { ...newMedicines[index], [field]: value };
    setMedicines(newMedicines);
  };

  const updateNameAndId = (index, value) => {
    const newMedicines = [...medicines];
    newMedicines[index] = {
      ...newMedicines[index],
      id: value[0],
      name: value[1],
    };
    setMedicines(newMedicines);
  };
  // console.log(filteredMedicines , 'filterMedi');
  // console.log(filteredDescriptionList , 'filterDescription');
  // console.log(filteredRemarks , 'filter Remarks ');

  //storing  medicine , remarks , and description whole data

  useEffect(() => {
    // if (
    //   medicineList?.payload?.medicines &&
    //   medicineList?.payload?.medicines?.length > 0
    // ) {
    //   localStorage.setItem(
    //     "allMedicines",
    //     JSON.stringify(medicineList?.payload?.medicines)
    //   );
    // }

    if (
      medicineList?.payload?.medicines &&
      medicineList?.payload?.medicines?.length > 0 &&
      medicineSaltList?.payload?.medicine_salt
    ) {
      localStorage.setItem(
        "allMedicines",
        JSON.stringify([
          ...medicineList?.payload?.medicines,
          ...medicineSaltList?.payload?.medicine_salt,
        ])
      );
    }

    if (
      remarkList?.payload?.medicine_remarks &&
      remarkList?.payload?.medicine_remarks?.length > 0
    ) {
      localStorage.setItem(
        "allRemarks",
        JSON.stringify(remarkList?.payload?.medicine_remarks)
      );
    }

    if (
      medicineDescriptionList?.payload?.medicine_descriptions &&
      medicineDescriptionList?.payload?.medicine_descriptions?.length > 0
    ) {
      localStorage.setItem(
        "allDescription",
        JSON.stringify(medicineDescriptionList?.payload?.medicine_descriptions)
      );
    }
  }, [medicineList, remarkList, medicineDescriptionList]);

  const handleSearch = (event) => {
    // console.log(event.target.value);
    const query = event.target.value.toLowerCase();
    setSearchTerm(query);

    const storedMedicines =
      JSON.parse(localStorage.getItem("allMedicines")) || [];

    // Filter medicines based on search query
    const filteredMedicineList = storedMedicines.filter(
      (medicine) =>
        // medicine[1].toLowerCase().startsWith(query)
        medicine[1] && medicine[1].toLowerCase().startsWith(query.toLowerCase())
    );

    // Limit the results to top 10 and sort

    let limitedResults = filteredMedicineList.slice(0, 10);
    if (!query) {
      limitedResults = [];
      // console.log(limitedResults)
      setHighlightedIndexMedicine(0)
    }
    // console.log(limitedResults, "limitedResults");
    setFilteredMedicines(limitedResults);
  };

  const handleSearchRemarks = (event) => {
    // console.log(event.target.value);
    const query = event.target.value.toLowerCase();
    setSearchTerm(query);

    const storedRemarks = JSON.parse(localStorage.getItem("allRemarks")) || [];

    // Filter remarks based on search query
    const filteredMedicineList = storedRemarks.filter(
      (remark) => remark[0] && remark[0].toLowerCase().startsWith(query)
    );

    // Limit the results to top 10 and sort
    let limitedResults = filteredMedicineList.slice(0, 10);
    if (!query) {
      limitedResults = [];
      // console.log(limitedResults)
      setHighlightedIndexRemark(0)
    }

    setFilteredRemarks(limitedResults);
  };

  const handleSearchDescription = (event) => {
    // console.log(event.target.value);
    const query = event.target.value.toLowerCase();
    let updatedValue = query;

    if (["1", "2", "3"].includes(query)) {
      updatedValue =
        query === "1" ? "once" : query === "2" ? "twice" : "thrice";
    }

    setSearchTerm(updatedValue);

    const storedDescription =
      JSON.parse(localStorage.getItem("allDescription")) || [];

    // Filter remarks based on search query
    const filteredDescriptionList = storedDescription.filter(
      (description) =>
        description[0] && description[0].toLowerCase().startsWith(updatedValue)
    );

    // Limit the results to top 10 and sort
    let limitedResults = filteredDescriptionList.slice(0, 10);
    if (!query) {
      limitedResults = [];
      // console.log(limitedResults)
      setHighlightedIndexDescription(0)
    }

    setFilteredDescriptionList(limitedResults);
  };

  // console.log(commonData, 'commonData')

  // old one
  // const handleKeyDown = (e, index, field) => {
  //   // console.log(field , e.key , 'check')
  //   if (e.key === "Tab" && field === "name") {
  //     const filterEachMedicine = [...filteredMedicines];
  //     e.preventDefault();
  //     if (medicines[index]?.name === "") {
  //       setTimeout(() => document.getElementById(`follow-up`).focus(), 0);
  //       return;
  //     }
  //     console.log(filterEachMedicine, "filteredMedicines");
  //     const currentInput = medicines[index]?.name;
  //     // const topOption = medicineList?.payload?.medicine?.find((medicine) =>
  //     //   medicine[0].toUpperCase().startsWith(currentInput)
  //     // );
  //     // Find the top matching medicine from filteredMedicines
  //     const topOption = filterEachMedicine.find((medicine) =>
  //       (medicine[1] || "").toLowerCase().startsWith(currentInput)
  //     );
  //     console.log(
  //       currentInput,
  //       topOption,
  //       medicines[index]?.name,
  //       "otpionssssss"
  //     );
  //     if (topOption) {
  //       handleChange(index, "name", topOption[0]);
  //       setTimeout(
  //         () => document.getElementById(`description-${index}`).focus(),
  //         0
  //       );
  //     }
  //   } else if (e.key === "Tab" && field === "remark") {
  //     if (medicines[index]?.medicine === "") {
  //       setTimeout(() => document.getElementById(`follow-up`).focus(), 0);
  //     } else {
  //       handleAddRow();
  //       setTimeout(
  //         () => document.getElementById(`name-${index + 1}`).focus(),
  //         0
  //       );
  //     }
  //   }
  // };

  // const handleKeyDown = (e, index, field) => {
  //   // FOR TAB
  //   if (e.key === "Tab" && field === "name") {
  //     e.preventDefault(); // Prevent default Tab behavior

  //     // If the medicine name is empty, move to the follow-up field
  //     if (!medicines[index]?.name) {
  //       setTimeout(() => document.getElementById(`follow-up`).focus(), 0);
  //       return;
  //     }
  //     // Get the input value and ensure it's a string
  //     const currentInput = medicines[index]?.name?.toLowerCase() || ""; // Ensure it's a string

  //     // console.log("Filtered Medicines:", filteredMedicines);
  //     // console.log("Current Input:", currentInput);
  //     // Find the top matching medicine from filteredMedicines
  //     const topOption = filteredMedicines.find((medicine) =>
  //       (medicine[1] || "").toLowerCase().startsWith(currentInput)
  //     );

  //     // console.log("Top Option Found:", topOption);

  //     // If a matching option is found, update the field and focus on the next input
  //     if (topOption) {
  //       //  handleChange(index, "name", topOption[1]);
  //       updateNameAndId(index, topOption);
  //       handlePresence(index, topOption);
  //       // setFilteredMedicines([]); this thing we are doing in handle presence
  //       setTimeout(
  //         () => document.getElementById(`description-${index}`).focus(),
  //         0
  //       ); // Focus next field
  //     } else {
  //       setTimeout(
  //         () => document.getElementById(`description-${index}`).focus(),
  //         0
  //       ); // Focus next field
  //     }
  //   } else if (e.key === "Enter" && field === "name") {
  //     e.preventDefault(); // Prevent default Tab behavior

  //     // If the medicine name is empty, move to the follow-up field
  //     if (!medicines[index]?.name) {
  //       setTimeout(() => document.getElementById(`follow-up`).focus(), 0);
  //       return;
  //     }
  //     // Get the input value and ensure it's a string
  //     const currentInput = medicines[index]?.name?.toLowerCase() || ""; // Ensure it's a string

  //     // console.log("Filtered Medicines:", filteredMedicines);
  //     // console.log("Current Input:", currentInput);
  //     // Find the top matching medicine from filteredMedicines
  //     const topOption = filteredMedicines.find((medicine) =>
  //       (medicine[1] || "").toLowerCase().startsWith(currentInput)
  //     );
  //     // console.log("Top Option Found:", topOption);
  //     // If a matching option is found, update the field and focus on the next input
  //     if (topOption) {
  //       setTimeout(() => {
  //         updateNameAndId(index, topOption); // Ensure this is wrapped inside a function
  //         handlePresence(index, topOption); // Ensure this is wrapped inside a function
  //         // document.getElementById(`description-${index}`).focus(); // Focus next field
  //       }, 0);
  //     }
  //   }

  //   // for tab
  //   else if (e.key === "Tab" && field === "remark") {
  //     e.preventDefault();

  //     // If remark is empty, move to the follow-up field
  //     if (medicines[index]?.name === "") {
  //       setTimeout(() => document.getElementById(`follow-up`).focus(), 0);
  //       return;
  //     }

  //     const currentRemarkInput = medicines[index]?.remark?.toLowerCase() || "";
  //     const topRemarkOption = filteredRemarks.find((remark) =>
  //       (remark[0] || "").toLowerCase().startsWith(currentRemarkInput)
  //     );

  //     if (topRemarkOption) {
  //       // Set the top remark option
  //       handleChange(index, "remark", topRemarkOption[0]);
  //       setFilteredRemarks([]);
  //       // setTimeout(() => document.getElementById(`addMoreBtn`).click(), 0);

  //       // setTimeout(
  //       //   () => document.getElementById(`name-${index + 1}`).focus(),
  //       //   100
  //       // );

  //       setTimeout(() => document.getElementById(`addMoreBtn`).click(), 0);
  //       setTimeout(
  //         () => document.getElementById(`name-${index + 1}`).focus(),
  //         100
  //       );
  //     } else {
  //       if (medicines?.length > index + 1) {
  //         setTimeout(
  //           () => document.getElementById(`name-${index + 1}`).focus(),
  //           100
  //         );
  //       } else {
  //         setTimeout(() => document.getElementById(`addMoreBtn`).click(), 0);
  //         setTimeout(
  //           () => document.getElementById(`name-${index + 1}`).focus(),
  //           100
  //         );
  //       }
  //     }
  //   }

  //   // for enter
  //   else if (e.key === "Enter" && field === "remark") {
  //     e.preventDefault();

  //     // If remark is empty, move to the follow-up field
  //     if (medicines[index]?.name === "") {
  //       setTimeout(() => document.getElementById(`follow-up`).focus(), 0);
  //       return;
  //     }

  //     const currentRemarkInput = medicines[index]?.remark?.toLowerCase() || "";
  //     const topRemarkOption = filteredRemarks.find((remark) =>
  //       (remark[0] || "").toLowerCase().startsWith(currentRemarkInput)
  //     );
  //     if (topRemarkOption) {
  //       setTimeout(() => {
  //         handleChange(index, "remark", topRemarkOption[0]);
  //         setFilteredRemarks([]);
  //         // setTimeout(() => document.getElementById(`addMoreBtn`).click(), 0);
  //       }, 0);
  //     }
  //   }

  //   // Handling "description" field using tab
  //   else if (e.key === "Tab" && field === "description") {
  //     e.preventDefault();
  //     const filterEachDescription = [
  //       ...medicineDescriptionList?.payload?.medicine_descriptions,
  //     ]; // List of descriptions
  //     // console.log(filterEachDescription, "medicineDescriptionList");

  //     if (!medicines[index]?.name) {
  //       setTimeout(() => document.getElementById(`follow-up`).focus(), 0);
  //       return;
  //     }

  //     const currentDescriptionInput =
  //       medicines[index]?.description?.toLowerCase() || "";
  //     // console.log(currentDescriptionInput, "currentDescriptionInput");
  //     const topDescriptionOption = filterEachDescription.find((desc) =>
  //       (desc[0] || "").toLowerCase().startsWith(currentDescriptionInput)
  //     );
  //     // console.log(topDescriptionOption, "topDescriptionOption");
  //     if (topDescriptionOption) {
  //       handleChange(index, "description", topDescriptionOption[0]); // Update description text
  //       setFilteredDescriptionList([]);
  //       // handleChange(index, "descriptionId", topDescriptionOption[1]); // Update description ID
  //       // Move focus to next field, e.g., remarks
  //       setTimeout(() => document.getElementById(`remark-${index}`).focus(), 0);
  //     } else {
  //       setTimeout(() => document.getElementById(`remark-${index}`).focus(), 0);
  //     }
  //   }

  //   // for enter
  //   else if (e.key === "Enter" && field === "description") {
  //     e.preventDefault();
  //     const filterEachDescription = [
  //       ...medicineDescriptionList?.payload?.medicine_descriptions,
  //     ]; // List of descriptions
  //     // console.log(filterEachDescription, "medicineDescriptionList");

  //     if (!medicines[index]?.name) {
  //       setTimeout(() => document.getElementById(`follow-up`).focus(), 0);
  //       return;
  //     }

  //     const currentDescriptionInput =
  //       medicines[index]?.description?.toLowerCase() || "";
  //     // console.log(currentDescriptionInput, "currentDescriptionInput");
  //     const topDescriptionOption = filterEachDescription.find((desc) =>
  //       (desc[0] || "").toLowerCase().startsWith(currentDescriptionInput)
  //     );

  //     if (topDescriptionOption) {
  //       setTimeout(() => {
  //         handleChange(index, "description", topDescriptionOption[0]); // Update description text
  //         setFilteredDescriptionList([]);
  //       }, 0);
  //     }
  //   }
  // };

  const handleKeyDown = (e, index, field) => {
    if (field === "name") {
      if (["ArrowDown", "ArrowUp"].includes(e.key)) {
        e.preventDefault();
        // Navigate options with arrow keys
        setHighlightedIndexMedicine((prevIndex) => {
          if (e.key === "ArrowDown") {
            return prevIndex < filteredMedicines?.length - 1
              ? prevIndex + 1
              : 0;
          } else {
            return prevIndex > 0
              ? prevIndex - 1
              : filteredMedicines?.length - 1;
          }
        });
      } else if (e.key === "Tab") {
        if (!medicines[index]?.name) {
          setTimeout(() => document.getElementById(`follow-up`).focus(), 0);
          if (currentStep === 9) { setCurrentStep(10) }
          return;
        }

        e.preventDefault();
        const topOption = filteredMedicines[highlightedIndexMedicine];

        if (topOption) {
          updateNameAndId(index, topOption);
          handlePresence(index, topOption);
          setHighlightedIndexMedicine(0);
          if (currentStep === 6) { setCurrentStep(7) }
          setTimeout(
            () => document.getElementById(`description-${index}`).focus(),
            0
          );
        } else {
          setHighlightedIndexMedicine(0);
          setTimeout(
            () => document.getElementById(`description-${index}`).focus(),
            0
          ); // Focus next field
        }
      } else if (e.key === "Enter") {
        e.stopPropagation();
        if (!medicines[index]?.name) {
          setTimeout(() => document.getElementById(`follow-up`).focus(), 0);
          return;
        }

        e.preventDefault();
        const topOption = filteredMedicines[highlightedIndexMedicine];

        if (topOption) {
          setTimeout(() => {
            setHighlightedIndexMedicine(0);
            updateNameAndId(index, topOption); // Ensure this is wrapped inside a function
            handlePresence(index, topOption); // Ensure this is wrapped inside a function
            // document.getElementById(`description-${index}`).focus(); // Focus next field
          }, 0);
        }
      }
    }

    // Description field
    else if (field === "description") {
      if (["ArrowDown", "ArrowUp"].includes(e.key)) {
        e.preventDefault();
        setHighlightedIndexDescription((prevIndex) => {
          if (e.key === "ArrowDown") {
            return prevIndex < filteredDescriptionList.length - 1
              ? prevIndex + 1
              : 0;
          } else {
            return prevIndex > 0
              ? prevIndex - 1
              : filteredDescriptionList.length - 1;
          }
        });
      } else if (e.key === "Tab") {
        e.preventDefault();
        if (!medicines[index]?.name) {
          setTimeout(() => document.getElementById(`follow-up`).focus(), 0);
          if (currentStep === 9) { setCurrentStep(10) }
          return;
        }
        const topOption = filteredDescriptionList[highlightedIndexDescription];

        if (topOption) {
          handleChange(index, "description", topOption[0]);
          setHighlightedIndexDescription(0);
          setFilteredDescriptionList([]);

          setTimeout(
            () => {
              document.getElementById(`remark-${index}`).focus()
              if (currentStep === 7) { setCurrentStep(8) }
            }, 0
          );
        } else {
          setTimeout(
            () => document.getElementById(`remark-${index}`).focus(),
            0
          );
        }
      } else if (e.key === "Enter") {
        e.preventDefault();
        if (!medicines[index]?.name) {
          setTimeout(() => document.getElementById(`follow-up`).focus(), 0);
          return;
        }
        const topOption = filteredDescriptionList[highlightedIndexDescription];

        if (topOption) {
          if (topOption) {
            setTimeout(() => {
              setHighlightedIndexDescription(0);
              handleChange(index, "description", topOption[0]); // Update description text
              setFilteredDescriptionList([]);
            }, 0);
          }
        }
      }
    }

    // Remark field
    else if (field === "remark") {
      if (["ArrowDown", "ArrowUp"].includes(e.key)) {
        e.preventDefault();
        setHighlightedIndexRemark((prevIndex) => {
          if (e.key === "ArrowDown") {
            return prevIndex < filteredRemarks.length - 1 ? prevIndex + 1 : 0;
          } else {
            return prevIndex > 0 ? prevIndex - 1 : filteredRemarks.length - 1;
          }
        });
      } else if (e.key === "Tab") {
        e.preventDefault();

        if (medicines[index]?.name === "") {
          setTimeout(() => document.getElementById(`follow-up`).focus(), 0);
          if (currentStep === 9) { setCurrentStep(10) }
          return;
        }

        const topOption = filteredRemarks[highlightedIndexRemark];

        if (topOption) {
          setHighlightedIndexRemark(0);
          handleChange(index, "remark", topOption[0]);
          setFilteredRemarks([]);
          setTimeout(() => document.getElementById(`addMoreBtn`).click(), 0);
          if (currentStep === 8) { setCurrentStep(9) }
          setTimeout(
            () => document.getElementById(`name-${index + 1}`).focus(),
            100
          );
        } else {
          setHighlightedIndexRemark(0);
          if (medicines?.length > index + 1) {
            setTimeout(
              () => document.getElementById(`name-${index + 1}`).focus(),
              100
            );
          } else {
            setTimeout(() => document.getElementById(`addMoreBtn`).click(), 0);
            setTimeout(
              () => document.getElementById(`name-${index + 1}`).focus(),
              100
            );
          }
        }
      } else if (e.key === "Enter") {
        e.preventDefault();

        if (medicines[index]?.name === "") {
          setTimeout(() => document.getElementById(`follow-up`).focus(), 0);
          return;
        }

        const topOption = filteredRemarks[highlightedIndexRemark];

        if (topOption) {
          setTimeout(() => {
            setHighlightedIndexRemark(0);
            handleChange(index, "remark", topOption[0]);
            setFilteredRemarks([]);
            // setTimeout(() => document.getElementById(`addMoreBtn`).click(), 0);
          }, 0);
        }
      }
    }
  };

  // console.log(medicines, "medicines");
  const handlePresence = async (index, value) => {
    const medicineNames = medicines
      ?.slice(0, medicines?.length > 1 ? medicines?.length - 1 : 0)
      .map((med) => med.name)
      .join(",");
    // encodeURIComponent(medicineNames)
    try {
      const res = await getMedicineByName(
        encodeURIComponent(medicineNames),
        encodeURIComponent(value[1])
      );

      //  console.log(res.payload?.presence);
      if (res?.payload?.presence) {
        const updatedMedicines = medicines?.map((medicine, i) =>
          i === index
            ? { ...medicine, id: "", name: "", description: "", remark: "" }
            : medicine
        );

        // Update the state with the cleared fields
        setMedicines(updatedMedicines);

        // console.log(updatedMedicines, "Updated medicines list");
        showToast("Medicine already present in the list", "ERROR");
      }
    } catch (error) {
      console.log(error);
    }
    // console.log(value, " call api to check ");
    setFilteredMedicines([]);
  };

  // console.log(commonData , 'commonData');
  // console.log(prescriptionData , 'prescriptionData');
  // console.log(appointmentData , 'appointmentData');

  // handle print prescription 
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Prescription Print",
    // onAfterPrint: () => console.log("Print job finished"),
  });

  useEffect(() => {
    if (recomendations?.recomendations) {
      const initialCheckBoxes = Object.keys(
        recomendations?.recomendations
      ).reduce((acc, key) => {
        acc[key] = prescriptionData?.[key] || null;
        return acc;
      }, {});
      setCheckBoxes(initialCheckBoxes);
    }
  }, [recomendations, prescriptionData]);


  const [appointmentsDate, setAppointmentsDate] = useState([]);
  useEffect(() => {
    const dates = patientDetailsData?.appointments?.filter(appointment =>
      ["completed", "paid"].includes(appointment.status) ||
      appointment?.prescription_id).map((appointment) => ({
        date: new Date(appointment.visit_date).toDateString(),
        id: appointment?.id,
        prescription_id: appointment?.prescription_id,
        appointmentStatus: appointment?.status,
      }));
    setAppointmentsDate(dates);
  }, [patientDetailsData])

  // console.log(checkBoxes);

  //handle check box
  // const handleCheckBox = (field) => {
  //   const currentValue = prescriptionData[field];
  //   const newValue = currentValue === null ? true : null;

  //   const updatedPrescription = {
  //     ...prescriptionData,
  //     [field]: newValue,
  //   };
  //   dispatch(setPrescriptionData(updatedPrescription));
  // };

  const handleCheckBox = (key) => {
    setCheckBoxes((prevState) => ({
      ...prevState,
      [key]: prevState[key] === null ? true : null,
    }));
  };
  // console.log(checkBoxes);

  function cleanPayload(obj) {
    if (Array.isArray(obj)) {
      return obj.map(cleanPayload).filter((item) => item != null);
    } else if (typeof obj === "object" && obj !== null) {
      return Object.fromEntries(
        Object.entries(obj)
          .map(([key, value]) => [key, cleanPayload(value)])
          .filter(([_, value]) => value != null && value !== "")
      );
    }
    return obj;
  }

  const validateForm = (formData) => {
    let newErrors = {};
    let isValid = true;

    PrescriptionForm.forEach((field) => {
      if (field.pattern) {
        const value = formData[field.name];
        if (!new RegExp(field.pattern).test(value)) {
          newErrors[field.name] = field.errorMessage || "Invalid input";
          isValid = false;
        }
      }
      // Add any other validation rules here
      if (field.required && !formData[field.name]) {
        // newErrors[field.name] = `${[field.name]}  is required`;
        newErrors[field.name] = field.errorMessage || "Invalid input";
        isValid = false;
      }
    });

    setErrors(newErrors);
    if (!isValid) {
      showToast("Validation failed ", "ERROR");
    }
    return isValid;
  };

  // console.log(commonData?.investigations ,'investigations')
  // handle save & print
  const updatePrescriptionAndPrint = async () => {
    setButtonLoading(true);
    if (!validateForm(commonData.patientForm)) {
      setButtonLoading(false);
      return null;
    }
    setmobileNumber(commonData?.patientForm?.mobile);
    if (!patientDetailsData?.id && !appointmentData?.id) {
      showToast(
        "Patient is not registered or has not taken an appointment. Click on 'Create New Visit' to create a prescription.",
        "ERROR"
      );
      setButtonLoading(false);
      return;
    }

    // filter empty row here
    const filteredMedicines = medicines.filter(
      (medicine) => medicine.name.trim() !== ""
    );
    // const payload = {
    //   data: [
    //     { name: "patient[uid]", value: commonData?.patientForm?.id },
    //     { name: "patient[full_name]", value: commonData?.patientForm?.fullName },
    //     { name: "patient[email]", value: commonData?.patientForm?.email },
    //     { name: "patient[mobile]", value: commonData?.patientForm?.mobile },
    //     { name: "patient[marital_status]", value: commonData?.patientForm?.maritalStatus },
    //     { name: "patient[diet]", value: commonData?.patientForm?.diet?.split("-")?.join("_")?.toLowerCase() },
    //     { name: "patient[gender]", value: commonData?.patientForm?.gender?.toLowerCase() },
    //     { name: "patient[occupation_id]", value: commonData?.patientForm?.occupation?.id || commonData?.patientForm?.occupation || null },
    //     { name: "patient[age]", value: commonData?.patientForm?.age },
    //     { name: "patient[address]", value: commonData?.patientForm?.address },
    //     { name: "patient[education_id]", value: commonData?.patientForm?.education?.id || commonData?.patientForm?.education || null },
    //     { name: "patient[city]", value: commonData?.patientForm?.city?.id || commonData?.patientForm?.city || null },
    //     { name: "patient[pincode]", value: commonData?.patientForm?.pincode || null },
    //     { name: "appointment[visit_type]", value: commonData?.patientForm?.visitType },
    //     { name: "appointment[doctor_id]", value: commonData?.patientForm?.attendingDoctor },
    //     { name: "appointment[consultant_id]", value: commonData?.patientForm?.consultancyDoctor },
    //     { name: "appointment[comments]", value: commonData?.patientForm?.comments },
    //     { name: "appointment[referrer_id]", value: commonData?.patientForm?.referrer_id },
    //     { name: "appointment[height]", value: commonData?.patientForm?.height },
    //     { name: "appointment[weight]", value: commonData?.patientForm?.weight },
    //     { name: "appointment[fever]", value: commonData?.patientForm?.fever || null },
    //     { name: "appointment[bp_s]", value: commonData?.patientForm?.bpSystolic || null },
    //     { name: "appointment[bp_d]", value: commonData?.patientForm?.bpDiastolic || null },
    //     { name: "appointment[bmi]", value: commonData?.patientForm?.bmi || null },
    //     { name: "prescription[diagnosis][]", value: commonData?.treatmentDetail?.diagnosis || "" },
    //     { name: "prescription[notes]", value: commonData?.treatmentDetail?.notes || null },
    //     { name: "prescription[self_notes]", value: commonData?.treatmentDetail?.selfNotes || null },
    //     { name: "prescription[special_instructions]", value: commonData?.treatmentDetail?.splInst || null },
    //     { name: "prescription[followup][]", value: followUp || null },
    //     { name: "prescription[medical_tests][]", value: medicalTestList || null },
    //     { name: "prescription[additional_tests][]", value: additionalTests || null },
    //     { name: "prescription[daily_exercise]", value: checkBoxes?.daily_exercise ? "daily_exercise" : "" },
    //     { name: "prescription[physiotherapist]", value: checkBoxes?.physiotherapist ? "physiotherapist" : "" },
    //     { name: "prescription[psychologist]", value: checkBoxes?.psychologist ? "psychologist" : "" },
    //     { name: "prescription[dietitian]", value: checkBoxes?.dietitian ? "dietitian" : "" },
    //     ...(commonData?.investigations?.map((investigation) => [
    //       { name: `investigation[before_${investigation.name}]`, value: investigation?.before_value },
    //       { name: `investigation[after_${investigation.name}]`, value: investigation?.after_value },
    //     ]) || []).flat(),
    //     ...(filteredMedicines?.map((medicine) => [
    //       { name: "treatment_medicine[medicine_id]", value: medicine?.id || "" },
    //       { name: "treatment_medicine[name]", value: medicine?.name },
    //       { name: "treatment_medicine[description]", value: medicine?.description },
    //       { name: "treatment_medicine[remark]", value: medicine?.remark },
    //     ]) || []).flat(),
    //   ].filter(item => item?.value && item?.value !== "" && item?.value !== null),
    //   appointment_id: appointmentData?.id,
    //   prescription_id: prescriptionData?.id,
    //   prescription_print: true,

    // };

    const payload = {
      data: {
        0: { name: "patient[uid]", value: commonData?.patientForm?.id },
        1: {
          name: "patient[full_name]",
          value: commonData?.patientForm?.fullName,
        },
        2: { name: "patient[email]", value: commonData?.patientForm?.email },
        3: { name: "patient[mobile]", value: commonData?.patientForm?.mobile },
        4: {
          name: "patient[marital_status]",
          value: commonData?.patientForm?.maritalStatus,
        },
        5: {
          name: "patient[diet]",
          value: commonData?.patientForm?.diet
            ?.split("-")
            ?.join("_")
            ?.toLowerCase(),
        },
        6: {
          name: "patient[gender]",
          value: commonData?.patientForm?.gender?.toLowerCase(),
        },
        7: {
          name: "patient[occupation_id]",
          value:
            commonData?.patientForm?.occupation?.id ||
            commonData?.patientForm?.occupation ||
            null,

          //  String(
          //   commonData?.patientForm?.occupation?.id ||
          //     commonData?.patientForm?.occupation
          // ),
        },
        8: { name: "patient[age]", value: commonData?.patientForm?.age },
        9: {
          name: "patient[address]",
          value: commonData?.patientForm?.address,
        },
        10: {
          name: "patient[education_id]",
          value:
            commonData?.patientForm?.education?.id ||
            commonData?.patientForm?.education ||
            null,
        },
        11: {
          name: "patient[city]",
          value:
            commonData?.patientForm?.city?.id ||
            commonData?.patientForm?.city ||
            null,
          //  String(
          //   commonData?.patientForm?.city?.id || commonData?.patientForm?.city
          // ),
        },
        12: {
          name: "patient[pincode]",
          value: commonData?.patientForm?.pincode || null,
        },
        13: {
          name: "appointment[visit_type]",
          value: commonData?.patientForm?.visitType,
        },
        14: {
          name: "appointment[doctor_id]",
          value: commonData?.patientForm?.attendingDoctor,
        },
        15: {
          name: "appointment[consultant_id]",
          value: commonData?.patientForm?.consultancyDoctor,
        },
        16: {
          name: "appointment[comments]",
          value: commonData?.patientForm?.comments,
        },
        17: {
          name: "appointment[referrer_id]",
          value: commonData?.patientForm?.referrer,
        },
        18: {
          name: "appointment[height]",
          value: commonData?.patientForm?.height,
        },
        19: {
          name: "appointment[weight]",
          value: commonData?.patientForm?.weight,
        },
        20: {
          name: "appointment[fever]",
          value: commonData?.patientForm?.fever || null,
        },
        21: {
          name: "appointment[bp_s]",
          value: commonData?.patientForm?.bpSystolic || null,
        },
        22: {
          name: "appointment[bp_d]",
          value: commonData?.patientForm?.bpDiastolic || null,
        },
        23: {
          name: "appointment[bmi]",
          value: commonData?.patientForm?.bmi || null,
        },

        24: {
          name: "prescription[diagnosis][]",
          value: commonData?.treatmentDetail?.diagnosis || "", // getting error from backend if we passing null
        },
        25: {
          name: "prescription[notes]",
          value: commonData?.treatmentDetail?.notes || null,
        },
        26: {
          name: "prescription[self_notes]",
          value: commonData?.treatmentDetail?.selfNotes || null,
        },
        27: {
          name: "prescription[special_instructions]",
          value: commonData?.treatmentDetail?.splInst || null,
        },

        28: { name: "prescription[followup][]", value: followUp || null },
        29: {
          name: "prescription[medical_tests][]",
          value: medicalTestList || null,
        },
        30: {
          name: "prescription[additional_tests][]",
          value: additionalTests || null,
        },

        31: {
          name: "prescription[daily_exercise]",
          value: checkBoxes?.daily_exercise ? "daily_exercise" : "",
        },
        32: {
          name: "prescription[physiotherapist]",
          value: checkBoxes?.physiotherapist ? "physiotherapist" : "",
        },
        33: {
          name: "prescription[psychologist]",
          value: checkBoxes?.psychologist ? "psychologist" : "",
        },
        34: {
          name: "prescription[dietitian]",
          value: checkBoxes?.dietitian ? "dietitian" : "",
        },

        // Investigations 

        ...(commonData?.investigations?.length > 0
          ? commonData.investigations.reduce((acc, investigation, index) => {
            const baseIndex = 34 + index;
            return {
              ...acc,
              [baseIndex + 1]: {
                name: `investigation[medical_test_id]`,
                value: investigation?.id,
              },
            };
          }, {})
          : {}),

        // Medicines
        ...filteredMedicines?.reduce((acc, medicine, index) => {
          const investigationLength = commonData?.investigations?.length ?? 0; // Default to 0 if investigations is undefined
          const baseIndex =
            35 +
            (investigationLength === 0 ? -1 : investigationLength) +
            index * 4;

          return {
            ...acc,
            [baseIndex]: {
              name: "treatment_medicine[name]",
              value: medicine?.name,
            },
            [baseIndex + 1]: {
              name: "treatment_medicine[medicine_id]",
              value: medicine?.id || "",
            },
            [baseIndex + 2]: {
              name: "treatment_medicine[description]",
              value: medicine?.description,
            },
            [baseIndex + 3]: {
              name: "treatment_medicine[remark]",
              value: medicine?.remark,
            },
          };
        }, {}),
      },
      appointment_id: appointmentData?.id,
      // prescription_id: prescriptionData?.id,
      prescription_print: true,
    };

    try {
      const res = await createAndUpdatePrescription(payload);
      // console.log(res, "update prescription");
      const statusMessage = res?.status?.status || "ERROR";
      const detailedMessage = res?.status?.message || "Something went wrong";
      if (statusMessage === "SUCCESS") {
        showToast("Generating prescription", "SUCCESS");
      } else {
        showToast(detailedMessage, "ERROR");
      }
      // showToast(statusMessage, detailedMessage);
      // showToast(
      //   res?.status?.message || res?.status?.status,
      //   res?.status?.status
      // );
      if (res?.status?.status === "SUCCESS") {
        setPrescriptionSuccessRes(res?.payload);
        // setTimeout(() => {
        //   dispatch(setPrescriptionData([]));
        //   dispatch(setPatientDetailsData({}));
        //   dispatch(setAppointmentData({}));
        //   setCommonData({
        //     patientForm: {},
        //     treatmentDetail: {},
        //     investigations: [],
        //     medicines: [],
        //   });
        //   setMedicines([]);
        //   setMedicalTestList("");
        //   setInputValue("");
        // }, 100);
      }

      if (res?.status?.status === "SUCCESS") {
        setTimeout(() => {
          // setMedicines([]);
          handlePrint();
          showToast(`Generating prescription PDF`, "SUCCESS");
        }, 300);
      }
      setButtonLoading(false);
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
    }
    dispatch(setRenderPrescriptionTable());
  };

  // handle  save & update
  const updatePrescription = async () => {
    setButtonLoading(true);
    if (!validateForm(commonData.patientForm)) {
      setButtonLoading(false);
      return null;
    }
    setmobileNumber(commonData?.patientForm?.mobile);

    // if (!prescriptionData?.id) {
    //   showToast(
    //     `Can't print or save patient without prescription ID. You can click on 'Create new Visit' to create a new Prescription and Appointment.`,
    //     "ERROR"
    //   );
    //   return null;
    // }
    if (!patientDetailsData?.id && !appointmentData?.id) {
      showToast(
        "Patient is not registered or has not taken an appointment. Click on 'Create New Visit' to create a prescription.",
        "ERROR"
      );
      setButtonLoading(false);
      return;
    }

    // if(   !patientDetailsData?.appointments[0]?.id){
    //   showToast(
    //     "Prescription is not generated yet , Please first  click on save and Print button to generate prescription",
    //     "ERROR"
    //   );
    //   setButtonLoading(false);
    //   return;
    // }

    const filteredMedicines = medicines.filter(
      (medicine) => medicine.name.trim() !== ""
    );

    const payload = {
      data: {
        0: { name: "patient[uid]", value: commonData?.patientForm?.id },
        1: {
          name: "patient[full_name]",
          value: commonData?.patientForm?.fullName,
        },
        2: { name: "patient[email]", value: commonData?.patientForm?.email },
        3: { name: "patient[mobile]", value: commonData?.patientForm?.mobile },
        4: {
          name: "patient[marital_status]",
          value: commonData?.patientForm?.maritalStatus,
        },
        5: {
          name: "patient[diet]",
          value: commonData?.patientForm?.diet
            ?.split("-")
            ?.join("_")
            ?.toLowerCase(),
        },
        6: {
          name: "patient[gender]",
          value: commonData?.patientForm?.gender?.toLowerCase(),
        },
        7: {
          name: "patient[occupation_id]",
          value:
            commonData?.patientForm?.occupation?.id ||
            commonData?.patientForm?.occupation ||
            null,

          //  String(
          //   commonData?.patientForm?.occupation?.id ||
          //     commonData?.patientForm?.occupation
          // ),
        },
        8: { name: "patient[age]", value: commonData?.patientForm?.age },
        9: {
          name: "patient[address]",
          value: commonData?.patientForm?.address,
        },
        10: {
          name: "patient[education_id]",
          value:
            commonData?.patientForm?.education?.id ||
            commonData?.patientForm?.education ||
            null,
        },
        11: {
          name: "patient[city]",
          value:
            commonData?.patientForm?.city?.id ||
            commonData?.patientForm?.city ||
            null,
          //  String(
          //   commonData?.patientForm?.city?.id || commonData?.patientForm?.city
          // ),
        },
        12: {
          name: "patient[pincode]",
          value: commonData?.patientForm?.pincode || null,
        },
        13: {
          name: "appointment[visit_type]",
          value: commonData?.patientForm?.visitType,
        },
        14: {
          name: "appointment[doctor_id]",
          value: commonData?.patientForm?.attendingDoctor,
        },
        15: {
          name: "appointment[consultant_id]",
          value: commonData?.patientForm?.consultancyDoctor,
        },
        16: {
          name: "appointment[comments]",
          value: commonData?.patientForm?.comments,
        },
        17: {
          name: "appointment[referrer_id]",
          value: commonData?.patientForm?.referrer,
        },
        18: {
          name: "appointment[height]",
          value: commonData?.patientForm?.height,
        },
        19: {
          name: "appointment[weight]",
          value: commonData?.patientForm?.weight,
        },
        20: {
          name: "appointment[fever]",
          value: commonData?.patientForm?.fever || null,
        },
        21: {
          name: "appointment[bp_s]",
          value: commonData?.patientForm?.bpSystolic || null,
        },
        22: {
          name: "appointment[bp_d]",
          value: commonData?.patientForm?.bpDiastolic || null,
        },
        23: {
          name: "appointment[bmi]",
          value: commonData?.patientForm?.bmi || null,
        },

        24: {
          name: "prescription[diagnosis][]",
          value: commonData?.treatmentDetail?.diagnosis || "", // getting error from backend if we passing null
        },
        25: {
          name: "prescription[notes]",
          value: commonData?.treatmentDetail?.notes || null,
        },
        26: {
          name: "prescription[self_notes]",
          value: commonData?.treatmentDetail?.selfNotes || null,
        },
        27: {
          name: "prescription[special_instructions]",
          value: commonData?.treatmentDetail?.splInst || null,
        },

        28: { name: "prescription[followup][]", value: followUp || null },
        29: {
          name: "prescription[medical_tests][]",
          value: medicalTestList || null,
        },
        30: {
          name: "prescription[additional_tests][]",
          value: additionalTests || null,
        },

        31: {
          name: "prescription[daily_exercise]",
          value: checkBoxes?.daily_exercise ? "daily_exercise" : "",
        },
        32: {
          name: "prescription[physiotherapist]",
          value: checkBoxes?.physiotherapist ? "physiotherapist" : "",
        },
        33: {
          name: "prescription[psychologist]",
          value: checkBoxes?.psychologist ? "psychologist" : "",
        },
        34: {
          name: "prescription[dietitian]",
          value: checkBoxes?.dietitian ? "dietitian" : "",
        },

        // Investigations

        ...(commonData?.investigations?.length > 0
          ? commonData.investigations.reduce((acc, investigation, index) => {
            const baseIndex = 34 + index;
            return {
              ...acc,
              [baseIndex + 1]: {
                name: `investigation[medical_test_id]`,
                value: investigation?.id,
              },
            };
          }, {})
          : {}),


        // Medicines
        ...filteredMedicines?.reduce((acc, medicine, index) => {
          const investigationLength = commonData?.investigations?.length ?? 0; // Default to 0 if investigations is undefined
          const baseIndex =
            35 +
            (investigationLength === 0 ? -1 : investigationLength) +
            index * 4;

          return {
            ...acc,
            [baseIndex]: {
              name: "treatment_medicine[name]",
              value: medicine?.name,
            },
            [baseIndex + 1]: {
              name: "treatment_medicine[medicine_id]",
              value: medicine?.id || "",
            },
            [baseIndex + 2]: {
              name: "treatment_medicine[description]",
              value: medicine?.description,
            },
            [baseIndex + 3]: {
              name: "treatment_medicine[remark]",
              value: medicine?.remark,
            },
          };
        }, {}),
      },
      appointment_id: appointmentData?.id,
      ...(prescriptionData?.id ? { prescription_id: prescriptionData?.id } : {}),
    };

    try {
      const res = await createAndUpdatePrescription(payload);
      // console.log(res, "update prescription");
      const statusMessage = res?.status?.status || "ERROR";
      const detailedMessage = res?.status?.message || "Something went wrong";
      showToast(statusMessage, detailedMessage);
      showToast(
        res?.status?.message || res?.status?.status,
        res?.status?.status
      );
      if (res?.status?.status === "SUCCESS") {
        setTimeout(() => {
          dispatch(setPrescriptionData(res?.payload));
        }, 100);
      }
      setButtonLoading(false);
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
    }
    setButtonLoading(false);
    dispatch(setRenderPrescriptionTable());
  };

  //  console.log(patientDetailsData)
  //  console.log(appointmentData)

  useEffect(() => {
    if (currentStep === 5 && medicines?.length === 0) {
      handleAddRow();
    }
    else if (currentStep === 6 && medicines.length === 0) {
      handleAddRow();
      setTimeout(() => document.getElementById('name-0').focus(), 100)
    } else if (currentStep === 6 && medicines.length > 0) {
      setTimeout(() => document.getElementById('name-0').focus(), 100)
    }

    if (currentStep === 8 && medicines.length <= 1) {
      handleAddRow();
    }
    else if (currentStep === 9 && medicines.length <= 1) {
      handleAddRow();
      setTimeout(() => document.getElementById('name-1').focus(), 100)
    } else if (currentStep === 9 && medicines.length > 1) {
      setTimeout(() => document.getElementById('name-1').focus(), 100)
    }
  }, [currentStep])


  //create new visit
  // console.log(patientDetailsData, "patientDetailsData");
  const CreatePrescription = async () => {
    setButtonLoading(true);
    dispatch(setRenderPrescriptionTable());
    if (!validateForm(commonData?.patientForm)) {
      setButtonLoading(false);
      return null;
    }

    //patient id not present that's mean  patient  not exist so should create patient   , appointment and , prescription all are not present then create all of them
    if (!patientDetailsData?.id) {
      let createPatientData = {
        patient: {
          full_name: commonData?.patientForm?.fullName,
          email: commonData?.patientForm?.email,
          mobile: commonData?.patientForm?.mobile,
          marital_status: commonData?.patientForm?.maritalStatus || null,
          diet:
            commonData?.patientForm?.diet
              ?.split("-")
              ?.join("_")
              ?.toLowerCase() || null,
          gender: commonData?.patientForm?.gender?.toLowerCase() || null,
          occupation_id:
            commonData?.patientForm?.occupation?.id ||
            commonData?.patientForm?.occupation ||
            null,
          age: commonData?.patientForm?.age || null,
          address: commonData?.patientForm?.address || null,
          education_id:
            commonData?.patientForm?.education?.id ||
            commonData?.patientForm?.education ||
            null,
          city:
            commonData?.patientForm?.city?.id ||
            commonData?.patientForm?.city ||
            null,
        },
      };

      createPatientData = cleanPayload(createPatientData);
      let createdPatient = await createPatient(createPatientData);
      console.log(createdPatient?.status?.status, "status patient");
      if (createdPatient?.status?.status === "SUCCESS") {
        dispatch(setPatientDetailsData(createdPatient?.payload));
        console.log(createdPatient?.payload, "createdPatient");
        showToast(
          createdPatient?.status?.message || createdPatient?.status?.status,
          createdPatient?.status?.status
        );

        // create appointment after patients
        let createAppointment = {
          appointment: {
            visit_type: commonData?.patientForm?.visitType,
            visit_date: new Date().toISOString().split("T")[0],
            consultant_id: commonData?.patientForm?.consultancyDoctor,
            doctor_id: commonData?.patientForm?.attendingDoctor,
            patient_id: createdPatient?.payload?.id,
            height: commonData?.patientForm?.height,
            weight: commonData?.patientForm?.weight,
            fever: commonData?.patientForm?.fever,
            bp_s: commonData?.patientForm?.bpSystolic,
            bp_d: commonData?.patientForm?.bpDiastolic,
            bmi: commonData?.patientForm?.bmi,
            referrer_id: commonData?.patientForm?.referrer,
          },
        };

        createAppointment = cleanPayload(createAppointment);
        let result = await savePatientAppointment({ ...createAppointment });
        // console.log(result);
        if (result?.status?.status === "ERROR") {
          showToast(
            result?.status?.message || result?.status?.status,
            result?.status?.status
          );
        } else {
          setTimeout(() => {
            dispatch(setAppointmentData(result?.payload));

            // filter empty row here
            const filteredMedicinesPayload = medicines.filter(
              (medicine) => medicine.name.trim() !== ""
            );
            // console.log(filteredMedicines)

            const newPayload = {
              data: {
                0: { name: "patient[uid]", value: commonData?.patientForm?.id },
                1: {
                  name: "patient[full_name]",
                  value: commonData?.patientForm?.fullName,
                },
                2: {
                  name: "patient[email]",
                  value: commonData?.patientForm?.email,
                },
                3: {
                  name: "patient[mobile]",
                  value: commonData?.patientForm?.mobile,
                },
                4: {
                  name: "patient[marital_status]",
                  value: commonData?.patientForm?.maritalStatus,
                },
                5: {
                  name: "patient[diet]",
                  value: commonData?.patientForm?.diet
                    ?.split("-")
                    ?.join("_")
                    ?.toLowerCase(),
                },
                6: {
                  name: "patient[gender]",
                  value: commonData?.patientForm?.gender?.toLowerCase(),
                },
                7: {
                  name: "patient[occupation_id]",
                  value:
                    commonData?.patientForm?.occupation?.id ||
                    commonData?.patientForm?.occupation ||
                    null,

                  //  String(
                  //   commonData?.patientForm?.occupation?.id ||
                  //     commonData?.patientForm?.occupation
                  // ),
                },
                8: {
                  name: "patient[age]",
                  value: commonData?.patientForm?.age,
                },
                9: {
                  name: "patient[address]",
                  value: commonData?.patientForm?.address,
                },
                10: {
                  name: "patient[education_id]",
                  value:
                    commonData?.patientForm?.education?.id ||
                    commonData?.patientForm?.education ||
                    null,
                },
                11: {
                  name: "patient[city]",
                  value:
                    commonData?.patientForm?.city?.id ||
                    commonData?.patientForm?.city ||
                    null,
                  //  String(
                  //   commonData?.patientForm?.city?.id || commonData?.patientForm?.city
                  // ),
                },
                12: {
                  name: "patient[pincode]",
                  value: commonData?.patientForm?.pincode || null,
                },
                13: {
                  name: "appointment[visit_type]",
                  value: commonData?.patientForm?.visitType,
                },
                14: {
                  name: "appointment[doctor_id]",
                  value: commonData?.patientForm?.attendingDoctor,
                },
                15: {
                  name: "appointment[consultant_id]",
                  value: commonData?.patientForm?.consultancyDoctor,
                },
                16: {
                  name: "appointment[comments]",
                  value: commonData?.patientForm?.comments,
                },
                17: {
                  name: "appointment[referrer_id]",
                  value: commonData?.patientForm?.referrer,
                },
                18: {
                  name: "appointment[height]",
                  value: commonData?.patientForm?.height,
                },
                19: {
                  name: "appointment[weight]",
                  value: commonData?.patientForm?.weight,
                },
                20: {
                  name: "appointment[fever]",
                  value: commonData?.patientForm?.fever || null,
                },
                21: {
                  name: "appointment[bp_s]",
                  value: commonData?.patientForm?.bpSystolic || null,
                },
                22: {
                  name: "appointment[bp_d]",
                  value: commonData?.patientForm?.bpDiastolic || null,
                },
                23: {
                  name: "appointment[bmi]",
                  value: commonData?.patientForm?.bmi || null,
                },

                24: {
                  name: "prescription[diagnosis][]",
                  value: commonData?.treatmentDetail?.diagnosis || "", // getting error from backend if we passing null
                },
                25: {
                  name: "prescription[notes]",
                  value: commonData?.treatmentDetail?.notes || null,
                },
                26: {
                  name: "prescription[self_notes]",
                  value: commonData?.treatmentDetail?.selfNotes || null,
                },
                27: {
                  name: "prescription[special_instructions]",
                  value: commonData?.treatmentDetail?.splInst || null,
                },

                28: {
                  name: "prescription[followup][]",
                  value: followUp || null,
                },
                29: {
                  name: "prescription[medical_tests][]",
                  value: medicalTestList || null,
                },
                30: {
                  name: "prescription[additional_tests][]",
                  value: additionalTests || null,
                },

                31: {
                  name: "prescription[daily_exercise]",
                  value: checkBoxes?.daily_exercise ? "daily_exercise" : "",
                },
                32: {
                  name: "prescription[physiotherapist]",
                  value: checkBoxes?.physiotherapist ? "physiotherapist" : "",
                },
                33: {
                  name: "prescription[psychologist]",
                  value: checkBoxes?.psychologist ? "psychologist" : "",
                },
                34: {
                  name: "prescription[dietitian]",
                  value: checkBoxes?.dietitian ? "dietitian" : "",
                },

                // Investigations

                ...(commonData?.investigations?.length > 0
                  ? commonData.investigations.reduce((acc, investigation, index) => {
                    const baseIndex = 34 + index;
                    return {
                      ...acc,
                      [baseIndex + 1]: {
                        name: `investigation[medical_test_id]`,
                        value: investigation?.id,
                      },
                    };
                  }, {})
                  : {}),


                // Medicines
                ...filteredMedicinesPayload?.reduce((acc, medicine, index) => {
                  const investigationLength =
                    commonData?.investigations?.length ?? 0; // Default to 0 if investigations is undefined
                  const baseIndex =
                    35 +
                    (investigationLength === 0 ? -1 : investigationLength) +
                    index * 4;

                  return {
                    ...acc,
                    [baseIndex]: {
                      name: "treatment_medicine[name]",
                      value: medicine?.name,
                    },
                    [baseIndex + 1]: {
                      name: "treatment_medicine[medicine_id]",
                      value: medicine?.id || "",
                    },
                    [baseIndex + 2]: {
                      name: "treatment_medicine[description]",
                      value: medicine?.description,
                    },
                    [baseIndex + 3]: {
                      name: "treatment_medicine[remark]",
                      value: medicine?.remark,
                    },
                  };
                }, {}),
              },
              appointment_id: result?.payload?.id,
            };

            setTimeout(async () => {
              const res = await createAndUpdatePrescription(newPayload);
              // console.log(res, "update prescription");
              if (res?.res?.res === "SUCCESS") {
                dispatch(setPrescriptionData(res?.payload));
              }
              const statusMessage = res?.status?.status || "ERROR";
              const detailedMessage =
                res?.status?.message || "Something went wrong";
              showToast(statusMessage, detailedMessage);
              showToast(
                res?.status?.message || res?.status?.status,
                res?.status?.status
              );
            }, 600);
          });
        }
      }
    }

    // if patient id present  , appointment and , prescription are not then create appointment , prescription
    else if (
      patientDetailsData?.id &&
      !appointmentData?.id &&
      !prescriptionData?.id
    ) {
      // if patient exist but  , appointment prescription  are missing create appointment and prescription

      let createAppointment = {
        appointment: {
          visit_type: commonData?.patientForm?.visitType,
          visit_date: new Date().toISOString().split("T")[0],
          consultant_id: commonData?.patientForm?.consultancyDoctor,
          doctor_id: commonData?.patientForm?.attendingDoctor,
          patient_id: patientDetailsData?.id,
          height: commonData?.patientForm?.height,
          weight: commonData?.patientForm?.weight,
          fever: commonData?.patientForm?.fever,
          bp_s: commonData?.patientForm?.bpSystolic,
          bp_d: commonData?.patientForm?.bpDiastolic,
          bmi: commonData?.patientForm?.bmi,
          referrer_id: commonData?.patientForm?.referrer,
        },
      };

      createAppointment = cleanPayload(createAppointment);
      let result = await savePatientAppointment(createAppointment);
      // console.log(result);
      if (result?.status?.status === "ERROR") {
        showToast(
          result?.status?.message || result?.status?.status,
          result?.status?.status
        );
      } else {
        dispatch(setAppointmentData(result?.payload));

        const filteredMedicines = medicines.filter(
          (medicine) => medicine?.name?.trim() !== ""
        );

        const payload = {
          data: {
            0: { name: "patient[uid]", value: commonData?.patientForm?.id },
            1: {
              name: "patient[full_name]",
              value: commonData?.patientForm?.fullName,
            },
            2: {
              name: "patient[email]",
              value: commonData?.patientForm?.email,
            },
            3: {
              name: "patient[mobile]",
              value: commonData?.patientForm?.mobile,
            },
            4: {
              name: "patient[marital_status]",
              value: commonData?.patientForm?.maritalStatus,
            },
            5: {
              name: "patient[diet]",
              value: commonData?.patientForm?.diet
                ?.split("-")
                ?.join("_")
                ?.toLowerCase(),
            },
            6: {
              name: "patient[gender]",
              value: commonData?.patientForm?.gender?.toLowerCase(),
            },
            7: {
              name: "patient[occupation_id]",
              value:
                commonData?.patientForm?.occupation?.id ||
                commonData?.patientForm?.occupation ||
                null,

              //  String(
              //   commonData?.patientForm?.occupation?.id ||
              //     commonData?.patientForm?.occupation
              // ),
            },
            8: { name: "patient[age]", value: commonData?.patientForm?.age },
            9: {
              name: "patient[address]",
              value: commonData?.patientForm?.address,
            },
            10: {
              name: "patient[education_id]",
              value:
                commonData?.patientForm?.education?.id ||
                commonData?.patientForm?.education ||
                null,
            },
            11: {
              name: "patient[city]",
              value:
                commonData?.patientForm?.city?.id ||
                commonData?.patientForm?.city ||
                null,
              //  String(
              //   commonData?.patientForm?.city?.id || commonData?.patientForm?.city
              // ),
            },
            12: {
              name: "patient[pincode]",
              value: commonData?.patientForm?.pincode || null,
            },
            13: {
              name: "appointment[visit_type]",
              value: commonData?.patientForm?.visitType,
            },
            14: {
              name: "appointment[doctor_id]",
              value: commonData?.patientForm?.attendingDoctor,
            },
            15: {
              name: "appointment[consultant_id]",
              value: commonData?.patientForm?.consultancyDoctor,
            },
            16: {
              name: "appointment[comments]",
              value: commonData?.patientForm?.comments,
            },
            17: {
              name: "appointment[referrer_id]",
              value: commonData?.patientForm?.referrer,
            },
            18: {
              name: "appointment[height]",
              value: commonData?.patientForm?.height,
            },
            19: {
              name: "appointment[weight]",
              value: commonData?.patientForm?.weight,
            },
            20: {
              name: "appointment[fever]",
              value: commonData?.patientForm?.fever || null,
            },
            21: {
              name: "appointment[bp_s]",
              value: commonData?.patientForm?.bpSystolic || null,
            },
            22: {
              name: "appointment[bp_d]",
              value: commonData?.patientForm?.bpDiastolic || null,
            },
            23: {
              name: "appointment[bmi]",
              value: commonData?.patientForm?.bmi || null,
            },

            24: {
              name: "prescription[diagnosis][]",
              value: commonData?.treatmentDetail?.diagnosis || "", // getting error from backend if we passing null
            },
            25: {
              name: "prescription[notes]",
              value: commonData?.treatmentDetail?.notes || null,
            },
            26: {
              name: "prescription[self_notes]",
              value: commonData?.treatmentDetail?.selfNotes || null,
            },
            27: {
              name: "prescription[special_instructions]",
              value: commonData?.treatmentDetail?.splInst || null,
            },

            28: { name: "prescription[followup][]", value: followUp || null },
            29: {
              name: "prescription[medical_tests][]",
              value: medicalTestList || null,
            },
            30: {
              name: "prescription[additional_tests][]",
              value: additionalTests || null,
            },

            31: {
              name: "prescription[daily_exercise]",
              value: checkBoxes?.daily_exercise ? "daily_exercise" : "",
            },
            32: {
              name: "prescription[physiotherapist]",
              value: checkBoxes?.physiotherapist ? "physiotherapist" : "",
            },
            33: {
              name: "prescription[psychologist]",
              value: checkBoxes?.psychologist ? "psychologist" : "",
            },
            34: {
              name: "prescription[dietitian]",
              value: checkBoxes?.dietitian ? "dietitian" : "",
            },

            // Investigations

            ...(commonData?.investigations?.length > 0
              ? commonData.investigations.reduce((acc, investigation, index) => {
                const baseIndex = 34 + index;
                return {
                  ...acc,
                  [baseIndex + 1]: {
                    name: `investigation[medical_test_id]`,
                    value: investigation?.id,
                  },
                };
              }, {})
              : {}),


            // Medicines
            ...filteredMedicines?.reduce((acc, medicine, index) => {
              const investigationLength =
                commonData?.investigations?.length ?? 0; // Default to 0 if investigations is undefined
              const baseIndex =
                35 +
                (investigationLength === 0 ? -1 : investigationLength) +
                index * 4;

              return {
                ...acc,
                [baseIndex]: {
                  name: "treatment_medicine[name]",
                  value: medicine?.name,
                },
                [baseIndex + 1]: {
                  name: "treatment_medicine[medicine_id]",
                  value: medicine?.id || "",
                },
                [baseIndex + 2]: {
                  name: "treatment_medicine[description]",
                  value: medicine?.description,
                },
                [baseIndex + 3]: {
                  name: "treatment_medicine[remark]",
                  value: medicine?.remark,
                },
              };
            }, {}),
          },
          appointment_id: result?.payload?.id,
        };

        setTimeout(async () => {
          const res = await createAndUpdatePrescription(payload);
          // console.log(res, "update prescription");
          if (res?.status?.status === "SUCCESS") {
            dispatch(setPrescriptionData(res.payload));
          }
          const statusMessage = res?.status?.status || "ERROR";
          const detailedMessage =
            res?.status?.message || "Something went wrong";
          showToast(statusMessage, detailedMessage);
          showToast(
            res?.status?.message || res?.status?.status,
            res?.status?.status
          );
        }, 0);
      }
    }

    // else that mean appointment exist appointment can be past date or in future  and prescription can be available or not so we will post   pres in both cases here or  and reshedule
    else {
      const filteredMedicines = medicines.filter(
        (medicine) => medicine?.name?.trim() !== ""
      );

      const payload = {
        data: {
          0: { name: "patient[uid]", value: commonData?.patientForm?.id },
          1: {
            name: "patient[full_name]",
            value: commonData?.patientForm?.fullName,
          },
          2: { name: "patient[email]", value: commonData?.patientForm?.email },
          3: {
            name: "patient[mobile]",
            value: commonData?.patientForm?.mobile,
          },
          4: {
            name: "patient[marital_status]",
            value: commonData?.patientForm?.maritalStatus,
          },
          5: {
            name: "patient[diet]",
            value: commonData?.patientForm?.diet
              ?.split("-")
              ?.join("_")
              ?.toLowerCase(),
          },
          6: {
            name: "patient[gender]",
            value: commonData?.patientForm?.gender?.toLowerCase(),
          },
          7: {
            name: "patient[occupation_id]",
            value:
              commonData?.patientForm?.occupation?.id ||
              commonData?.patientForm?.occupation ||
              null,

            //  String(
            //   commonData?.patientForm?.occupation?.id ||
            //     commonData?.patientForm?.occupation
            // ),
          },
          8: { name: "patient[age]", value: commonData?.patientForm?.age },
          9: {
            name: "patient[address]",
            value: commonData?.patientForm?.address,
          },
          10: {
            name: "patient[education_id]",
            value:
              commonData?.patientForm?.education?.id ||
              commonData?.patientForm?.education ||
              null,
          },
          11: {
            name: "patient[city]",
            value:
              commonData?.patientForm?.city?.id ||
              commonData?.patientForm?.city ||
              null,
            //  String(
            //   commonData?.patientForm?.city?.id || commonData?.patientForm?.city
            // ),
          },
          12: {
            name: "patient[pincode]",
            value: commonData?.patientForm?.pincode || null,
          },
          13: {
            name: "appointment[visit_type]",
            value: commonData?.patientForm?.visitType,
          },
          14: {
            name: "appointment[doctor_id]",
            value: commonData?.patientForm?.attendingDoctor,
          },
          15: {
            name: "appointment[consultant_id]",
            value: commonData?.patientForm?.consultancyDoctor,
          },
          16: {
            name: "appointment[comments]",
            value: commonData?.patientForm?.comments,
          },
          17: {
            name: "appointment[referrer_id]",
            value: commonData?.patientForm?.referrer,
          },
          18: {
            name: "appointment[height]",
            value: commonData?.patientForm?.height,
          },
          19: {
            name: "appointment[weight]",
            value: commonData?.patientForm?.weight,
          },
          20: {
            name: "appointment[fever]",
            value: commonData?.patientForm?.fever || null,
          },
          21: {
            name: "appointment[bp_s]",
            value: commonData?.patientForm?.bpSystolic || null,
          },
          22: {
            name: "appointment[bp_d]",
            value: commonData?.patientForm?.bpDiastolic || null,
          },
          23: {
            name: "appointment[bmi]",
            value: commonData?.patientForm?.bmi || null,
          },

          24: {
            name: "prescription[diagnosis][]",
            value: commonData?.treatmentDetail?.diagnosis || "", // getting error from backend if we passing null
          },
          25: {
            name: "prescription[notes]",
            value: commonData?.treatmentDetail?.notes || null,
          },
          26: {
            name: "prescription[self_notes]",
            value: commonData?.treatmentDetail?.selfNotes || null,
          },
          27: {
            name: "prescription[special_instructions]",
            value: commonData?.treatmentDetail?.splInst || null,
          },

          28: { name: "prescription[followup][]", value: followUp || null },
          29: {
            name: "prescription[medical_tests][]",
            value: medicalTestList || null,
          },
          30: {
            name: "prescription[additional_tests][]",
            value: additionalTests || null,
          },

          31: {
            name: "prescription[daily_exercise]",
            value: checkBoxes?.daily_exercise ? "daily_exercise" : "",
          },
          32: {
            name: "prescription[physiotherapist]",
            value: checkBoxes?.physiotherapist ? "physiotherapist" : "",
          },
          33: {
            name: "prescription[psychologist]",
            value: checkBoxes?.psychologist ? "psychologist" : "",
          },
          34: {
            name: "prescription[dietitian]",
            value: checkBoxes?.dietitian ? "dietitian" : "",
          },

          // Investigations

          ...(commonData?.investigations?.length > 0
            ? commonData.investigations.reduce((acc, investigation, index) => {
              const baseIndex = 34 + index;
              return {
                ...acc,
                [baseIndex + 1]: {
                  name: `investigation[medical_test_id]`,
                  value: investigation?.id,
                },
              };
            }, {})
            : {}),

          // Medicines
          ...filteredMedicines?.reduce((acc, medicine, index) => {
            const investigationLength = commonData?.investigations?.length ?? 0; // Default to 0 if investigations is undefined
            const baseIndex =
              35 +
              (investigationLength === 0 ? -1 : investigationLength) +
              index * 4;

            return {
              ...acc,
              [baseIndex]: {
                name: "treatment_medicine[name]",
                value: medicine?.name,
              },
              [baseIndex + 1]: {
                name: "treatment_medicine[medicine_id]",
                value: medicine?.id || "",
              },
              [baseIndex + 2]: {
                name: "treatment_medicine[description]",
                value: medicine?.description,
              },
              [baseIndex + 3]: {
                name: "treatment_medicine[remark]",
                value: medicine?.remark,
              },
            };
          }, {}),
        },
        appointment_id: appointmentData?.id,
      };

      let appointment = {
        visit_type: commonData?.patientForm?.visitType,
        consultant_id: commonData?.patientForm?.consultancyDoctor,
        doctor_id: commonData?.patientForm?.attendingDoctor,
      };
      // function formatDate(visitDate) {
      //   // Assuming visitDate is a string in 'YYMMDD' format
      //   const year = visitDate.slice(0, 2);
      //   const month = visitDate.slice(2, 4);
      //   const day = visitDate.slice(4, 6);
      //   // Return the formatted date in DD-MM-YY format
      //   return `${day}-${month}-${year}`;
      // }

      //     const visitDate = patientDetailsData?.appointments?.[0]?.visit_date;
      //     if (visitDate) {
      // // Convert YYMMDD to DDMMYY
      // const formattedDate = formatDate(visitDate);
      const userConfirmation = window.confirm(
        ` One Appointment is paid  ${patientDetailsData?.appointments?.[0]?.visit_date
          ? patientDetailsData?.appointments?.[0]?.visit_date
          : appointmentData?.visit_date
            ? appointmentData?.visit_date
            : ""
        } Do you want to reschedule the appointment?`
      );

      if (userConfirmation) {
        appointment = cleanPayload(appointment);
        const rescheduleRes = await rescheduleAppointmentData(
          appointment,
          patientDetailsData?.appointments?.[0]?.id || appointmentData?.id
        );

        if (rescheduleRes?.status?.status === "SUCCESS") {
          payload["appointment_id"] =
            rescheduleRes?.payload?.patient?.appointments[0]?.id;

          dispatch(setAppointmentData(rescheduleRes?.payload));
          try {
            const res = await createAndUpdatePrescription(payload);
            // console.log(res, "update prescription");
            const statusMessage = res?.status?.status || "ERROR";
            const detailedMessage =
              res?.status?.message || "Something went wrong";
            showToast(statusMessage, detailedMessage);
            showToast(
              res?.status?.message || res?.status?.status,
              res?.status?.status
            );
            if (res?.status?.status === "SUCCESS") {
              dispatch(setPrescriptionData(res?.payload));
            }
          } catch (error) {
            console.log(error);
            showToast(
              rescheduleRes?.status?.message || rescheduleRes?.status?.status,
              rescheduleRes?.status?.status
            );
          }
        }
        showToast(
          rescheduleRes?.status?.message || rescheduleRes?.status?.status,
          rescheduleRes?.status?.status
        );
      }
      // else {
      //   try {
      //     const res = await createAndUpdatePrescription(payload);
      //     // console.log(res, "update prescription");
      //     const statusMessage = res?.status?.status || "ERROR";
      //     const detailedMessage =
      //       res?.status?.message || "Something went wrong";
      //     showToast(statusMessage, detailedMessage);
      //     showToast(
      //       res?.status?.message || res?.status?.status,
      //       res?.status?.status
      //     );
      //     if (res?.status?.status === "SUCCESS") {
      //       dispatch(setPrescriptionData(res?.payload));
      //     }
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }
    }
    setButtonLoading(false);
  };
  //  create new visit end

  // Handle input change
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value);

    // Get the text after the last comma
    const splitValue = value.split(",");
    const lastText = splitValue[splitValue.length - 1].trim(); // Text after the last comma

    if (value.endsWith(",") || value === ",") {
      // Open the dropdown with all options when a comma is entered
      // console.log("if", value.endsWith(","), value === ",");
      setOpenDropdown(true);
      setFilteredOptions(
        medicalList?.medical_tests?.map((test) => test[1]) || []
      );
    } else if (lastText) {
      // Filter the options based on the text after the last comma
      // console.log(lastText, "else if");
      if (value === ",") {
        setOpenDropdown(true);
      }
      const filtered =
        medicalList?.medical_tests
          ?.filter((test) =>
            test[1].toLowerCase().includes(lastText.toLowerCase())
          )
          .map((test) => test[1]) || [];
      setFilteredOptions(filtered);
    } else {
      setOpenDropdown(false); // Close dropdown if no matching text
      setHighlightedIndex(0);
    }
  };

  const handleKeyDown1 = (event) => {
    if (openDropdown) {
      if (event.key === "ArrowDown") {
        // Navigate through options with arrow down
        setHighlightedIndex((prevIndex) =>
          prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0
        );
      } else if (event.key === "ArrowUp") {
        // Navigate through options with arrow up
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1
        );
      } else if (event.key === "Enter") {
        // Select the highlighted option on Enter
        event.preventDefault(); // Prevent newline in the textarea
        if (filteredOptions[highlightedIndex]) {
          handleOptionSelect(filteredOptions[highlightedIndex]);
        }
      }
    } else {
      if (event.key === "Tab") {
        setTimeout(() => document.getElementById(`additionalTests`).focus(), 0);
      }
    }
  };

  // Handle selecting an option from the dropdown
  const handleOptionSelect = (selectedTest) => {
    const currentTest = inputValue
      .split(",")
      .map((item) => item.trim())
      .filter(Boolean);
    if (!currentTest.includes(selectedTest)) {
      const currentTests = inputValue.split(",").map((item) => item.trim()); // Get existing values
      const updatedTests = [...currentTests.slice(0, -1), selectedTest]; // Replace the last comma with the new test
      // const newInputValue = updatedTests.join(", ") + ", "; // Add selected test and a comma
      setInputValue(updatedTests);
    }
    setOpenDropdown(false); // Close dropdown after selection
    setHighlightedIndex(0);
  };

  // console.log(prescriptionData, ' prescriptionData');
  // console.log(commonData ,'commonData')
  // console.log(inputValue,'inputValue')

  return (
    <Box
      mt={1}
      p={2}
      m={2}
      paddingLeft={"25px"}
      sx={{
        backgroundColor: "#FFFFFF",
        borderTopRightRadius: "20px",
        borderRadius: "20px",
        border: "1px solid rgba(0, 0, 0, 0.2)",
      }}
    >
      <Grid
        sx={drTableRowBlueBg}
        mt={"auto"}
        p={"8px"}
        paddingX={"8px"}
        container
        spacing={1}
        alignItems="center"
      >
        <Grid
          sx={{ paddingBottom: "10px", paddingLeft: "8px" }}
          item
          xs={12}
          sm={3.9}
        >
          <Typography variant="body1_text_bold" sx={{ color: "neutral.white" }}>
            Medicine
          </Typography>
        </Grid>
        <Grid
          sx={{ paddingBottom: "10px", color: "neutral.white" }}
          item
          xs={12}
          sm={3.7}
        >
          <Typography variant="body1_text_bold">Description</Typography>
        </Grid>
        <Grid
          sx={{ paddingBottom: "10px", color: "neutral.white" }}
          item
          xs={12}
          sm={3.7}
        >
          <Typography variant="body1_text_bold">Remarks</Typography>
        </Grid>
        <Grid
          sx={{ paddingBottom: "10px" }}
          item
          xs={12}
          sm={0.6}
          display={"flex"}
          justifyContent="center"
        >
          <IconButton
            id="addMoreBtn"
            variant="contained"
            sx={{ ...orangeButton, marginLeft: "8px" }}
            onClick={handleAddRow}
          >
            <AddIcon
              sx={{
                width: "30px",
                color: "neutral.white",
                margin: "0px",
                height: "30px",
                padding: "0px",
              }}
            />
          </IconButton>
        </Grid>
      </Grid>

      {medicines?.map((medicine, index) => (
        <Grid
          container
          spacing={1}
          alignItems="center"
          key={index}
          // className="step-ten"
          className={index === 1 ? 'step-ten' : ''}
          sx={{
            backgroundColor: "#F7F7FF",
            marginTop: "3px",
            paddingLeft: "-20px",
            borderBottomLeftRadius:
              medicines?.length - 1 === index ? "20px" : " ",
            borderBottomRightRadius:
              medicines?.length - 1 === index ? "20px" : " ",
            cursor: "pointer",
            "&:hover": {
              boxShadow: "rgba(0, 0, 0, 0.25) 1px 1px 1px",
              //  borderRadius:'20px',
            },
          }}
        >
          <Grid item xs={12} sm={3.9}
            className={currentStep === 6 && "step-seven"}
          >
            <Autocomplete
              options={filteredMedicines}
              freeSolo
              clearOnEscape={false}
              clearIcon={null}
              open={openMedicineList === index}
              onOpen={() => setOpenMedicines(index)}
              onClose={() => setOpenMedicines(false)}
              id={`name-${index}`}
              inputValue={medicine?.name !== null ? medicine?.name : ""}
              onInputChange={(e, value) => {
                handleChange(index, "name", value, e);
              }}
              onChange={(e, value) => {
                // console.log(value);
                if (value) {
                  handleChange(index, "id", value[0]);
                  handlePresence(index, value);
                  setTimeout(() => {
                    document.getElementById(`description-${index}`).focus();
                  }, 0);
                }
              }}
              sx={{
                ...textfieldTextBlueBorder,
                height: "50px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  height: "40px",
                  padding: "9px !important",
                  "& input": {
                    padding: "8px",
                    fontSize: "14px",
                  },
                },
                scrollbarWidth: "none", // Firefox
                msOverflowStyle: "none", // Internet Explorer and Edge
                "&::-webkit-scrollbar": {
                  display: "none", // Chrome, Safari, WebKit
                },
              }}
              PopperComponent={(props) => (
                <Grid item lg={8}>
                  <Popper
                    {...props}
                    placement="bottom-start"
                    style={{
                      ...menuItemStyle,
                      width: props.anchorEl?.offsetWidth || "auto", // Matches the TextField width
                    }}
                    modifiers={[
                      {
                        name: "preventOverflow",
                        options: {
                          boundary: "block",
                        },
                      },
                    ]}
                  >
                    <div
                      style={{
                        maxHeight: 300,
                        overflowY: "auto",
                        scrollbarWidth: "none",
                        // maxWidth: "326px",
                        // minWidth: "320px",
                        width: "100%",
                        border: "2px solid #0067FF",
                        borderTop: "0px",
                        msOverflowStyle: "none",
                        borderRadius: "20px",
                        paddingTop: "7px",
                        backgroundColor: "white",
                      }}
                      css={`
                        &::-webkit-scrollbar {
                          display: none; // Chrome, Safari, and other WebKit browsers
                        }
                      `}
                    >
                      {props.children}
                    </div>
                  </Popper>
                </Grid>
              )}
              getOptionLabel={(option) => option[1]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onFocus={(e) => e.target.select()}
                  onChange={handleSearch}
                  value={searchTerm}
                  placeholder="Medicine"
                  onKeyDown={(e) => handleKeyDown(e, index, "name")}
                // sx={{ border :'1px solid red' ,borderRadius:'5px' }} // if not available
                />
              )}
              renderOption={(props, option, { index }) => (
                <MenuItem
                  sx={{
                    ...menuItemStyle,
                    "&:hover": {
                      backgroundColor: "#0067FF40 !important",
                      borderRadius: "16px",
                      color: "black !important",
                    },
                    ...(highlightedIndexMedicine === index && {
                      backgroundColor: "#0067FF40 !important",
                      borderRadius: "16px",
                      color: "black !important",
                    }),
                  }}
                  {...props}
                  key={option[0]}
                >
                  {option[1]}
                </MenuItem>
              )}
            />
            <Box
              sx={{
                display:
                  currentStep === 6 || currentStep === 7 || currentStep === 8
                    ? "block"
                    : "none",
                height: "200px",
              }}
            ></Box>
          </Grid>

          {/* description  */}
          <Grid item xs={12} sm={3.7}
            className={currentStep === 7 && "step-eight"}>
            <Autocomplete
              options={filteredDescriptionList}
              freeSolo
              clearOnEscape={false}
              clearIcon={null}
              // className="step-eight"
              open={openDescriptionList === index}
              onOpen={() => setOpenDescriptionList(index)}
              onClose={() => setOpenDescriptionList(false)}
              id={`description-${index}`}
              inputValue={
                medicine?.description !== null ? medicine?.description : ""
              }
              onInputChange={(e, value) => {
                handleChange(index, "description", value);
              }}
              onChange={(e, value) => {
                // console.log(value);
                // if (value) {
                //   handleChange(index, "id", value[0]);
                // }
                setTimeout(
                  () => document.getElementById(`remark-${index}`).focus(),
                  setFilteredDescriptionList([]),
                  0
                );
              }}
              filterOptions={(options) => options}
              sx={{
                ...textfieldTextBlueBorder,
                height: "50px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  height: "40px",
                  padding: "9px !important",
                  "& input": {
                    padding: "8px",
                    fontSize: "14px",
                  },
                },
              }}
              PopperComponent={(props) => (
                <Grid item lg={8}>
                  <Popper
                    {...props}
                    placement="bottom-start"
                    // style={{menuItemStyle , width:'100%'}}
                    style={{
                      ...menuItemStyle,
                      width: props.anchorEl?.offsetWidth || "auto", // Matches the TextField width
                    }}
                    modifiers={[
                      {
                        name: "preventOverflow",
                        options: {
                          boundary: "window",
                        },
                      },
                    ]}
                  >
                    <div
                      style={{
                        width: "100%",
                        maxHeight: 300,
                        overflowY: "auto",
                        scrollbarWidth: "none",
                        // maxWidth: "316",
                        // minWidth: "310px",
                        border: "2px solid #0067FF",
                        borderTop: "1px solid #0067FF",
                        msOverflowStyle: "none",
                        borderRadius: "20px",
                        paddingTop: "7px",
                        backgroundColor: "white",
                      }}
                      css={`
                        &::-webkit-scrollbar {
                          display: none; // Chrome, Safari, and other WebKit browsers
                        }
                      `}
                    >
                      {props.children}
                    </div>
                  </Popper>
                </Grid>
              )}
              getOptionLabel={(option) => option[0]}
              renderInput={(params) => (
                <TextField
                  sx={{ fontFamily: "Outfit" }}
                  {...params}
                  onChange={handleSearchDescription}
                  onFocus={(e) => e.target.select()}
                  value={searchTerm}
                  placeholder="Description"
                  onKeyDown={(e) => handleKeyDown(e, index, "description")}
                />
              )}
              renderOption={(props, option, { index }) => (
                <MenuItem
                  sx={{
                    ...menuItemStyle,
                    "&:hover": {
                      backgroundColor: "#0067FF40 !important",
                      borderRadius: "16px",
                      color: "black !important",
                    },
                    ...(highlightedIndexDescription === index && {
                      backgroundColor: "#0067FF40 !important",
                      borderRadius: "16px",
                      color: "black !important",
                    }),
                  }}
                  {...props}
                  // sx={menuItemStyle}
                  key={option[0]}
                >
                  {option[0]}
                </MenuItem>
              )}
            />
            <Box
              sx={{
                display:
                  currentStep === 6 || currentStep === 7 || currentStep === 8
                    ? "block"
                    : "none",
                height: "200px",
              }}
            ></Box>
          </Grid>

          {/* remarks */}
          <Grid item xs={12} sm={3.6}
            className={currentStep === 8 && "step-nine"} >
            <Autocomplete
              options={filteredRemarks}
              freeSolo
              clearOnEscape={false}
              clearIcon={null}
              open={openRemarkList === index}
              onOpen={() => setOpenRemarkList(index)}
              onClose={() => setOpenRemarkList(false)}
              // className="step-nine"
              id={`remark-${index}`}
              inputValue={medicine?.remark !== null ? medicine?.remark : ""}
              onInputChange={(e, value) => {
                handleChange(index, "remark", value);
              }}
              onChange={(e, value) => {
                // console.log(value);
                // if (value) {
                //   handleChange(index, "id", value[0]);
                // }
                handleAddRow();
                setTimeout(() => {
                  document.getElementById(`name-${index + 1}`).focus();
                  // Clear the filtered remarks list
                  setFilteredRemarks([]);
                }, 0);
              }}
              sx={{
                ...textfieldTextBlueBorder,
                height: "50px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  height: "40px",
                  padding: "9px !important",
                  "& input": {
                    padding: "8px",
                    fontSize: "14px",
                  },
                },
              }}
              PopperComponent={(props) => (
                <Grid item lg={8}>
                  <Popper
                    {...props}
                    placement="bottom-start"
                    style={{
                      ...menuItemStyle,
                      width: props.anchorEl?.offsetWidth || "auto", // Matches the TextField width
                    }}
                    modifiers={[
                      {
                        name: "preventOverflow",
                        options: {
                          boundary: "window",
                        },
                      },
                    ]}
                  >
                    <div
                      style={{
                        maxHeight: 300,
                        overflowY: "auto",
                        scrollbarWidth: "none",
                        // maxWidth: "306px",
                        // minWidth: "300px",
                        width: "100%",
                        border: "2px solid #0067FF",
                        borderTop: "1px solid #0067FF",
                        msOverflowStyle: "none",
                        borderRadius: "20px",
                        paddingTop: "7px",
                        backgroundColor: "white",
                      }}
                      css={`
                        &::-webkit-scrollbar {
                          display: none; // Chrome, Safari, and other WebKit browsers
                        }
                      `}
                    >
                      {props.children}
                    </div>
                  </Popper>
                </Grid>
              )}
              getOptionLabel={(option) => option[0]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={handleSearchRemarks}
                  onFocus={(e) => e.target.select()}
                  value={searchTerm}
                  placeholder="Remarks"
                  onKeyDown={(e) => handleKeyDown(e, index, "remark")}
                />
              )}
              renderOption={(props, option, { index }) => (
                <MenuItem
                  sx={{
                    ...menuItemStyle,
                    "&:hover": {
                      backgroundColor: "#0067FF40 !important",
                      borderRadius: "16px",
                      color: "black !important",
                    },
                    ...(highlightedIndexRemark === index && {
                      backgroundColor: "#0067FF40 !important",
                      borderRadius: "16px",
                      color: "black !important",
                    }),
                  }}
                  {...props}
                  // sx={menuItemStyle}
                  key={option[0]}
                >
                  {option[0]}
                </MenuItem>
              )}
            />
            <Box
              sx={{
                display:
                  currentStep === 6 || currentStep === 7 || currentStep === 8
                    ? "block"
                    : "none",
                height: "200px",
              }}
            ></Box>
          </Grid>

          <Grid item xs={12} sm={0.6} display={"flex"} justifyContent="space-between">
            <IconButton
              sx={{ marginLeft: "8px", marginBottom: "7px", height: "28px", marginTop: (currentStep === 6 || currentStep === 7 || currentStep === 8) ? '-20px' : "" }}
              onClick={() => handleRemoveRow(index)}
            >
              <CloseIcon
                sx={{
                  ...xBtn,
                  width: "28px",
                  height: "28px",
                  borderRadius: "20%",
                }}
              />
            </IconButton>
            <Box
              sx={{
                display:
                  currentStep === 6 || currentStep === 7 || currentStep === 8
                    ? "block"
                    : "none",
                height: "200px",
              }}
            ></Box>
          </Grid>
        </Grid>
      ))}

      <Grid mt={3} container spacing={0}>
        <Grid item md={6.8}>
          <Grid
            // display={"flex"}
            // alignItems={"center"}
            item
            xs={12}
            md={12}
          >
            <Grid item md={2.5} xs={12} mt={1}>
              <Typography variant="body1_text_bold">Follow Ups</Typography>
            </Grid>

            <Grid item xs={12} md={11} mt={1}>
              <Autocomplete
                options={Array.isArray(followUpList) ? followUpList : []}
                freeSolo
                disableClearable
                id="follow-up"
                className="step-eleven"
                inputValue={followUp}
                // inputValue={followUp || " "}
                value={followUp || ""}
                onInputChange={(e, value) => setFollowUp(value)}
                onChange={(e, newValue) => setFollowUp(newValue)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Outfit",
                    padding: "0px 11px", // No padding inside the input field
                    borderRadius: "15px",
                    backgroundColor: "#F7F7FF",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc", // Border should remain consistent on hover
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc", // Ensure border remains consistent
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc", // No border color change on focus
                  },
                  "& .MuiInputBase-input": {
                    padding: "0px 4PX",
                  },
                  "& .MuiInputBase-root.Mui-focused": {
                    // backgroundColor: "neutral.white",
                    boxShadow:
                      // "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
                      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
                  },
                  "& fieldset": {
                    border: "none", // Remove default border
                  },
                  "&:hover fieldset": {
                    border: "none", // Remove border on hover
                  },
                  borderRadius: "15px",
                  //   "& fieldset": {
                  //   border: "none", // Remove default border
                  // },
                  // "&:hover fieldset": {
                  //   border: "none", // Remove border on hover
                  // },
                }}
                renderInput={(params) => (
                  <TextField
                    multiline
                    onKeyDown={(event) => {
                      if (event.key === "Tab" || event.key === "Enter") {
                        event.preventDefault(); // Prevent default behavior
                        const medicalTestsElement =
                          document.getElementById("medicalTests");
                        if (medicalTestsElement) {
                          medicalTestsElement.focus();
                        }
                        if (currentStep === 10) {
                          setCurrentStep(11)
                        }
                      }
                    }}
                    rows={1.5}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "0px 8px", // No padding inside TextField
                        borderRadius: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc", // Keep consistent border
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc", // No change on hover
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ccc", // No change on focus
                      },
                      borderRadius: "15px",
                      "& .MuiInputBase-input": {
                        padding: "0px 4PX",
                      },
                      "& fieldset": {
                        border: "none", // Remove default border
                      },
                      "&:hover fieldset": {
                        border: "none", // Remove border on hover
                      },
                    }}
                    {...params}
                    placeholder="Follow Up"
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem
                    sx={{
                      ...menuItemStyle,
                      "&:hover": {
                        backgroundColor: "#0067FF40 !important",
                        borderRadius: "16px",
                        color: "black !important",
                      },
                      // ...(index === 0 && {
                      //   backgroundColor: "#0067FF40 !important",
                      //   borderRadius:"16px",
                      //   color: "black !important",
                      // }),
                    }}
                    {...props}
                    key={option}
                  >
                    {option}
                  </MenuItem>
                )}
              />
            </Grid>
          </Grid>

          <Grid
            mt={2}
            // display={"flex"}
            // mt={4}
            // alignItems={"center"}
            item
            xs={12}
            md={12}
          >
            <Grid item md={5} mt={1}>
              <Typography variant="body1_text_bold">
                Additional Tests
              </Typography>
            </Grid>

            <Grid item md={11} mt={1}>
              <TextField
                id="additionalTests"
                fullWidth
                placeholder="Additional Tests"
                value={additionalTests}
                onChange={(e) => setAdditionalTests(e.target.value)}
                multiline
                rows={1.5}
                // sx={{
                //   padding: "0px",
                //   // maxHeight: 80,
                //   overflowY: "auto",
                //   "& .MuiOutlinedInput-root": {
                //     fontFamily:'Outfit',
                //     padding: "6px 10px",
                //     height: "auto",
                //      borderRadius: "15px",
                //      backgroundColor:'#F7F7FF',
                //   },
                //   // "& .MuiOutlinedInput-notchedOutline": {
                //   //   borderColor: "#ccc",
                //   // },

                //   // "& .MuiInputBase-input": {
                //   //     padding: "0px 4PX",
                //   //  },
                //    "& .MuiInputBase-root.Mui-focused": {
                //     // backgroundColor: "neutral.white",
                //     boxShadow:
                //       // "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;",
                //     'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',

                //   },
                //    "& fieldset": {
                //     border: "none", // Remove default border
                //    },
                //    "&:hover fieldset": {
                //      border: "none", // Remove border on hover
                //    },
                // }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontFamily: "Outfit",
                    // padding: "0px 11px", // Adjust internal padding
                    padding: "6px 10px",
                    borderRadius: "15px",
                    backgroundColor: "#F7F7FF",
                    transition: "box-shadow 0.3s ease, border-color 0.3s ease",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc", // Consistent border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc", // Consistent on hover
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#ccc", // No change in border color on focus
                  },
                  "& .MuiInputBase-input": {
                    padding: "0px 4px", // Input text padding
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    boxShadow:
                      "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em", // Focus shadow
                  },
                  "& fieldset": {
                    border: "none", // Remove default border
                  },
                  "&:hover fieldset": {
                    border: "none", // Keep border removed on hover
                  },
                  borderRadius: "15px", // Apply outer border radius for the component
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>

          {/* suggestions  */}
          <Grid
            // display={"flex"}
            // alignItems={"center"}
            item
            xs={12}
            md={12}
          >
            <Grid item md={2.5} xs={12} mt={1.5} mb={1.5}>
              <Typography variant="body1_text_bold"> Suggestions</Typography>
            </Grid>

            {/* <Grid
              container
              md={12}
              spacing={1}
              alignItems="center"
              sx={{ marginLeft: "-10px" }}
              mt={0}
            >
              {checkBoxes &&
                Object.entries(checkBoxes)?.map(([key, value]) => (
                  
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={key}
                    sx={{ marginTop: "-10px" }}
                  >
                    <FormControlLabel
                      // key={key}
                      control={
                        <Checkbox
                          checked={value === true}
                          onClick={() => handleCheckBox(key)} // Toggle the checkbox on click
                          sx={{ marginRight: 0, paddingRight: 0 }}
                        />
                      }
                      // Display the key as the label (you can replace this with a more user-friendly label)
                      label={
                        <Typography
                          variant="small_Text1"
                          textTransform={"capitalize"}
                        >
                          {" "}
                          {key.replace(/_/g, " ")}
                        </Typography>
                      }
                      sx={{ marginLeft: 1, paddingLeft: 1 }}
                    />
                  </Grid>
                ))}
            </Grid> */}
            <Grid
              container
              md={12}
              spacing={1}
              alignItems="center"
              sx={{ marginLeft: "-10px" }}
              mt={0}
            >
              {checkBoxes &&
                Object.entries(checkBoxes)?.map(
                  ([key, value], index, array) => {
                    const isLastCheckbox = index === array.length - 1;

                    return (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        key={key}
                        sx={{ marginTop: "-10px" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value === true}
                              onClick={() => handleCheckBox(key)}
                              onKeyDown={(e) => {
                                if (isLastCheckbox && e.key === "Tab") {
                                  e.preventDefault();
                                  document
                                    .getElementById("save&print")
                                    ?.focus();
                                }
                              }}
                              sx={{ marginRight: 0, paddingRight: 0 }}
                            />
                          }
                          label={
                            <Typography
                              variant="small_Text1"
                              textTransform={"capitalize"}
                            >
                              {key.replace(/_/g, " ")}
                            </Typography>
                          }
                          sx={{ marginLeft: 1, paddingLeft: 1 }}
                        />
                      </Grid>
                    );
                  }
                )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={5.2} mt={1}>
          <Typography variant="body1_text_bold">Medical Tests</Typography>

          <Box mt={1} sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
            {shortCodeList?.shortcodes?.length > 0 &&
              shortCodeList?.shortcodes?.map((shortcode, index) => (
                <Chip
                  key={index} // Use a unique key for each item
                  sx={{ ...slotChip }}
                  label={shortcode[1]} // Label is the second element in each subarray
                  clickable
                  variant="outlined"
                  color="primary"
                  onClick={() => fetchSelectedShortCode(shortcode[1])}
                />
              ))}
          </Box>

          {/* {old text filed that we used} */}
          {/* <TextField
            fullWidth
            placeholder="Medical Tests"
            setMedicalTestList
            value={medicalTestList}
            onChange={(e) => setMedicalTestList(e.target.value)}
            multiline
            rows={5}
            sx={{
              // width:'100%',
              mt: "15px",
              padding: "0px",
              maxHeight: 250,
              overflowY: "auto",
              "& .MuiOutlinedInput-root": {
                padding: "6px",
                height: "auto",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ccc",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ccc",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ccc",
              },
              "&:hover": {
                boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
              },
            }}
            variant="outlined"
          /> */}

          <div style={{ position: "relative" }}>
            <TextField
              id="medicalTests"
              fullWidth
              variant="outlined"
              placeholder="Medical Tests"
              multiline
              className="step-twelve"
              rows={8.2}
              value={inputValue}
              onBlur={() => setOpenDropdown(false)}
              onChange={(e) => {
                setMedicalTestList(e.target.value);
                handleInputChange(e); // Ensure it's invoked correctly
              }}
              onKeyDown={handleKeyDown1}
              sx={{
                "& .MuiOutlinedInput-root": {
                  fontFamily: "Outfit",
                  // padding: "0px 11px", // Adjust internal padding
                  padding: "6px 10px",
                  borderRadius: "15px",
                  mt: "15px",
                  mb: "15px",
                  backgroundColor: "#F7F7FF",
                  transition: "box-shadow 0.3s ease, border-color 0.3s ease",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ccc", // Consistent border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ccc", // Consistent on hover
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#ccc", // No change in border color on focus
                },
                "& .MuiInputBase-input": {
                  padding: "0px 4px", // Input text padding
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                  boxShadow:
                    "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em", // Focus shadow
                },
                "& fieldset": {
                  border: "none", // Remove default border
                },
                "&:hover fieldset": {
                  border: "none", // Keep border removed on hover
                },
                borderRadius: "15px", // Apply outer border radius for the component
              }}
            />

            {/* Custom Dropdown */}
            {openDropdown && (
              <Paper
                style={{
                  position: "absolute",
                  zIndex: 1000,
                  marginTop: "-10px",
                  maxHeight: "250px",
                  width: "100%",
                  borderRadius: "20px",
                  overflowY: "auto",
                  border: "2px solid #0067FF",
                  display: filteredOptions.length === 0 ? "none" : "",
                }}
              >
                <List>
                  {filteredOptions?.map((option, index) => (
                    <MenuItem
                      sx={{
                        ...menuItemStyle,
                        "&:hover": {
                          backgroundColor: "#0067FF40 !important",
                          color: "black !important",
                          borderRadius: "16px",
                        },
                        ...(index === highlightedIndex && {
                          backgroundColor: "#0067FF40 !important",
                          borderRadius: "16px",
                          color: "black !important",
                        }),
                      }}
                      key={index}
                      onClick={() => handleOptionSelect(option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </List>
              </Paper>
            )}
          </div>
        </Grid>


        {/* <Grid item xs={6}>
          <Typography variant="body1_text_bold">Videos</Typography>
          <FormControl sx={{ mt: "1rem" }} fullWidth variant="outlined">
            <Autocomplete
              sx={textfieldTextPrep}
              options={transformedVideos.sort(
                (a, b) => -b.group.localeCompare(a.group)
              )}
              groupBy={(option) => option.group}
              getOptionLabel={(option) => option.label}
              value={
                transformedVideos.find((opt) => opt.label === vedios) || null
              }
              onChange={(event, newValue) => {
                setVedios(newValue ? newValue.label : "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Videos"
                  variant="outlined"
                  fullWidth
                />
              )}
              renderOption={(props, option) => (
                <MenuItem
                  {...props}
                  sx={{
                    ...menuItemStyle,
                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#e0e0e0 !important",
                    },
                  }}
                >
                  {option.label}
                </MenuItem>
              )}
              renderGroup={(params) => [
                <ListSubheader 
                  key={params.key}
                  sx={{
                    ...menuItemStyle,
                    position: "unset",
                    backgroundColor: "inherit",
                    fontSize: "0.875rem",
                  }}
                >
                  {params.group}
                </ListSubheader>,
                params.children,
              ]}
            />
          </FormControl>
        </Grid> */}

        <Box
          mt={12}
          md={12}
          lg={12}
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
          gap={2}
          // bgcolor="beige"

          marginTop={"30px"}
          mb={4}
          // padding={"0.8rem 1rem"}
          sx={{ width: "100%" }}
        >
          {/* <Box></Box> */}

          <Box>
            {/* {!appointmentData?.id ? <p>{' '}</p> : */}
            <Button
              onClick={CreatePrescription}
              id="createNewVisit"
              sx={{
                // backgroundColor: "neutral.main",
                // "&:hover": { backgroundColor: "neutral.main" },
                // borderRadius: "20px",
                // paddingX: "30px",
                // paddingY: "10px",
                ...primaryBtn,
                width: "195px",
              }}
              disabled={buttonLoading}
              className="btn draw-border"
              variant="contained"
            >
              Create New Visit
            </Button>


            <Button
              onKeyDown={(event) => {
                if (event.key === "Tab" || event.key === "Enter") {
                  event.preventDefault(); // Prevent default behavior
                  const createNewVisitElement =
                    document.getElementById("createNewVisit");
                  if (createNewVisitElement) {
                    createNewVisitElement.focus();
                  }
                }
              }}
              variant="contained"
              id="save&update"
              sx={{
                ...primaryBtn,
                width: "195px",
                marginLeft: "10px",
              }}
              disabled={buttonLoading}
              onClick={updatePrescription}
              className="btn draw-border"
            >
              Save and Update
            </Button>
          </Box>

          <Box>
            {/* {console.log(dayjs().tz("Asia/Kolkata").format("YYYY-MM-DD"))} */}

            {/* show reprint when status is completed   */}

            {/*   
  {patientDetailsData?.appointments?.filter(appointment =>
      ["completed", "paid"].includes(appointment.status)} */}


            {appointmentsDate && appointmentsDate[0]?.id !== appointmentData?.id
              // && patientDetailsData?.appointments[0]?.visit_date === dayjs().tz("Asia/Kolkata").format("YYYY-MM-DD")
              && appointmentData?.status === 'completed'
              ? (
                <Button
                  onClick={printPrescriptionData}
                  onKeyDown={(event) => {
                    if (event.key === "Tab" || event.key === "Enter") {
                      event.preventDefault(); // Prevent default behavior
                      const saveUpdateElement =
                        document.getElementById("save&update");
                      if (saveUpdateElement) {
                        saveUpdateElement.focus();
                      }
                    }
                  }}
                  className="btn draw-border"
                  id="save&print"
                  variant="contained"
                  sx={{
                    ...primaryBtnBlue,
                    backgroundColor: "#FF392B",
                    "&:hover": {
                      backgroundColor: "#cf1d11",
                    },
                    fontSize: "14px",
                    width: "195px",
                  }}
                  disabled={buttonLoading}
                >
                  Reprint
                </Button>
              ) : (
                <Button
                  onClick={updatePrescriptionAndPrint}
                  onKeyDown={(event) => {
                    if (event.key === "Tab" || event.key === "Enter") {
                      event.preventDefault(); // Prevent default behavior
                      const saveUpdateElement =
                        document.getElementById("save&update");
                      if (saveUpdateElement) {
                        saveUpdateElement.focus();
                      }
                    }
                  }}
                  className="btn draw-border"
                  id="save&print"
                  variant="contained"
                  sx={{
                    ...primaryBtnBlue,
                    fontSize: "14px",
                    width: "195px",
                  }}
                  disabled={buttonLoading}
                >
                  Save and Print
                </Button>
              )}
          </Box>

        </Box>

      </Grid>

      <div style={{ display: "none" }}>
        <PrintPrescription
          ref={printRef}
          prescription={{ ...prescriptionSuccessRes, mobileNumber }}
        />
      </div>
    </Box>
  );
};

export default Medicine;
