import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  TextField,
  IconButton,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import {
  headingStyle,
  xBtn,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { textfieldTextBlueBr } from "../../style/PharmacyStyle";
import { orangeButton, primaryBtnBlue, textfieldTextBlueBorder } from "../../style/PatientDetailsStyle";
import { generateMedicine } from "../../connections/postApi";
import { showToast } from "../../utils/toastUtil";
import { autoFocusOffWithOutBorder, drTableRowBlueBg } from "../../style/PatinetFormStyle";
import medicines_icon from '../../assets/medicine.svg'
import UseFetchPackList from "../../helper/UseFetchPackList";
import UseFetchFormType from "../../helper/UseFetchFormType";
import UseGetAllMedicineSaltList from "../../helper/UseGetAllMedicineSaltList";
import UseMedicineSaltPharmacy from "../../helper/UseMedicineSaltPharmacy";

const NewMedicines = ({ onClose, refetch }) => {

  const { data: packList } = UseFetchPackList();
  const { data: formTypeList } = UseFetchFormType();
  // const { data: medicineSaltList } = UseGetAllMedicineSaltList();
  const { data: medicineSaltList } = UseMedicineSaltPharmacy();

  const [fieldOptions, setFieldOptions] = useState({
    formTypeList: JSON.parse(localStorage.getItem("formTypeList")) || [],
    medicineSaltList: JSON.parse(localStorage.getItem("medicineSaltList")) || [],
    packList: JSON.parse(localStorage.getItem("packList")) || [],
  });

  const [formState, setFormState] = useState({
    name: "",
    company: "",
    srp: "",
    form_type: "",
    flat_price: "",
    auto_purchase_threshold: "",
    medicine_salt_id: "",
    medicine_salt: '',
    gst: "",
    discount: "",
    hsn_name: "",
    cgst: "",
    sgst: "",
  });

  const [errors, setErrors] = useState({});
  const [batches, setBatches] = useState([
    {
      batch_no: "",
      expiry_date: "",
      amount: "",
      purchase_cost: "",
      form_type: "",
      packing: "",
      stock_left: ""
    }
  ])

  const validateForm = () => {
    const newErrors = {};

    if (!formState.name) newErrors.name = "Medicine name is required.";
    if (!formState.company) newErrors.company = "Company is required.";
    // if (!formState.srp || isNaN(formState.srp)) newErrors.srp = "Valid SRP is required.";
    // if (!formState.form_type) newErrors.form_type = "Form type is required.";
    if (!formState.flat_price || isNaN(formState.flat_price)) newErrors.flat_price = "Flat price is required.";
    // if (!formState.auto_purchase_threshold || isNaN(formState.auto_purchase_threshold)) {
    //   newErrors.auto_purchase_threshold = "Auto purchase threshold is required.";
    // }
    // if (!formState.medicine_salt || isNaN(formState.medicine_salt)) {
    //   newErrors.medicine_salt = "Medicine salt  is required.";
    // }
    // if (!formState.gst || isNaN(formState.gst)) newErrors.gst = "Valid GST is required.";
    // if (!formState.discount || isNaN(formState.discount)) newErrors.discount = "Valid discount is required.";
    // if (!formState.hsn_name) newErrors.hsn_name = "HSN code is required.";
    if (!formState.cgst || isNaN(formState.cgst)) newErrors.cgst = "Valid CGST is required.";
    if (!formState.sgst || isNaN(formState.sgst)) newErrors.sgst = "Valid SGST is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };


  // console.log(batches)
  //handle form change
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({ ...prev, [name]: undefined }));
  };

  const handleAddRow = () => {
    setBatches((prev) => [...prev, {
      batch_no: "",
      expiry_date: "",
      amount: "",
      purchase_cost: "",
      form_type: "",
      packing: "",
      stock_left: ""
    }])
  };

  const handleChange = (index, field, value) => {
    // console.log(index, field, value)
    const newBatchArr = [...batches];
    newBatchArr[index] = { ...newBatchArr[index], [field]: value };
    setBatches(newBatchArr);
  };

  useEffect(() => {
    if (packList?.payload?.packing?.length > 0) {
      localStorage.setItem(
        "packList",
        JSON.stringify(packList.payload.packing)
      );
      setFieldOptions((prev) => ({
        ...prev,
        packList: packList.payload.packing,
      }));
    }

    if (formTypeList?.payload?.form_types?.length > 0) {
      localStorage.setItem(
        "formTypeList",
        JSON.stringify(formTypeList?.payload?.form_types)
      );
      setFieldOptions((prev) => ({
        ...prev,
        formTypeList: formTypeList?.payload?.form_types,
      }));
    }


    if (medicineSaltList?.payload?.medicine_salts?.length > 0) {

      // const transformedData = medicineSaltList.payload.medicine_salts.map((salt) => [id: salt.id, name: salt.name])

      const transformedData = medicineSaltList.payload.medicine_salts.map((salt) => [salt.id, salt.name]);

      localStorage.setItem(
        "medicineSaltList",
        JSON.stringify(transformedData)
      );
      setFieldOptions((prev) => ({
        ...prev,
        medicineSaltList: transformedData,
      }));
    }


  }, [formTypeList, medicineSaltList, packList]);


  const handleDeleteRow = (index) => {
    setBatches(
      (prev) => prev.filter((_, i) => i !== index) // Keep rows that don't match the index
    );
  };



  const handleKeyDown = (e, index, field) => {

    if (field === "batch_no" && e.key === "Enter") {
      if (!batches[index]?.batch_no) {
        setTimeout(() => document.getElementById(`submit`).focus(), 0)
        return;
      }
      setTimeout(() => document.getElementById(`expiry_date-${index}`).focus(), 0)
    }

    if (field === "expiry_date" && e.key === "Enter") {
      setTimeout(() => document.getElementById(`amount-${index}`).focus(), 0)
    }

    if (field === "amount" && e.key === "Enter") {
      setTimeout(() => document.getElementById(`purchase_cost-${index}`).focus(), 0)
    }

    if (field === "purchase_cost" && e.key === "Enter") {
      setTimeout(() => document.getElementById(`form_type-${index}`).focus(), 0)
    }

    if (field === "form_type" && e.key === "Enter") {
      setTimeout(() => document.getElementById(`packing-${index}`).focus(), 0)
    }

    if (field === "packing" && e.key === "Enter") {
      setTimeout(() => document.getElementById(`stock_left-${index}`).focus(), 0)
    }

    if (field === "stock_left" && e.key === "Enter") {
      if (batches?.length > index + 1) {
        setTimeout(() => document.getElementById(`batch_no-${index + 1}`).focus(), 100)
      } else {
        handleAddRow()
        setTimeout(() => document.getElementById(`batch_no-${index + 1}`).focus(), 100)
      }
    }

  };


  const handleCreateMedicine = async (e) => {
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    e.preventDefault();
    const payload = {
      medicine: {
        ...formState,
        ...(batches.length > 0 && {
          medicine_batches_attributes: batches,
        }),
      },
    };
    try {
      const result = await generateMedicine(
        payload
      );
      console.log(result);
      showToast(
        result?.status?.message || result?.status?.status,
        result?.status?.status
      );
      // if (result?.status?.status === "SUCCESS") {
      //   showToast("Purchase Order Created  Successfully" ,"SUCCESS");
      //   // navigate("/admin/purchase_orders");
      // }
      refetch()
    } catch (error) {
      showToast(error.message, "ERROR");
    }
  };

  // console.log(medicines);
  return (
    <>
      <Box sx={{ width: "100%", display: 'flex', height: "90vh", flexDirection: 'column', justifyContent: "space-between" }}>
        <Box sx={{
          overflowY: 'auto', maxHeight: "80vh", '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}  >

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={4}
            position="sticky"
          >
            <Box></Box>


            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: "8px" }} >
              <img src={medicines_icon} alt='' sx={{ color: 'neutral.navyBlue', marginBottom: "4px", marginRight: '8px' }} />
              <Typography
                variant="heading_Large"
                fontWeight={700}
                gutterBottom
                sx={headingStyle}
              >
                New Medicines
              </Typography>
            </Box>
            <IconButton sx={{ marginBottom: "r0em" }} onClick={onClose}>
              <CloseIcon size="small" sx={{ color: "red" }} />
            </IconButton>
          </Box>

          <Grid container spacing={2}>

            <Grid item sm={2}>
              <TextField value={formState?.name} fullWidth placeholder="Medicine name " name="name" onChange={(e) => handleFormChange(e)} sx={autoFocusOffWithOutBorder}
                error={!!errors['name']}
                helperText={errors['name']}
              />
            </Grid>
            <Grid item sm={2}>
              <TextField value={formState?.company} fullWidth placeholder="Company " name="company" onChange={(e) => handleFormChange(e)} sx={autoFocusOffWithOutBorder}
                error={!!errors['company']}
                helperText={errors['company']}
              />
            </Grid>
            <Grid item sm={2}>
              <TextField value={formState?.srp} fullWidth placeholder="SRP " name="srp" onChange={(e) => handleFormChange(e)} sx={autoFocusOffWithOutBorder}
                error={!!errors['srp']}
                helperText={errors['srp']}
              />
            </Grid>
            <Grid item sm={2}>
              <TextField value={formState?.form_type} fullWidth placeholder="Form type " name="form_type" onChange={(e) => handleFormChange(e)} sx={autoFocusOffWithOutBorder}
                error={!!errors['form_type']}
                helperText={errors['form_type']}
              />
            </Grid>
            <Grid item sm={2}>
              <TextField
                fullWidth
                onChange={(e) => handleFormChange(e)}
                value={formState.flat_price}
                name="flat_price"
                placeholder="Flat Price"
                type="number"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.flat_price}
                helperText={errors.flat_price}
              />
            </Grid>

            <Grid item sm={2}>
              <TextField
                fullWidth
                onChange={(e) => handleFormChange(e)}
                value={formState?.auto_purchase_threshold}
                name="auto_purchase_threshold"
                placeholder="Auto purchase"
                type="number"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.auto_purchase_threshold}
                helperText={errors.auto_purchase_threshold}
              />
            </Grid>

            <Grid item sm={2}>
              {/* <Autocomplete
                clearOnEscape={false}
                clearIcon={null}
                sx={{
                  ...autoFocusOffWithOutBorder,
                  "& .MuiOutlinedInput-root": {
                    padding: "2px 8px", // No padding inside the input field
                    borderRadius: "15px",
                     backgroundColor:'#F7F7FF',
                  },
                }}
                options={
                  fieldOptions?.medicineSaltList?.filter(
                    (option) => option
                  ) || []
                } // Filter out invalid entries
                name="medicine_id"
                getOptionLabel={(option) => option || ""} // Use the string value directly
                inputValue={formState?.name || ""} // Handle input display safely
                onInputChange={(e, value) => {
                   handleFormChange(e)
                  // handleChange(index, "form_type", value, e); // Update the form state on input change
                }}
                onChange={(e, value) => {
                  if (value) {
                    // Assuming `value` is a string directly
                    console.log(value);
                    handleFormChange(e)
                    // handleChange(index, "form_type", value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Medicine salt"
                    error={!!errors.medicine_salt}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option}>
                    {option[1]}
                  </MenuItem>
                )}
                isOptionEqualToValue={(option, value) =>
                  option === value
                } // Compare values directly
              /> */}


              <Autocomplete
                clearOnEscape={false}
                clearIcon={null}
                sx={{
                  ...autoFocusOffWithOutBorder,
                  "& .MuiOutlinedInput-root": {
                    padding: "2px 8px",
                    borderRadius: "15px",
                    backgroundColor: "#F7F7FF",
                  },
                }}
                options={fieldOptions?.medicineSaltList?.filter((option) => option) || []}
                name="medicine_salt"
                getOptionLabel={(option) => option[1] || ""}
                inputValue={formState?.medicine_salt || ""}
                onInputChange={(e, value) => {
                  handleFormChange({ target: { name: "medicine_salt", value } });
                }}
                onChange={(e, value) => {
                  if (value) {
                    console.log(value);
                    handleFormChange({ target: { name: "medicine_salt_id", value: value[0] } });
                    handleFormChange({ target: { name: "medicine_salt", value: value[1] } });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Medicine salt"
                    error={!!errors.medicine_salt}
                    helperText={errors['medicine_salt']}
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props} key={option[0]}>
                    {option[1]}
                  </MenuItem>
                )}
                isOptionEqualToValue={(option, value) => option[0] === value[0]}
              />

            </Grid>

            <Grid item sm={2}>
              <TextField
                fullWidth
                onChange={(e) => handleFormChange(e)}
                value={formState.gst}
                name="gst"
                placeholder="GST"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.gst}
                helperText={errors.gst}
              />
            </Grid>

            <Grid item sm={2}>
              <TextField
                fullWidth
                onChange={(e) => handleFormChange(e)}
                value={formState.discount}
                name="discount"
                placeholder="Discount"
                type="Discount"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.discount}
                helperText={errors.discount}
              />
            </Grid>

            <Grid item sm={2}>
              <TextField
                fullWidth
                onChange={(e) => handleFormChange(e)}
                value={formState.mobile}
                name="hsn_name"
                placeholder="HSN code"
                type="number"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.hsn_name}
                helperText={errors.hsn_name}
              />
            </Grid>

            <Grid item sm={2}>
              <TextField
                fullWidth
                onChange={(e) => handleFormChange(e)}
                value={formState.cgst}
                name="cgst"
                placeholder="CGST"
                type="number"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.cgst}
                helperText={errors.cgst}
              />
            </Grid>

            <Grid item sm={2}>
              <TextField
                fullWidth
                onChange={(e) => handleFormChange(e)}
                value={formState.sgst}
                name="sgst"
                placeholder="SGST"
                type="number"
                required
                variant="outlined"
                sx={{ ...autoFocusOffWithOutBorder }}
                error={!!errors.sgst}
                helperText={errors.sgst}
              />
            </Grid>


          </Grid>

          <Grid item xs={12}>
            <TableContainer sx={{ mt: 2, width: "100%" }}>
              <Table>

                <TableHead stickyHeader >
                  <TableRow sx={{ ...drTableRowBlueBg, textAlign: "center" }}>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        borderTopLeftRadius: "20px",
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      Batches
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      Expiry
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      Amount
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      Purchase cost
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      Form type
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      Packing
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.white',
                        p: "4px",
                        textAlign: "center",
                      }}
                    >
                      Stock left
                    </TableCell>


                    <TableCell sx={{ p: "4px", textAlign: "center", borderTopRightRadius: "20px" }}>
                      <IconButton onClick={handleAddRow} sx={{ ...orangeButton, marginLeft: "7px", marginY: '2px' }}>
                        <AddIcon sx={{
                          color: 'neutral.white',
                          width: '30px',
                          height: '32px', padding: '0px'
                        }} />
                      </IconButton>
                    </TableCell>

                  </TableRow>
                </TableHead>

                <TableBody>
                  {batches?.map((item, index) => (
                    <TableRow sx={{ padding: '0px', margin: '0px' }}>

                      <TableCell
                        variant="body1_text_bold"
                        sx={{
                          width: "14.28%",
                          color: 'neutral.white',
                          p: "4px",
                          textAlign: "center",
                        }}
                      >
                        <TextField
                          placeholder="Batch no"
                          value={item?.batch_no}
                          onChange={(e) => {
                            handleChange(index, "batch_no", e.target.value);
                          }}
                          id={`batch_no-${index}`}
                          onKeyDown={(e) => handleKeyDown(e, index, "batch_no")}
                          sx={textfieldTextBlueBr}
                        ></TextField>
                      </TableCell>

                      {/* Quantity Date Field */}
                      <TableCell
                        variant="body1_text_bold"
                        sx={{
                          width: "14.28%",
                          color: 'neutral.white',
                          p: "4px",
                          textAlign: "center",
                        }}
                      >
                        <TextField
                          placeholder="expiry"
                          value={item?.expiry_date}
                          onChange={(e) => {
                            handleChange(index, "expiry_date", e.target.value);
                          }}
                          sx={textfieldTextBlueBr}
                          name="expiry_date"
                          fullWidth
                          type="date"
                          id={`expiry_date-${index}`}
                          onKeyDown={(e) => handleKeyDown(e, index, "expiry_date")}
                          inputProps={{ min: new Date().toISOString().split("T")[0] }}
                        />
                      </TableCell>

                      <TableCell
                        variant="body1_text_bold"
                        sx={{
                          width: "14.28%",
                          color: 'neutral.white',
                          p: "4px",
                          textAlign: "center",
                        }}
                      >
                        <TextField
                          placeholder="Amount"
                          sx={textfieldTextBlueBr}
                          fullWidth
                          value={item?.amount}
                          onChange={(e) => {
                            handleChange(index, "amount", e.target.value);
                          }}
                          id={`amount-${index}`}
                          onKeyDown={(e) => handleKeyDown(e, index, "amount")}
                          name="amount"
                          type="number"
                        />
                      </TableCell>

                      <TableCell
                        variant="body1_text_bold"
                        sx={{
                          width: "14.28%",
                          color: 'neutral.white',
                          p: "4px",
                          textAlign: "center",
                        }}
                      >
                        <TextField
                          placeholder="Purchase cost"
                          value={item?.purchase_cost}
                          onChange={(e) => {
                            handleChange(index, "purchase_cost", e.target.value);
                          }}
                          name="purchase_cost"
                          sx={textfieldTextBlueBr}
                          id={`purchase_cost-${index}`}
                          onKeyDown={(e) => handleKeyDown(e, index, "purchase_cost")}
                          fullWidth
                          type="number"
                        />
                      </TableCell>

                      <TableCell
                        variant="body1_text_bold"
                        sx={{
                          borderTopLeftRadius: "20px",
                          color: "neutral.white",
                          p: "4px",
                          textAlign: "center",
                          minWidth: "120px",
                        }}
                      >
                        <Autocomplete
                          clearOnEscape={false}
                          clearIcon={null}
                          sx={{
                            ...textfieldTextBlueBorder,
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                              height: "40px",
                              padding: "9px !important",
                              "& input": {
                                padding: "8px",
                                fontSize: "14px",
                              },
                              "& .MuiAutocomplete-popupIndicator": {
                                display: "none", // Hides the dropdown icon
                              },
                            },
                          }}
                          options={
                            fieldOptions?.formTypeList?.filter(
                              (option) => option
                            ) || []
                          } // Filter out invalid entries
                          getOptionLabel={(option) => option || ""} // Use the string value directly
                          inputValue={item?.form_type || ""} // Handle input display safely
                          onInputChange={(e, value) => {
                            handleChange(index, "form_type", value, e); // Update the form state on input change
                          }}
                          onChange={(e, value) => {
                            if (value) {
                              // Assuming `value` is a string directly
                              console.log(value);
                              handleChange(index, "form_type", value);
                            }
                          }}
                          id={`form_type-${index}`}
                          onKeyDown={(e) => handleKeyDown(e, index, "form_type")}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Form type"

                              error={!!errors.suppliers}
                            />
                          )}
                          renderOption={(props, option) => (
                            <MenuItem {...props} key={option}>
                              {option}
                            </MenuItem>
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          } // Compare values directly
                        />
                      </TableCell>


                      <TableCell
                        variant="body1_text_bold"
                        sx={{
                          borderTopLeftRadius: "20px",
                          color: "neutral.white",
                          p: "4px",
                          textAlign: "center",
                          minWidth: "120px",
                        }}
                      >
                        <Autocomplete
                          clearOnEscape={false}
                          clearIcon={null}
                          sx={{
                            ...textfieldTextBlueBorder,
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                              height: "40px",
                              padding: "9px !important",
                              "& input": {
                                padding: "8px",
                                fontSize: "14px",
                              },
                              "& .MuiAutocomplete-popupIndicator": {
                                display: "none", // Hides the dropdown icon
                              },
                            },
                          }}
                          options={
                            fieldOptions?.packList?.filter(
                              (option) => option
                            ) || []
                          } // Filter out invalid entries
                          getOptionLabel={(option) => option || ""} // Use the string value directly
                          inputValue={item?.packing || ""} // Handle input display safely
                          onInputChange={(e, value) => {
                            handleChange(index, "packing", value, e); // Update the form state on input change
                          }}
                          id={`packing-${index}`}
                          onKeyDown={(e) => handleKeyDown(e, index, "packing")}
                          onChange={(e, value) => {
                            if (value) {
                              // Assuming `value` is a string directly
                              console.log(value);
                              handleChange(index, "packing", value);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select pack"
                              error={!!errors.suppliers}
                            />
                          )}
                          renderOption={(props, option) => (
                            <MenuItem {...props} key={option}>
                              {option}
                            </MenuItem>
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          } // Compare values directly
                        />
                      </TableCell>

                      <TableCell
                        variant="body1_text_bold"
                        sx={{
                          width: "14.28%",
                          color: 'neutral.white',
                          p: "4px",
                          textAlign: "center",
                        }}
                      >
                        <TextField
                          placeholder="stock left"
                          sx={textfieldTextBlueBr}
                          value={item?.stock_left}
                          onChange={(e) => {
                            handleChange(index, "stock_left", e.target.value);
                          }}
                          name="stock_left"
                          fullWidth
                          id={`stock_left-${index}`}
                          onKeyDown={(e) => handleKeyDown(e, index, "stock_left")}
                          type='number'
                        />
                      </TableCell>




                      {/* Icon Column */}
                      <TableCell
                        variant="body1_text_bold"
                        sx={{
                          width: "auto", // Remaining space for icon
                          color: 'neutral.white',
                          p: "4px",
                          textAlign: "center",
                        }}
                      >
                        <IconButton sx={{ marginLeft: '8px', marginBottom: "7px", }}
                        // onClick={() => handleRemoveRow(index)}
                        >
                          <CloseIcon sx={{ ...xBtn, width: '28px', height: '28px', borderRadius: '20%' }} onClick={() => handleDeleteRow(index)} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>


              </Table>
            </TableContainer>
          </Grid>
        </Box>


        <Box
          container
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ zIndex: "10" }}
          lg={12}
          marginBottom={'5px'}
        >
          <Grid item>

          </Grid>

          <Grid item>

            <Button
              onClick={handleCreateMedicine}
              className="btn draw-border"
              variant="contained"
              id="submit"
              sx={{
                ...primaryBtnBlue,
                width: '195px',
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
              }}
            //   disabled={isLoading}
            >
              Create Medicine
            </Button>
          </Grid>
        </Box>
      </Box>

    </>
  );
};

export default NewMedicines;
