import React, { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Typography,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    Table,
    Button,
    FormControl,
    TextField,
} from "@mui/material";
import {
    tableCellLab,
    tableRowLab,
} from "../../style/LapPageStyle";
import "react-toastify/dist/ReactToastify.css";
import CustomModal from "../CustomModal/CustomModal";
import UseFetchCollections from "../../helper/UseFetchCollections";
import PastBillPharmacyByInvoice from "../ModalChildren/PastBillPharmacyByInvoice";
import Loader from "../UI/Loader";
import not_found from '../../assets/not_found.svg';
import { primaryBtnBlue } from "../../style/PatientDetailsStyle";

const RefundInvoices = () => {
    const [selectedDate, setSelectedDate] = useState(
        new Date().toISOString().split("T")[0]
    );

    const [total, setTotal] = useState(0);

    //get collection data
    const {
        data: collectionArray,
        refetch,
        isLoading,
    } = UseFetchCollections(selectedDate, 'cancelled,returned');
    // } = UseFetchCollections(selectedDate, 'cancelled,returned');

    // Handle date change
    const handleDateChange = (event) => {
        setTotal(0);
        setSelectedDate(event.target.value);
    };

    useEffect(() => {
        if (collectionArray?.payload?.invoices) {
            const grandTotalSum = collectionArray.payload.invoices.reduce(
                (acc, invoice) => {
                    return acc + (invoice.grand_total < 0 ? (Math.abs(invoice.grand_total)) :
                        invoice.grand_total || 0);
                },
                0
            );
            setTotal(grandTotalSum);
        }
    }, [collectionArray]);


    return (
        <Box sx={{ width: "100%" }}>

            <Box display="flex" justifyContent="space-between" width="100%">
                <FormControl variant="outlined" sx={{ width: "160px" }}>
                    <TextField
                        defaultValue={new Date().toISOString().split("T")[0]}
                        max={new Date().toISOString().split("T")[0]}
                        type="date"
                        sx={{
                            marginTop: "2px",
                            "& .MuiOutlinedInput-root": {
                                height: "40px",
                                borderRadius: '15px',
                                fontSize: "14px",
                                "& input": {
                                    padding: "8px",
                                    fontSize: "14px",
                                },
                                fontWeight: '600',
                                backgroundColor: "#F7F7FF",
                                transition: "background-color 0.3s",
                            },
                            "& fieldset": {
                                border: "none", // Removes the default border
                            },
                            "& .MuiInputBase-root": {
                                backgroundColor: "#F7F7FF",
                                transition: "background-color 0.3s",
                            }, cursor: 'pointer',

                        }}
                        value={selectedDate}
                        onChange={handleDateChange}
                        name="Date"
                    />
                </FormControl>

                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography fontWeight={"bold"} variant="h6" mb={2}>
                    </Typography>

                    <Typography fontWeight={"600"} mb={2} sx={{ backgroundColor: '#F7F7FF', padding: '10px', borderRadius: "12px" }}>
                        <Typography fontSize={'16px'} variant="span" >  Refunds  </Typography>    &#8377;   {" "}  {'-'}{total}
                    </Typography>
                </Box>



            </Box>



            <Grid container sm={12} md={12} marginBottom={"auto"} lg={12}>
                <Grid item xs={12}>
                    <TableContainer sx={{
                        mt: 2, width: "100%",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                        "-ms-overflow-style": "none",
                        "scrollbar-width": "none",

                    }}>

                        {collectionArray?.payload?.invoices?.length > 0 && !isLoading ? (
                            <Table sx={{
                                width: "100%",
                                overflowY: "scroll",
                                "&::-webkit-scrollbar": {
                                    display: "none", // Hide the scrollbar for Webkit browsers (Chrome, Safari)
                                },
                                "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
                                "scrollbar-width": "none",

                            }}>
                                <TableHead stickyHeader>
                                    <TableRow sx={{ textAlign: "center" }}>
                                        <TableCell
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                p: "6px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Sr No.
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                p: "6px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Name
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                p: "6px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Patient ID
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                p: "6px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Invoice Id
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                p: "6px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Amount
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                p: "6px",
                                                borderTopRightRadius: '20px',
                                                borderBottomRightRadius: '20px',
                                                textAlign: "center",
                                            }}
                                        >
                                            Time
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                p: "6px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Status
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {isLoading && (
                                        <TableRow>
                                            <TableCell colSpan={7} align="center">
                                                <Loader />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {collectionArray?.payload?.invoices?.map((invoice, index) => (
                                        <TableRow key={index} sx={{
                                            ...tableRowLab, borderBottom: '8px solid white', borderTop: index === 0 ? "2px solid white" : ' ',
                                            "&:hover": {
                                                transform: "scale(1.001)",
                                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "20px !important",
                                            },
                                        }} >
                                            <TableCell sx={{ ...tableCellLab, paddingY: "8px", borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px" }}>
                                                <Typography
                                                    variant="body_Grey1"
                                                // color={
                                                //     invoice?.grand_total < 0
                                                //         ? "neutral.textDanger"
                                                //         : "text.black"
                                                // }
                                                >
                                                    {index + 1}
                                                </Typography>
                                            </TableCell>

                                            <TableCell sx={tableCellLab}>
                                                <Typography
                                                    // color={
                                                    //     invoice?.grand_total < 0
                                                    //         ? "neutral.textDanger"
                                                    //         : "text.black"
                                                    // }
                                                    variant="heading2"
                                                >
                                                    {" "}
                                                    {invoice.name}
                                                </Typography>
                                                {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <MobileScreenShareRoundedIcon sx={{ height: "12px", width: "12px" }} />
                <Typography variant="body2_text">
                  {" "}
                  {"1234567890"}
                </Typography>
              </Box> */}
                                            </TableCell>

                                            <TableCell sx={tableCellLab}>
                                                <Typography
                                                    // color={
                                                    //     invoice?.grand_total < 0
                                                    //         ? "neutral.textDanger"
                                                    //         : "text.black"
                                                    // }
                                                    variant="body_Grey1"
                                                >
                                                    {invoice?.patient_id ? invoice?.patient_id : "-"}
                                                </Typography>
                                            </TableCell>

                                            <TableCell sx={tableCellLab}>
                                                <Typography
                                                    // color={
                                                    //     invoice?.grand_total < 0
                                                    //         ? "neutral.textDanger"
                                                    //         : "text.black"
                                                    // }
                                                    variant="body_Grey1"
                                                >
                                                    {invoice?.id}
                                                </Typography>
                                            </TableCell>

                                            <TableCell sx={tableCellLab}>
                                                <Typography
                                                    // color ={
                                                    //     invoice?.grand_total < 0
                                                    //         ? "neutral.textDanger"
                                                    //         : "text.black"
                                                    // }
                                                    variant="body_Grey1"
                                                >
                                                    {" "}
                                                    {invoice.grand_total < 0 ? (invoice.grand_total) : (-invoice.grand_total)}
                                                </Typography>
                                            </TableCell>

                                            <TableCell sx={{
                                                ...tableCellLab,
                                                // borderTopRightRadius: '20px',
                                                // borderBottomRightRadius: '20px',
                                            }}>
                                                <Typography
                                                    // color={
                                                    //     invoice?.grand_total < 0
                                                    //         ? "neutral.textDanger"
                                                    //         : "text.black"
                                                    // }
                                                    variant="body_Grey1"
                                                >
                                                    {invoice?.generated_time}
                                                </Typography>
                                            </TableCell>

                                            <TableCell sx={{
                                                ...tableCellLab,
                                                borderTopRightRadius: '20px',
                                                borderBottomRightRadius: '20px',
                                            }}>
                                                <Typography
                                                    color={
                                                        invoice?.status === 'cancelled'
                                                            ? "neutral.textDanger"
                                                            : "#FF8D1E"
                                                    }
                                                    variant="heading2"

                                                >
                                                    {invoice?.status}
                                                </Typography>
                                            </TableCell>


                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                        ) : (

                            <Box sx={{ display: 'flex', justifyContent: "center", flexDirection: 'column', width: '30%', margin: 'auto' }}>

                                <Box sx={{ margin: "auto", width: '40%' }}>
                                    <img src={not_found} alt="" width={'100%'} sx={{ margin: 'auto' }} />
                                </Box>
                                <Typography align="center" fontSize={'17px'} color={"crimson"}>   No refund invoices found for selected date</Typography>
                            </Box>

                        )}
                    </TableContainer>
                </Grid>
            </Grid>


        </Box>
    );
};

export default RefundInvoices;
