import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getSupplierDetails } from "../connections/getApi";
import { setError, setLoading } from "../redux/slices/pharmacySlice";
const UseFetchSupplierDetails = (id) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ["getSupplierDetails", id],
    queryFn: () => getSupplierDetails(id),
    enabled: !!id,
    onError: () => {
      dispatch(setError(true));
      dispatch(setLoading(false));
    },
    onSettled: () => {
      dispatch(setLoading(false));
    },
    // staleTime: Infinity, // Keep the data fresh indefinitely
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
  });
};

export default UseFetchSupplierDetails;
