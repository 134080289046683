import React, { useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import { headingStyle } from "../../style/LapPageStyle";
import ReturnBill from "../Pharmacy/ReturnBill";
import NewBill from "../Pharmacy/NewBill";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

const NewBillPharmacy = ({ onClose }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const closeModalOnSuccess = () => {
    onClose();
  };
  return (
    <Box sx={{ width: "100%" }}>


      <Box display="flex" justifyContent="space-between" alignItems="center">

        <Box></Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>

          <Box sx={{ marginTop: '0px' }}>
            <ReceiptLongIcon
              sx={{
                color: "neutral.navyBlue",
                marginBottom: "4px",
                marginRight: "8px",
              }}
            />
          </Box>
          <Typography
            variant="heading1"
            fontWeight={600}
            gutterBottom
            sx={{ ...headingStyle }}
          >
            Bill
          </Typography>
        </Box>



        <IconButton sx={{ marginBottom: "2rem" }}
          onClick={onClose}
        >
          <CloseIcon sx={{ color: 'red' }} size="small" color="red" />
        </IconButton>
      </Box>

      {/* Tabs Navigation */}
      <Tabs
        value={tabIndex}
        onChange={(_, newIndex) => setTabIndex(newIndex)}
        variant="fullWidth"
      >
        <Tab label="New Bill"
          iconPosition="start"
          sx={{
            fontFamily: "Poppins, sans-serif", textTransform: "capitalize", fontWeight: 800,
            color: "gray", // Default color
            "&.Mui-selected": { color: "black" },
          }}
        />
        <Tab label="Return Bill"
          sx={{
            fontFamily: "Poppins, sans-serif", textTransform: "capitalize", fontWeight: 800,
            color: "gray", // Default color
            "&.Mui-selected": { color: "black" },
          }}
        />
      </Tabs>

      {/* Tab Content */}
      <Box sx={{ p: 3 }}>
        {tabIndex === 0 && < NewBill onSuccess={closeModalOnSuccess} />}
        {tabIndex === 1 && <ReturnBill onSuccess={closeModalOnSuccess} />}
      </Box>
    </Box>
  );
};

export default NewBillPharmacy;
