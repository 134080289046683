import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
// import PastBillsPharmacy from "./PastBillsPharmacy";
import PharmacyTodaysCollection from "../Pharmacy/PharmacyTodaysCollection";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { headingStyle } from "../../style/LapPageStyle";
import RefundInvoices from "../Pharmacy/RefundInvoices";
import default_collection from '../../assets/default_collection.svg';
import active_refund from '../../assets/active_refund.svg';
import default_refund from '../../assets/default_refund.svg';
import rupee from '../../assets/rupee.svg';



const TotalCollection = ({ onClose }) => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Box sx={{ width: "100%" }}>


      <Box display="flex" justifyContent="space-between" alignItems="center">

        <Box></Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>

          <Box sx={{ marginTop: '0px' }}>
            <CurrencyRupeeIcon sx={{ width: '28px', height: '24px', marginRight: "8px", marginTop: '0px', color: '#0067FF' }} />
          </Box>
          <Typography
            variant="heading1"
            fontWeight={600}
            gutterBottom
            sx={{ ...headingStyle }}
          >
            Payments
          </Typography>
        </Box>



        <IconButton sx={{ marginBottom: "2rem" }}
          onClick={onClose}
        >
          <CloseIcon sx={{ color: 'red' }} size="small" color="red" />
        </IconButton>
      </Box>

      {/* Tabs Navigation */}
      <Tabs
        value={tabIndex}
        onChange={(_, newIndex) => setTabIndex(newIndex)}
        variant="fullWidth"
      >
        <Tab label="Collection"
          icon={
            <img
              src={tabIndex === 0 ? rupee : default_collection}
              alt="Collection"
              style={{ width: 26, height: 26, marginBottom: 0 }}
            />
          }
          iconPosition="start"
          sx={{
            fontFamily: "Poppins, sans-serif", textTransform: "capitalize", fontWeight: 800,
            color: "gray", // Default color
            "&.Mui-selected": { color: "black" },
          }}
        />
        <Tab label="Refunds"
          icon={
            <img
              src={tabIndex === 1 ? active_refund : default_refund}
              alt="Collection"
              style={{ width: 26, height: 26, marginBottom: 0 }}
            />
          }
          iconPosition="start"
          sx={{
            fontFamily: "Poppins, sans-serif", textTransform: "capitalize", fontWeight: 800,
            color: "gray", // Default color
            "&.Mui-selected": { color: "black" },
          }}
        />
      </Tabs>

      {/* Tab Content */}
      <Box sx={{ p: 3 }}>
        {tabIndex === 0 && <PharmacyTodaysCollection />}
        {tabIndex === 1 && <RefundInvoices />}
      </Box>
    </Box>
  );
};

export default TotalCollection;
