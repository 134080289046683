import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  Button,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
  Modal,
  Skeleton,
  InputAdornment,
} from "@mui/material";
import {
  headingStyle,
  tableRowLab,
  xBtn,
} from "../../style/LapPageStyle";
import CloseIcon from "@mui/icons-material/Close";
import {
  primaryBtn,

} from "../../style/AppointmentStyle";
import pharmacy_icon from '../../assets/pharmacy_icon.svg'
import { autoFocusOffWithOutBorder, drTableRowBlueBg } from "../../style/PatinetFormStyle";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import updownar from "../../assets/updownar.svg";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { DeleteForeverRounded } from "@mui/icons-material";
import json_icon from '../../assets/json_icon.svg';
import csv_icon from '../../assets/csv_icon.svg';
import CustomModal from "../CustomModal/CustomModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditMedicines from "./EditMedicines";
import NewMedicines from "./NewMedicines";
import medicines_icon from '../../assets/medicine.svg'
import MedicinesDetails from "./MedicinesDetails";
import UseFetchMedicineAdmin from "../../helper/UseFetchMedicineAdmin";
import { primaryBtnBlue } from "../../style/PatientDetailsStyle";
import { Link, useNavigate } from "react-router-dom";
import { generateCSV, handleDownloadCsvMedicine, handleShowJson, formattedDate } from '../../utils/constantFunctions'
import { showToast } from "../../utils/toastUtil";
import { deleteMedicine } from "../../connections/deleteApi";
import MedicineDetailsPharmacy from "./MedicineDetailsPharmacy";
import useDebounce from "../../hooks/UseDebounce";
import SearchIcon from '@mui/icons-material/Search';

const PharmacyAdmin = ({ onClose }) => {

  // console.log(medicines);
  const navigate = useNavigate();

  const [filterModal, setFilterModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [editPurchaseOrderModal, setEditPurchaseOrderModal] = useState(false);
  const [newPurchaseOrderModal, setNewPurchaseOrderModal] = useState(false);
  const [page, setPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [id, setId] = useState(null);
  const [lastPage, setLastPage] = useState(true)
  const [medicineSearchInput, setMedicineSearchInput] = useState(null);

  //  console.log(medicineList?.payload?.medicines);
  const handleMedicineChange = (e) => {
    e.preventDefault();
    setMedicineSearchInput(e?.target?.value);
  };

  const debouncedInputMedicine = useDebounce(medicineSearchInput, 1000);


  const { data: medicineList, refetch, isLoading } = UseFetchMedicineAdmin(page, itemPerPage, debouncedInputMedicine);


  //  console.log(medicineList?.payload?.medicines);



  const totalRecords = medicineList?.meta?.total_count || 0;
  const totalPage = Math.ceil(totalRecords / itemPerPage);

  const handlePrevious = () => {
    if (page > 1) setPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (page < totalPage) setPage((prev) => prev + 1);
  };

  const handleLast = (page) => {
    setPage(page);
    setLastPage(!lastPage)
  }

  // useEffect(() => {
  //   refetch(page, itemPerPage)
  // }, [page, itemPerPage]);

  const handleDelete = async (id) => {
    setDeleteModal(false);
    const res = await deleteMedicine(id);
    //  console.log(res);

    showToast(res?.status?.message || res?.status?.status, res?.status?.status);
    if (res?.status?.status) {
      refetch(page, itemPerPage);
    }
  }


  return (
    <>
      <Box sx={{ width: "100%", display: 'flex', height: "90vh", flexDirection: 'column', justifyContent: "space-between" }}>


        {/* //modals for delete and filter */}
        <Modal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          sx={{
            position: 'absolute',
            top: '25%',
            // left:'50%',
            width: "450px",
            // height:"400px",
            margin: 'auto',
            //  display: "flex",
            //  alignItems: "flex-start",
            //  justifyContent: "center",
            //  overflow: "auto",
            borderRadius: '20px',
          }}
        >

          <Box
            sx={{
              mt: 2,
              bgcolor: "background.paper",
              borderRadius: 3.6,
              boxShadow: 24,
              p: 4.5,
              "&:focus": {
                outline: "none",
              },
            }}
          >

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography textAlign={'center'}>Are you sure?</Typography>
              <Typography textAlign={'center'}>You want to delete these selected purchase orders?</Typography>

            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'flex', gap: '25px', width: 'max-content', margin: 'auto', marginTop: "40px" }}>
              <Button onClick={() => handleDelete(id)} sx={{
                backgroundColor: "#FF392B", color: "white", border: '', width: "150px", borderRadius: "5px", fontSize: "12px", textTransform: 'capitalize', '&:hover': {
                  backgroundColor: "red",
                }
              }}>Delete</Button>
              <Button onClick={() => setDeleteModal(false)} sx={{ backgroundColor: "white", color: "black", border: '2px solid #FF392B', width: "150px", borderRadius: "5px", fontSize: "12px", textTransform: 'capitalize' }}>Cancel</Button>
            </Box>

          </Box>
        </Modal>

        <Modal
          open={filterModal}
          onClose={() => setFilterModal(false)}
          sx={{
            position: 'absolute',
            top: '14%',
            // left:'50%',
            width: "340px",
            // height:"400px",
            margin: 'auto',
            //  display: "flex",
            //  alignItems: "flex-start",
            //  justifyContent: "center",
            //  overflow: "auto",
            borderRadius: '20px',
          }}
        >

          <Box
            sx={{
              mt: 2,
              bgcolor: "background.paper",
              borderRadius: '20px',
              boxShadow: 24,
              p: 1,
              "&:focus": {
                outline: "none",
              },


            }}
          >

            <Grid container spacing={2} sx={{ padding: '9px' }}>
              <Grid item xs={12}>
                <Typography variant="subTitle116px" fontWeight={'600'} textAlign={'center'} margin={'auto'}>Filters</Typography>
              </Grid>



              {/* <Grid item xs={12}>
        <Typography  variant="subTitle116px" >Suppliers</Typography>
      </Grid> */}

              {/* Filter Selection */}
              <Grid item xs={12}>

                <Select fullWidth defaultValue="" placeholder="Select Filter" sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Ensure border remains none on focus
                  },
                  "& .MuiSelect-select": {
                    padding: "9px",
                    borderRadius: "15px",
                    backgroundColor: "#F7F7FF",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                  }
                }}

                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <span style={{ color: "text.default_grey" }}>
                          Select filter
                        </span>
                      );
                    }
                    return selected === "filter1"
                      ? "filter2"
                      : selected === "filter3"
                        ? "filter3"
                        : "select filter";
                  }}
                >
                  <MenuItem value="filter1">Filter 1</MenuItem>
                  <MenuItem value="filter2">Filter 2</MenuItem>
                  <MenuItem value="filter3">Filter 3</MenuItem>
                </Select>
              </Grid>

              {/* Purchase Date */}
              <Grid item xs={12}>
                <Typography variant="subTitle116px">Purchase Date</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="From"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="To"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Department Selection */}
              <Grid item xs={12}>
                <Typography variant="subTitle116px">Department</Typography>
                <Select fullWidth defaultValue="" sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Ensure border remains none on focus
                  },
                  "& .MuiSelect-select": {
                    padding: "9px",
                    borderRadius: "15px",
                    backgroundColor: "#F7F7FF",
                  },
                  "&:hover": {
                    boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                  }
                }}>
                  <MenuItem value="dept1">Department 1</MenuItem>
                  <MenuItem value="dept2">Department 2</MenuItem>
                  <MenuItem value="dept3">Department 3</MenuItem>
                </Select>
              </Grid>

              {/* Created At */}
              <Grid item xs={12}>
                <Typography variant="subTitle116px">Created At</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="From"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={autoFocusOffWithOutBorder}
                  label="To"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              {/* Buttons */}
              <Grid item xs={6}>
                <Button fullWidth variant="subTitle116px16px" sx={{ ...primaryBtnBlue, color: 'white' }}>Apply</Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={() => setFilterModal(false)} fullWidth variant="subTitle116px16px" sx={{ ...primaryBtn, color: 'white', border: 'none' }}>Clear Filters</Button>
              </Grid>
            </Grid>



          </Box>
        </Modal>

        <Box sx={{
          overflowY: 'auto', maxHeight: "80vh", '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}  >

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
          >
            <Box></Box>


            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }} >
              <img src={pharmacy_icon} alt=' ' sx={{ color: 'neutral.navyBlue', marginBottom: "4px", marginRight: '8px' }} />
              <Typography
                variant="heading_Large"
                fontWeight={700}
                gutterBottom
                sx={headingStyle}
              >
                Pharmacy
              </Typography>
            </Box>
            <IconButton sx={{ marginBottom: "r0em" }} onClick={onClose}>
              <CloseIcon size="small" sx={{ color: "red" }} />
            </IconButton>
          </Box>

          <Box
            mt={-1}
            mb={-1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
            paddingX="25px"
          >
            <Box>

              <Box sx={{ marginLeft: 4 }}>
                <TextField
                  sx={{ ...autoFocusOffWithOutBorder }}
                  type="text"
                  className="form-control"
                  placeholder="Search Medicine "
                  value={medicineSearchInput}
                  onChange={handleMedicineChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              {/* <AddRoundedIcon onClick={()=>setNewPurchaseOrderModal(true)} sx={{backgroundColor:"#FFE7DC" ,p:'4px',  width:"20px", color:"#FF8D1E" , borderRadius:"8px" ,cursor:"pointer"}}   /> */}

            </Box>
            {/* <Box > <AddRoundedIcon onClick={()=>setNewPurchaseOrderModal(true)} sx={{backgroundColor:"#FFE7DC" ,p:'4px',  width:"20px", color:"#FF8D1E" , borderRadius:"8px" ,cursor:"pointer"}}   /></Box> */}


            <Box onClick={() => setFilterModal(true)} sx={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
              {/* <IconButton  >
          <TuneRoundedIcon size="small"  sx={{color:"#0067FF"}}/>  
        </IconButton>
        <Typography>
         Filters
        </Typography>  */}
            </Box>
          </Box>

          <Grid item xs={12} >
            <TableContainer sx={{ mt: 4, width: "100%" }}>

              <Table sx={{ width: "100%" }}>
                <TableHead stickyHeader >
                  <TableRow sx={{ textAlign: "center" }}>
                    {/* <TableCell
                variant="body1_text_bold" 
                  sx={{
                    borderTopLeftRadius:"20px",
                    
                  color: 'neutral.black',
                    p: "6px", 
                    textAlign: "center",
                  }} 
                > 
                  <Checkbox/>
                </TableCell> */}

                    <TableCell
                      variant="body1_text_bold"
                      sx={{

                        color: 'neutral.black',
                        p: "6px",
                        textAlign: "center",
                      }}
                    >
                      ID
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "6px",
                        //  paddingX:"4px",
                        textAlign: "center",
                        width: "200px",
                      }}
                    >
                      Name
                    </TableCell>

                    <TableCell

                      variant="body1_text_bold"
                      sx={{
                        width: "130px",
                        paddingRight: "30px",
                        color: 'neutral.black',
                        p: "6px",
                        textAlign: "center",
                      }}
                    >
                      Company
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "6px",
                        textAlign: "center",
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "6px",
                        textAlign: "center",
                      }}
                    >
                      Discount
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "6px",
                        textAlign: "center",
                      }}
                    >
                      Packing
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "6px",
                        textAlign: "center",
                      }}
                    >
                      Stock left
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "6px",
                        textAlign: "center",
                      }}
                    >
                      Expiry
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "6px",
                        textAlign: "center",
                      }}
                    >
                      Form Type
                    </TableCell>

                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "6px",
                        textAlign: "center",
                      }}
                    >
                      Medicine Salt
                    </TableCell>
                    <TableCell
                      variant="body1_text_bold"
                      sx={{
                        color: 'neutral.black',
                        p: "6px",
                        textAlign: "center",
                      }}
                    >
                      Actions
                    </TableCell>



                  </TableRow>
                </TableHead>


                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={12} sx={{ p: 0 }}>
                        <Box sx={{ rowGap: 1.2, display: 'flex', flexDirection: 'column' }}>
                          <Skeleton
                            variant="rectangular"
                            sx={{
                              width: "100%",
                              height: "50px", // Adjust to match your table row height
                              backgroundColor: "#f0f0f0", // Light grey color
                            }}
                          />

                          <Skeleton
                            variant="rectangular"
                            sx={{
                              width: "100%",
                              height: "50px", // Adjust to match your table row height
                              backgroundColor: "#f0f0f0", // Light grey color
                            }}
                          />

                          <Skeleton
                            variant="rectangular"
                            sx={{
                              width: "100%",
                              height: "50px", // Adjust to match your table row height
                              backgroundColor: "#f0f0f0", // Light grey color
                            }}
                          />

                          <Skeleton
                            variant="rectangular"
                            sx={{
                              width: "100%",
                              height: "50px", // Adjust to match your table row height
                              backgroundColor: "#f0f0f0", // Light grey color
                            }}
                          />

                          <Skeleton
                            variant="rectangular"
                            sx={{
                              width: "100%",
                              height: "50px", // Adjust to match your table row height
                              backgroundColor: "#f0f0f0", // Light grey color
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
                  : (

                    <TableBody>


                      {medicineList?.payload?.medicines?.map((medicine, index) =>

                        <TableRow sx={{ ...tableRowLab, textAlign: "center", backgroundColor: "#F7F7FF", borderBottom: "8px solid white", borderTop: "2px solid white" }}>
                          {/* <TableCell
                variant="subTitle116px" 
                  sx={{
                    borderTopLeftRadius:"20px",
                    borderBottomLeftRadius:"20px",
                  color: 'neutral.black',
                    p: "6px", 
                    textAlign: "center",
                  }}
                > 
                  <Checkbox/> 
                </TableCell> */}
                          <TableCell

                            sx={{
                              color: 'neutral.black',
                              p: "6px",
                              textAlign: "center",
                              borderTopLeftRadius: "20px",
                              borderBottomLeftRadius: "20px",
                            }}
                          >
                            <Typography variant="subTitle116px"> {medicine?.id}   </Typography>

                          </TableCell>
                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "6px",
                              //  paddingX:"4px",
                              textAlign: "center",
                              width: "200px",
                            }}
                          >

                            <Link> <Typography variant="subTitle116px">
                              {medicine?.name}
                            </Typography>
                            </Link>
                          </TableCell>

                          <TableCell

                            variant="body1_text_bold"
                            sx={{
                              width: "130px",
                              paddingRight: "30px",
                              color: 'neutral.black',
                              p: "6px",
                              textAlign: "center",
                            }}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography variant="subTitle116px">{medicine?.company}</Typography>

                            </Box>
                          </TableCell>
                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "6px",
                              textAlign: "center",
                            }}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography variant="subTitle116px">  {medicine?.flat_price && <Typography variant="span"> &#8377; </Typography>}  {" "} {medicine?.flat_price}</Typography>

                            </Box>
                          </TableCell>
                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "6px",
                              textAlign: "center",
                            }}
                          >

                            <Typography variant="subTitle116px">{medicine?.discount}</Typography>

                          </TableCell>

                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "6px",
                              textAlign: "center",
                            }}
                          >

                            <Typography variant="subTitle116px">  {medicine?.packing}  </Typography>
                          </TableCell>
                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "6px",
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="subTitle116px"> {medicine?.total_stock_left}  </Typography>
                          </TableCell>

                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "6px",
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="subTitle116px">  {formattedDate(medicine?.expiry_date)} </Typography>
                          </TableCell>

                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "6px",
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="subTitle116px"> {medicine?.form_type}</Typography>
                          </TableCell>

                          <TableCell
                            variant="body1_text_bold"
                            sx={{
                              color: 'neutral.black',
                              p: "6px",
                              textAlign: "center",
                            }}
                          >

                            <Link>    <Typography variant="subTitle116px" > {medicine?.medicine_salt?.name} </Typography> </Link>
                          </TableCell>

                          <TableCell
                            sx={{
                              borderTopRightRadius: "20px",
                              borderBottomRightRadius: '20px',
                            }}

                          >
                            <Box sx={{ display: 'flex', gap: '8px', marginRight: "-8px" }}>
                              <RemoveRedEyeRoundedIcon onClick={() => {
                                setDetailsModal(true)
                                setId(medicine?.id)
                              }} sx={{ backgroundColor: "#0067FF", p: '2px', width: "20px", color: 'white', borderRadius: "8px", cursor: "pointer" }} />

                              {/*
                <DeleteForeverRounded onClick={()=>{
                  setDeleteModal(true)
                  setId(medicine?.id); 
                  }} sx={{backgroundColor:"#FF392B" ,p:'2px',  width:"18px", color:"white" , borderRadius:"8px" ,cursor:"pointer"}}/> */}

                            </Box>


                          </TableCell>

                        </TableRow>

                      )
                      }
                    </TableBody>
                  )}
              </Table>
            </TableContainer>


          </Grid>
        </Box>


        <Box
          container
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ zIndex: "10" }}
          lg={12}
          marginBottom={'5px'}
          paddingX={'20px'}
        >

          <Grid item>
            <Box sx={{ display: 'flex', gap: '8px' }}>

              <Box sx={{ cursor: "pointer" }} onClick={() =>
                handleDownloadCsvMedicine(medicineList?.payload?.medicines)
              }>
                <img src={csv_icon} alt="" width={'28px'} sx={{ cursor: "pointer" }} /> </Box>

              <Box sx={{ cursor: "pointer" }} onClick={() =>
                handleShowJson(medicineList?.payload?.medicines)
              }>  <img src={json_icon} alt="" sx={{ cursor: "pointer" }} /> </Box>

              {/* <img src={} alt=""/> */}

            </Box>
          </Grid>

          <Grid item sx={{ display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center' }} >

            <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center' }}>
              <Typography>

                Displaying  {page} {' '} - {' '} {totalPage} of {medicineList?.meta?.total_count} in total
              </Typography>

              {/* pagination buttons  start  */}

              <Box
                // mt={2}
                gap={1}
                justifyContent="end"
                display="flex"
                alignItems="center"
              >
                <Button
                  onClick={handlePrevious}
                  startIcon={<ArrowBackIcon sx={{ fontSize: "14px !important" }} />}
                  sx={{
                    fontSize: "12px",
                    textTransform: "capitalize",
                    border: 1 ? "none" : "1px solid rgba(0, 0, 0, 0.2)",
                    color: page === 1 ? "grey" : "#336cfb",
                  }}

                >
                  Previous
                </Button>


                <Button

                  sx={{
                    fontSize: "12px",
                    minWidth: "24px",
                    minHeight: "24px",
                    padding: "2px",
                    borderRadius: "4px",
                    border: 2 ? "none" : "1px solid rgba(0, 0, 0, 0.2)",
                    // color: page === currentPage ? "black" : "inherit",
                    // backgroundColor:
                    //   page === currentPage ? "rgba(0, 0, 0, 0.1)" : "inherit",
                  }}

                >
                  {page}
                </Button>

                <Button
                  onClick={handleNext}
                  endIcon={<ArrowForwardIcon sx={{ fontSize: "14px !important" }} />}
                  sx={{
                    fontSize: "12px",
                    textTransform: "capitalize",
                    border:
                      1
                        ? "none"
                        : "1px solid rgba(0, 0, 0, 0.2)",
                    color: page === totalPage ? "grey" : "#336cfb",
                  }}

                >
                  Next
                </Button>

                <Button
                  onClick={
                    () => handleLast(lastPage ? totalPage : 1)
                  }
                  //   endIcon={
                  //     <ArrowForwardIcon sx={{ fontSize: "14px !important",   transform: lastPage ? "" : "rotate(190deg)", }} />  
                  // }
                  sx={{
                    fontSize: "12px",
                    textTransform: "capitalize",
                    // backgroundColor:'silver',
                    // border: "1px solid rgba(0, 0, 0, 0.2)",
                    // color: page===totalPage? "grey" : "#336cfb",
                  }}
                >

                  {lastPage ? "Last >>" : "<< First"}
                </Button>

              </Box>

              {/* pagination buttons  end  */}
            </Box>

          </Grid>


        </Box>
      </Box>
      <CustomModal
        customStyles={{ border: "none", minWidth: "85%" }}
        open={detailsModal} setDetailsModal
        onClose={() => setDetailsModal(false)}
      >
        <MedicineDetailsPharmacy onClose={() => setDetailsModal(false)} id={id} refetch={refetch} page={page} itemPerPage={itemPerPage} />
      </CustomModal>

    </>
  );
};

export default PharmacyAdmin;
