import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getMedicineDetails } from "../connections/getApi";
import { setError, setLoading } from "../redux/slices/pharmacySlice";
const UseFetchMedicineDetailsAdmin = (id) => {
  const dispatch = useDispatch();
  return useQuery({
    queryKey: ["getMedicineListAdmin", id],
    queryFn: () => getMedicineDetails(id),
    enabled: !!id,
    onError: () => {
      dispatch(setError(true));
      dispatch(setLoading(false));
    },
    onSettled: () => {
      dispatch(setLoading(false));
    },
    // staleTime: Infinity, // Keep the data fresh indefinitely
    // Cache the data for 1 hour
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    // Don't refetch on component mount
  });
};

export default UseFetchMedicineDetailsAdmin;
