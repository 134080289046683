import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  appointmentTableCell,
  appointmentTableHead,
  titleStyle,
} from "../../style/FutureAppointmentStatusStyle";
import UseFetchFutureAppointment from "../../helper/UseFetchFutureAppointment";
import Loader from "../UI/Loader";
import { headingStyle } from "../../style/LapPageStyle";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import not_found from "../../assets/not_found.svg";

const FutureAppointmentStatus = ({ onClose }) => {
  function getDayOfWeek(dateString) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const date = new Date(dateString);

    const dayIndex = date.getDay();

    return daysOfWeek[dayIndex];
  }
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const getFutureAppointmentQuery = UseFetchFutureAppointment(
    getCurrentDate(),
    currentPage,
    perPage
  );
  const { data, isLoading, refetch } = getFutureAppointmentQuery;
  useEffect(() => {
    refetch(getCurrentDate(), currentPage, perPage);
  }, [currentPage, perPage, refetch]);
  const totalRecords = data?.status?.message?.total_count || 0;
  const totalPages = Math.ceil(totalRecords / perPage);

  const handlePage = (val) => {
    setCurrentPage((prevPage) => {
      const newPage = prevPage + val;
      if (newPage < 1 || newPage > totalPages) return prevPage;
      return newPage;
    });
  };
  return (
    <Box p={2}>


      {/* <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="body"
          fontWeight={700}
          gutterBottom
          sx={titleStyle}
          fontFamily={'sans-serif'}
        >
          Future Appointments Status
        </Typography>
        <IconButton sx={{ marginBottom: "2rem" }} onClick={onClose}>
          <CloseIcon size="small" sx={{ color: '#FF392B' }} />
        </IconButton>
      </Box> */}


      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="sticky"
        sx={{ marginTop: '-10px', alignItems: 'center' }}
      >
        <Box></Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "9px",
          }}
        >
          <CalendarMonthIcon sx={{ color: '#0067FF', marginBottom: 1 }} />
          <Typography
            variant="heading_Large"
            fontWeight={700}
            gutterBottom
            sx={headingStyle}
          >
            Future appointments
          </Typography>
        </Box>
        <IconButton sx={{ marginBottom: "0rem" }} onClick={onClose}>
          <CloseIcon size="small" sx={{ color: "red" }} />
        </IconButton>
      </Box>

      <TableContainer >
        {data?.payload?.length > 0 ? (
          <Table>
            <TableHead >
              <TableRow>
                <TableCell sx={appointmentTableCell} align="center">
                  Date
                </TableCell>
                <TableCell sx={appointmentTableCell} align="center">
                  Old
                </TableCell>
                <TableCell sx={appointmentTableCell} align="center">
                  New
                </TableCell>
                <TableCell sx={appointmentTableCell} align="center">
                  Review
                </TableCell>
                <TableCell sx={appointmentTableCell} align="center">
                  Complementary
                </TableCell>
                <TableCell sx={{
                  ...appointmentTableCell,
                  width: 300,
                }} align="center">
                  Available Slots
                </TableCell>

                <TableCell sx={{ ...appointmentTableCell, pr: 4 }} align="center">
                  Appointment Count
                </TableCell>

              </TableRow>

            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {data?.payload?.map((row, index) => (
                  <TableRow key={row.date} sx={{
                    backgroundColor: '#F7F7FF',
                    borderBottom: '8px solid white',
                    borderTop: index === 0 ? '2px solid white' : '',

                  }}>
                    <TableCell sx={{ fontSize: "14px", borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }} align="center">
                      <Typography sx={{ fontSize: "14px" }}>
                        {row.visit_date}
                      </Typography>
                      <Typography variant="caption" color="textSecondary"
                        sx={{ color: '#0067FF' }}>
                        {getDayOfWeek(row.visit_date)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }} align="center">
                      {row.old}
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }} align="center">
                      {row.new}
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }} align="center">
                      {row.review}
                    </TableCell>
                    <TableCell sx={{ fontSize: "14px" }} align="center">
                      {row.complementary}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px", display: "flex", alignmentBaseline: 'center',
                        width: 300,
                      }}
                      align="center"
                    >
                      {Object.keys(row?.available_slots).map((label, index) => (
                        <Box
                          display={"flex"}
                          gap={"4px"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                        >
                          <Chip
                            key={index}
                            label={`${label === "total_slots" ? "Total slots" : label.replace(/_/g, " ")}`}
                            variant="outlined"
                            sx={{
                              marginRight: 0.3,
                              fontSize: "14px",
                              bgcolor: "#0067FF",
                              color: "white",
                              fontWeight: 100,
                              height: "fit-content",
                            }}
                          />
                          <Typography fontWeight={"500"} sx={{ textTransform: 'capitalize' }}>
                            {row?.available_slots[label]}
                          </Typography>
                        </Box>
                      ))}
                    </TableCell>
                    <TableCell sx={{
                      fontSize: "14px",
                      borderTopRightRadius: '20px', borderBottomRightRadius: '20px'
                    }} align="center">
                      {row?.appointments_count}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "30%",
              margin: "auto",
              marginTop: 10,
            }}
          >
            <Box sx={{ margin: "auto", width: "40%" }}>
              <img
                src={not_found}
                alt=""
                width={"100%"}
                sx={{ margin: "auto" }}
              />
            </Box>
            <Typography align="center" fontSize={"17px"} color={"crimson"}>
              {" "}
              No future appointments found
            </Typography>
          </Box>
        )}

      </TableContainer>

      {/* {"pagination for table data"} */}
      {data?.payload?.length > 0 &&
        <Box
          mt={2}
          gap={1}
          justifyContent="end"
          display="flex"
          alignItems="center"
        >
          <Button
            startIcon={<ArrowBackIcon sx={{ fontSize: "14px !important" }} />}
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              border: currentPage === 1 ? "none" : "1px solid rgba(0, 0, 0, 0.2)",
              color: currentPage === 1 ? "grey" : "#336cfb",
            }}
            disabled={currentPage === 1}
            onClick={() => handlePage(-1)}
          >
            Previous
          </Button>

          {Array.from({ length: totalPages }).map((_, pageIndex) => {
            const page = pageIndex + 1;
            return (
              <Button
                key={page}
                sx={{
                  fontSize: "12px",
                  minWidth: "24px",
                  minHeight: "24px",
                  padding: "2px",
                  borderRadius: "4px",
                  border:
                    page === currentPage
                      ? "none"
                      : "1px solid rgba(0, 0, 0, 0.2)",
                  color: page === currentPage ? "black" : "inherit",
                  backgroundColor:
                    page === currentPage ? "rgba(0, 0, 0, 0.1)" : "inherit",
                }}
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </Button>
            );
          })}

          <Button
            endIcon={<ArrowForwardIcon sx={{ fontSize: "14px !important" }} />}
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              border:
                currentPage === totalPages
                  ? "none"
                  : "1px solid rgba(0, 0, 0, 0.2)",
              color: currentPage === totalPages ? "grey" : "#336cfb",
            }}
            disabled={currentPage === totalPages}
            onClick={() => handlePage(1)}
          >
            Next
          </Button>
        </Box>
      }
    </Box>
  );
};

export default FutureAppointmentStatus;
