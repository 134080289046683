import { ThemeProvider } from "@emotion/react";
import "./App.css";
import ALLRoute from "./routing/Route";
import theme from "./theme/theme";
import { Provider } from "react-redux";
import store from "./redux/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastNotifier } from "./utils/toastUtil";
import { ToastContainer } from "react-toastify";
import { BaseUrlProvider } from "./connections/connectionUrl";
import { TourProvider, components, useTour } from '@reactour/tour'
import { Box } from "@mui/material";
import { IoClose } from 'react-icons/io5';  // Close icon
import { IoArrowBack, IoArrowForward } from 'react-icons/io5'; // Back & Forward icons
import { useEffect, useState } from "react";

const queryClient = new QueryClient();

function Close({ onClick }) {
  return (

    <button onClick={onClick} style={{ position: 'absolute', right: 10, top: 10, color: 'white', background: 'transparent', border: 'none' }}>
      <IoClose size={24} />
    </button>
  );
}

function Arrow({ inverted, disabled, onClick }) {
  const { currentStep, setIsOpen, steps, setCurrentStep } = useTour();

  const isLastStep = currentStep === steps.length - 1;
  const isFirstStep = currentStep === 0;
  return (
    <button
      onClick={isLastStep ? () => { setIsOpen(false); setCurrentStep(0); } : onClick}
      disabled={disabled}
      style={{ color: "white", background: "transparent", border: "none", cursor: disabled ? "not-allowed" : "pointer" }}
    >
      {inverted ? (isLastStep ? "" : "Next") : isFirstStep ? "" : (isLastStep ? "Finish" : "Previous")}
    </button>
  );
}


const steps = [
  {
    selector: '.step-one',
    content: (
      <>
        <strong>Switch easily between text fields</strong>
        <br />
        Use the <strong>TAB</strong>  button to switch between text fields no more scrolling.
      </>
    ),

    // title: 'Switch easily between text fields' 
  },
  {
    selector: '.step-two',
    content: (
      <>
        <strong>Keep Going!</strong>
        <br />
        Great job! Now, use the <strong>TAB</strong> key again to smoothly jump to the next input field.
      </>
    ),
    // title: 'Switch easily between text fields' 
  },
  {
    selector: '.step-three',
    content: (
      <>
        <strong>Write a treatment  effortlessly!</strong>
        <br />
        Enter the diagnosis, then press the <strong>TAB</strong> key to jump to the notes field effortlessly.
      </>
    ),
    // title: 'Switch easily between text fields' 
  },
  {
    selector: '.step-four',
    content: (
      <>
        <strong>Stay on Track!</strong>
        <br />
        write  notes, then press the <strong>TAB</strong> key to move to the self-notes field smoothly.
      </>
    ),
    title: 'Just a click away from adding medicine'
  },
  {
    selector: '.step-five',
    content: (
      <>
        <strong>Keep Going!</strong>
        <br />
        Type in the self-notes, then hit the <strong>TAB</strong> key to proceed to the special instructions field.
      </>
    ),
    title: 'Just a click away from adding descriptions'
  },
  {
    selector: '.step-six',
    content: (
      <>
        <strong>You're Doing Great! </strong> <br />
        Fill in special instructions by a click on above chips or  typing  in, then hit the <strong>TAB</strong> key to proceed to next  field.
      </>
    ),
    title: 'Just a click away from adding remarks'
  },
  {
    selector: '.step-seven',
    content: (
      <>
        <strong>Just a click away from adding medicine</strong> <br />
        Use the <strong>TAB</strong> button to add medicines. It will add a medicine and move you forward to the description text field.
      </>
    ),
    title: 'Just a click away from adding medicine'
  },

  {
    selector: '.step-eight',
    content: (
      <>
        <strong style={{ marginBottom: 10 }}>Just a click away from adding descriptions</strong>
        <br />
        <br />
        Use the <strong>TAB</strong> button to add descriptions. It will add a description and move you forward to the remarks text field.
      </>
    ),
    title: 'Just a click away from adding descriptions'
  },

  {
    selector: '.step-nine',
    content: (
      <>
        <strong>Just a click away from adding remarks</strong> <br />
        Use the <strong>TAB</strong> button to add remarks. It will add a remark and insert a new row.
      </>
    ),
    title: 'Just a click away from adding remarks'
  },

  {
    selector: '.step-ten',
    content: (
      <>
        <strong>Switch between sections easily</strong> <br />
        Move directly to <strong>Follow-Up</strong>. If no medicine is added, pressing the <strong>TAB</strong> key while on the (Medicine, Description, or Remarks) fields will shift the focus to Follow-Up.
      </>
    ),
    title: 'Switch between sections easily'
  },
  {
    selector: '.step-eleven',
    content: (
      <>
        <strong>Almost there!</strong> <br />
        Pressing the <strong>TAB</strong> button here will move you to the medical test text field.
      </>
    ),
    title: 'Switch between sections easily'
  },
  {
    selector: '.step-twelve',
    content: (
      <>
        <strong>Get Medical Test Recommendations at Your Fingertips</strong> <br />
        Use a <strong>COMMA (,)</strong> in the text field, and the recommendation box will open automatically.
        <br /><br />
        <strong>Hurray! 🎉 You've Completed the Tutorial!</strong> <br />
        You’re all set to get started!
      </>

    ),
    title: 'Get medical test recommendations at your fingertips'
  },
];



function App() {

  return (
    <Provider store={store}>
      <BaseUrlProvider>
        <TourProvider steps={steps}
          keyboardHandler={(e, clickProps) => {
            if (e.key === 'ArrowRight') {
              clickProps.setCurrentStep(
                Math.min(clickProps.currentStep + 1, clickProps.steps.length - 1)
              );
            }
            if (e.key === 'ArrowLeft') {
              clickProps.setCurrentStep(Math.max(clickProps.currentStep - 1, 0));
            }
            if (e.key === 'Escape') {
              clickProps.setCurrentStep(0);
              clickProps.setIsOpen(false);
            }
          }}
          // onBlur={() => console.log("Input field lost focus")}
          styles={{
            popover: (base) => ({
              ...base,
              backgroundColor: '#0067FF',
              color: 'white',
              // padding: 30,
              padding: '20px',
              borderRadius: '10px',
              minWidth: '350px',
              fontFamily: 'Outfit',
            }),
            mask: (base) => ({
              ...base,
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            }),

            // maskArea: (base) => ({
            //   ...base,
            //   paddingTop: 40,  // Y-axis (Top Padding)
            //   paddingBottom: 40, // Y-axis (Bottom Padding)
            //   paddingLeft: 0,   // No X-axis padding
            //   paddingRight: 0,  // No X-axis padding
            //   transition: 'all 0.3s ease', // Smooth transition
            // }),
          }}
          onClickMask={({ setIsOpen, setCurrentStep }) => {
            setCurrentStep(0);
            setIsOpen(false);
          }}
          components={{ Close, Arrow }}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <ALLRoute />
              <ToastNotifier />
              <ToastContainer />
            </ThemeProvider>
          </QueryClientProvider>
        </TourProvider>
      </BaseUrlProvider>
    </Provider>
  );
}

export default App;
