import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Box,
  TableContainer,
  Table,
  Typography,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  FormControl,
  TextField,
} from "@mui/material";
import CustomModal from "../CustomModal/CustomModal";
import AddNewReport from "../ModalChildren/AddNewReport";
import { dateFiled } from "../../style/AppointmentStyle";
import MobileScreenShareRoundedIcon from "@mui/icons-material/MobileScreenShareRounded";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Print } from "@mui/icons-material";
import { drTableRow } from "../../style/DoctorListComponent";
import {
  tableCellLab,
  tableHeadCell,
  tableRowLab,
} from "../../style/LapPageStyle";
import { getPatientPreviousReport } from "../../connections/getApi";
import ComponentToPrint from "./printContent";
import ReactToPrint from "react-to-print";
import updownar from "../../assets/updownar.svg";

const Completed = ({ data = [], setDateTest, testDate }) => {
  const [patients, setPatients] = useState(data);
  const [openModal, setOpenModal] = useState(false);
  const [cellData, setCellData] = useState({});
  const [visitDate, setVisitDate] = useState(
    testDate || new Date().toISOString().split("T")[0]
  );
  const printRef = useRef();
  const [printData, setPrintData] = useState({
    reportData: "",
    patientData: "",
  });
  const handlePreview = async (patient, modalFalse = false) => {
    try {
      const data = await getPatientPreviousReport(
        patient?.id,
        patient?.mobile,
        testDate
      );
      const testIdsString = data?.tests
        .map((test) => test.medical_test_id)
        .filter(Boolean)
        .join(",");
      const transformedData = data?.tests?.reduce((acc, obj) => {
        const dateKey = obj.created_date;

        // If the date already exists in the accumulator, push to the array
        if (acc[dateKey]) {
          acc[dateKey].push(obj);
        } else {
          // Otherwise, initialize with an array containing the first object
          acc[dateKey] = [obj];
        }

        return acc;
      }, {});
      // setCellData(data);
      setCellData((prev) => ({
        ...prev,
        id: patient?.id,
        report: transformedData,
        patient_info: data?.patient_info,
        TestIdS: testIdsString,
      }));
      setOpenModal(modalFalse ? false : true);
      return {
        testIdsString,
        tests: data?.tests,
        patientInfo: data?.patient_info,
      };
    } catch (error) {
      console.log(error);
    }
  };
  const handlePrint = async (item) => {
    const data = await handlePreview(item, true);

    setPrintData({
      reportData: data?.tests,
      patientData: data?.patientInfo,
    });
    if (data?.tests?.length > 0) {
      setTimeout(() => {
        document.getElementById("print-button").click();
        printRef.current.click();
      }, 500);
    }
  };
  const directPrintReport = () => {
    setOpenModal(true);
  };
  useEffect(() => {
    setVisitDate(setVisitDate);
    setPatients(data);
  }, [data, testDate]);

  return (
    <>
      <Grid
        container
        sm={12}
        md={12}
        marginBottom={"auto"}
        lg={12}
        mb={"50px"}
        sx={{ backgroundColor: "#F7F7FF" }}
      >
        <Grid item xs={11.5} mt={0} sx={{ margin: "auto" }}>
          <Grid
            container
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ mt: 2 }}
            lg={12}
            px={"10px"}
          >
            <Grid item>
              {/* <Typography variant="heading1">Completed Tests</Typography> */}
            </Grid>

            <Grid item>
              <FormControl variant="outlined" sx={{ width: "200px" }}>
                <TextField
                  defaultValue={new Date().toISOString().split("T")[0]}
                  type="date"
                  sx={dateFiled}
                  value={visitDate}
                  name="Date"
                  onChange={(e) => setDateTest(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>

          <TableContainer
            sx={{
              mt: 0,
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                display: "none", // Hide the scrollbar for Webkit browsers (Chrome, Safari)
              },
              "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
              "scrollbar-width": "none",
            }}
          >
            <Table
              sx={{
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  display: "none", // Hide the scrollbar for Webkit browsers (Chrome, Safari)
                },
                "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
                "scrollbar-width": "none", // Hide scrollbar for Firefox
              }}
            >
              <TableHead stickyHeader>
                <TableRow>
                  <TableCell
                    sx={{
                      tableHeadCell,
                      color: "neutral.black",
                      textAlign: "center",
                    }}
                  >
                    S NO{" "}
                  </TableCell>
                  <TableCell
                    sx={{
                      tableHeadCell,
                      color: "neutral.black",
                      textAlign: "center",
                    }}
                  >
                    Name{" "}
                  </TableCell>

                  <TableCell
                    sx={{
                      tableHeadCell,
                      color: "neutral.black",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    Patient ID{" "}
                  </TableCell>

                  <TableCell
                    sx={{
                      tableHeadCell,
                      color: "neutral.black",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    Invoice ID{" "}
                  </TableCell>

                  <TableCell
                    sx={{
                      tableHeadCell,
                      color: "neutral.black",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    Actions{" "}
                  </TableCell>

                  {/* <TableCell sx={{tableHeadCell , color:'neutral.black' , textAlign:'center'}}>Print</TableCell> */}
                </TableRow>
              </TableHead>

              <TableBody>
                {patients?.length === 0 && (
                  <TableCell
                    sx={{ textAlign: "center" }}
                    colSpan={5}
                    rowSpan={4}
                  >
                    <Typography>No Data</Typography>
                  </TableCell>
                )}
                {patients?.map((patient, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      ...tableRowLab,
                      backgroundColor: "white",
                      //  borderTop:'2px solid #F7F7FF' , borderBottom:"8px solid #F7F7FF" ,
                      borderBottom: "8px solid #F7F7FF",
                      borderTop: index === 0 ? "2px solid #F7F7FF" : "",
                      transition: "all 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.001)", // Slight scaling for the pop-up effect
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for outer glow
                        borderRadius: "20px !important",
                      },
                    }}
                  >
                    <TableCell
                      sx={{
                        p: "4px",
                        textAlign: "center",
                        borderTopLeftRadius: "20px",
                        borderBottomLeftRadius: "20px",
                      }}
                    >
                      <Typography variant="body_Grey1" color={"text.grey"}>
                        {index + 1}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        ...tableCellLab,
                        paddingY: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="heading2">
                        {" "}
                        {patient.name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <MobileScreenShareRoundedIcon
                          sx={{ height: "12px", width: "12px" }}
                        />
                        <Typography variant="body2_text">
                          {" "}
                          {patient?.mobile}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      <Typography variant="body_Grey1">
                        {patient.patient_uid}
                      </Typography>
                    </TableCell>

                    <TableCell sx={tableCellLab}>
                      <Typography variant="body_Grey1">
                        {patient?.invoice_uid}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        ...tableCellLab,
                        borderTopRightRadius: "20px",
                        borderBottomRightRadius: "20px",
                      }}
                    >
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <VisibilityOutlinedIcon
                          sx={{
                            p: "8px",
                            bgcolor: "neutral.navyBlue",
                            borderRadius: "8px",
                            color: "white",
                            height: "15px",
                            width: "15px",
                            cursor: "pointer",
                          }}
                          onClick={() => handlePreview(patient)}
                        />

                        <Print
                          sx={{
                            marginLeft: "8px",
                            p: "8px",
                            bgcolor: "#FF8D1E",
                            borderRadius: "8px",
                            color: "white",
                            height: "15px",
                            width: "15px",
                            cursor: "pointer",
                          }}
                          onClick={() => handlePrint(patient)}
                        />
                      </Box>
                    </TableCell>

                    {/* <TableCell sx={{...tableCellLab ,  borderTopRightRadius:"20px" , borderBottomRightRadius:"20px"} }> */}
                    {/* <ReactToPrint
                        onBeforeGetContent={() => handlePrint(patient)}
                        trigger={() => (
                        
                        )}
                        content={() => printRef.current}
                        pageStyle="@page { size: auto; margin: 20mm; }"
                        copyStyles={true}
                      /> */}
                    {/* <IconButton onClick={() => handlePrint(patient)}>
                        <Print
                          sx={{
                            p: "6px",
                            paddingX: "8px",
                            bgcolor: "neutral.navyBlue",
                            color:'white',
                            borderRadius: "8px",
                            height: "15px",
                            width: "15px",
                          }}
                        />
                      </IconButton> */}
                    {/* <IconButton onClick={() => handlePrint(patient)}>
                        <Print
                          sx={{
                            p: "6px",
                            paddingX: "8px",
                            bgcolor: "neutral.olive",
                            borderRadius: "45%",
                            height: "15px",
                            width: "15px",
                          }}
                        />
                      </IconButton> */}
                    {/* </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <CustomModal open={openModal} onClose={() => setOpenModal(false)}>
        <AddNewReport
          patientData={cellData?.patient_info}
          report={cellData?.report}
          onClose={() => setOpenModal(false)}
          handlePreview={handlePreview}
        />
      </CustomModal>
      <ReactToPrint
        trigger={() => (
          <IconButton onClick={directPrintReport} id="print-button">
            <Print
              sx={{
                p: "6px",
                paddingX: "8px",
                display: "none",
                bgcolor: "neutral.olive",
                borderRadius: "45%",
                height: "15px",
                width: "15px",
              }}
            />
          </IconButton>
        )}
        content={() => printRef.current}
        pageStyle="@page { size: auto; margin: 6mm; }"
        // pageStyle="@page { size: auto; margin: 20mm; }"
        copyStyles={true}
      />
      <ComponentToPrint
        ref={printRef}
        reportData={printData?.reportData}
        patientData={printData?.patientData}
        selectedDate={
          testDate ? testDate : new Date().toISOString().split("T")[0]
        }
      />
    </>
  );
};

export default Completed;
